import "./css/lawsuits.css"
import LawsuitsList from "../components/LawsuitsList";
import HeroLawsuits from "../components/HeroLawsuits";
import Footer from '../components/Footer';


function Lawsuits(){
    return(
        <div className="LawsuitsContainer">
            <HeroLawsuits/>
            <div className="LawsuitsContainerList">
            <LawsuitsList/>
            </div>
            <Footer/>
        </div>
    );
}
export default Lawsuits;