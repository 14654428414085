import './styles/cities.css';
import React from 'react';

class Cities extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <span onClick={this.props.handleClick} data-idregion={this.props.idRegion} className="candidates__city">{this.props.name}</span>
            );
    }   
}

export default Cities;