import "./styles/lawsuitsListPoliticianEdit.css";
import React, { Component } from "react";
import ProfileEditLawsuitModal from "../modals/ProfileEditLawsuitModal";
import axios from "axios";

class LawsuitsListPoliticianEdit extends React.Component{

    constructor(props){
        super(props);
        this.state={
            flagEditLawsuit:false,
            index:null,
            disclaimer:null,
            idDisclaimer:null,
            description:"",
            link:'',
            date:''
        };
      }


 componentDidMount(){
        // console.log('component did mount lawsuit list');
        this.props.handleListLawsuit();
      }

componentDidUpdate(){
    // console.log('component did update');
    
}

openModal=(e)=>{
    // console.log("open");
}

changeState=(idLawsuit)=>{
    var selected=document.querySelector("[data--idlawsuit='"+idLawsuit+"']");
    // console.log("################ selected"+selected);
    selected.innerHTML="Con Réplica";
    selected.style.color="#cc11ba";
}

getDisclaimer=async(index,idLawsuit)=>{

    const res = await axios.get('https://politicosperu.pe/php/getDisclaimer.php?idLawsuit='+idLawsuit);

    var miarray=res.data;
    miarray=miarray[0];
    //console.log("disclaimer"+ JSON.stringify(miarray.status));
    if(miarray.status ==  "success"){
        this.setState(
            {
                flagEditLawsuit:true,
                index:index,
                disclaimer:res.data,
                idDisclaimer:miarray.idDisclaimer,
                description:miarray.description,
                link:miarray.link,
                date:miarray.date
            }
        );
    }else{
        this.setState(
            {
                flagEditLawsuit:true,
                index:index,
                // disclaimer:null,
                // idDisclaimer:null,
                // description:"",
                // link:"",
                // date:""
            }
        );
    }


}

handleLawsuitEdit=(e)=>{
    //console.log(e.target.value);

    this.getDisclaimer(e.currentTarget.getAttribute("data--index"),e.currentTarget.getAttribute("data--idlawsuit"));

}


handleCancelEditLawsuitModal=(e)=>{
    // console.log("testing");
    this.setState(
        {
            flagEditLawsuit:false,
            index:null,
            disclaimer:null,
            idDisclaimer:null,
            description:"",
            link:'',
            date:''
        }
    );
}

    render(){
        return(
            <div className="LawsuitsListPoliticianEditContainer">
                <div className="LawsuitsListPoliticianEdit__title">Últimas Denuncias</div>
                <div className="LawsuitsListPoliticianEdit__subTitle">
                    <span className="LawsuitsListPoliticianEdit__subTitlePolitician">Demandante</span>
                    <span className="LawsuitsListPoliticianEdit__subTitleLawSuit">Acusación</span>
                    <span className="LawsuitsListPoliticianEdit__subTitleState">Estado</span>
                </div>
                <div className="LawsuitsListPoliticianEdit__list">

                {this.props.flagErrors &&
                    this.props.data.map((element,index)=>{
                        var actualState="";
                        var color="";
                        var flagclick=false;
        
                        if(element.state==1){
                        actualState="En Desarrollo";
                        color="LawsuitsListPoliticianEdit__stateInProcess";
                        flagclick=true;
                        }
                        if(element.state==2){
                        actualState="Con Réplica";
                        color="LawsuitsListPoliticianEdit__stateInDebate";
                        flagclick=true;
                        }
                        if(element.state==3){
                        actualState="Falso";
                        color="LawsuitsListPoliticianEdit__stateFalse";
                        flagclick=true;
                    }
                        if(element.state==4){
                        actualState="Confirmado";
                        color="LawsuitsListPoliticianEdit__stateTrue";
                        flagclick=true;
    
                    }
                        if(flagclick==false){
                        return(
                            <div className="LawsuitsListPoliticianEdit__item">
                            <span className="LawsuitsListPoliticianEdit__politician">{element.name}</span>
                            <span className="LawsuitsListPoliticianEdit__lawsuit">{element.title}</span>
                            <span className={color}>{actualState}</span>
                            </div>
                                )
                        }else{
                        return(
                            <div onClick={this.handleLawsuitEdit} data--index={index} data--idlawsuit={element.idLawsuit} className="LawsuitsListPoliticianEdit__item">
                            <span className="LawsuitsListPoliticianEdit__politician">{element.name}</span>
                            <span className="LawsuitsListPoliticianEdit__lawsuit">{element.title}</span>
                            <span className={color}>{actualState}</span>
                            </div>
                                )
                        }

                    })}

                </div>

                {this.state.flagEditLawsuit && <ProfileEditLawsuitModal
                messageHeader='Detalles de la Denuncia'
                handleCancelEditLawsuitModal= {this.handleCancelEditLawsuitModal}  
                idPolitician={this.state.idPolitician}
                    data={this.props.data}
                    index={this.state.index}
                    disclaimer={this.state.disclaimer}
                    idDisclaimer={this.state.idDisclaimer}
                    description={this.state.description}
                    link={this.state.link}
                    date={this.state.date}
                    changeState={this.changeState}
                />}

            </div>
        );
    }
}

export default LawsuitsListPoliticianEdit;