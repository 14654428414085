import React from "react";
import "./styles/profilePlanModal.css";


class ProfilePlanModal extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <div onClick={this.props.handleClosePlanModal} className="profilePlanModalContainer">
                <div onClick={(e)=>{e.stopPropagation()}} className="profilePlanModalWrapper">
                    <div className="profilePlanModal__header">
                        <span>{this.props.header}</span>
                        <span onClick={this.props.handleClosePlanModal} className="profilePLanModal__close">x</span>
                    </div>
                    <div className="profilePlanModal__body">
                        <div className="profilePlanModal__imageContainer">
                            <img className="profilePlanModal__image" src={this.props.image} alt="" />
                        </div>
                        <div className="profilePlanModal__description">
                            {this.props.message}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ProfilePlanModal;