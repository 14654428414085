import React from 'react';
import '../index.css';
import Hero from '../components/Hero';
import Slider from '../components/Slider';
import Candidates from '../components/Candidates';
import Footer from '../components/Footer';


class Home extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <React.StrictMode>
            <Hero 
            checkLogin={this.props.checkLogin}
            flagLogin={this.props.flagLogin}
            flagLoged={this.props.flagLoged}
            updateInputs={this.props.updateInputs}
            userDataLogin={this.props.userDataLogin}
            />
            <Slider/>
            <Candidates/>
            <Footer/>
            </React.StrictMode>
            );
    }

    }
export default Home;

