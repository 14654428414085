import "./styles/heroProfileEdit.css";
import React from "react";


class HeroProfileEdit extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="heroProfileEditContainer">
                <div className="heroProfileEditWrapper">
                    <div onClick={this.props.handleChangeImage} className="heroProfileEdit__imageContainer">
                        <img className="heroProfileEdit__image" src={this.props.imgBanner} alt="" />
                    </div>
                    <div className="heroProfileEdit__buttonsContainer">
                            <div onClick={this.props.handleChangeFile}
                            id="plan" 
                            target="_blank" 
                            className="heroProfileEdit__Button">
                                Plan de Gobierno
                            </div>
                            <div onClick={this.props.handleChangeFile} 
                            id="team" 
                            target="_blank" 
                            className="heroProfileEdit__Button">
                                Equipo Técnico
                            </div>
                    </div>
                </div>
            </div>
        );

    }

}
export default HeroProfileEdit;