import React from "react";
import "./styles/activity.css";
import imgTrash from "./images/bin.png"

class Activity extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <div onClick={this.props.handleEditActivity} data--idactivity={this.props.idActivity} data--index={this.props.index} className="activityWrapper">
                                {this.props.flagDelete &&

                                <div data--idactivity={this.props.idActivity} data--index={this.props.index} onClick={(e)=>{e.stopPropagation(); this.props.handleShowDeleteModal(e);} } className="activity__delete">
                                <img className="activity__deleteImage" src={imgTrash} alt="" />
                                </div>
                                }
                <div className="activity__head">
                    <svg className="activity__svg" width="262" height="47" viewBox="0 0 262 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M262 47.0001L0 47C227.153 46.9998 179.5 -21.5 262 8.5V47.0001Z" fill="rgba(225,1,28,1)"/>
                    </svg>
                    <img className="activity__image" src={this.props.image} alt="" />
                    <div className="activity__date">
                    <span className="activity__dateNumber">{this.props.date}</span>
                    <span className="activity__dateMonth">{this.props.month}</span>
                    </div>
                </div>
                <div className="activity__body">
                    <div className="activity__bodyWrapper">
                        <span className="activity__title"> {this.props.title}</span>
                        <p className="activity__description">
                            {this.props.text}
                        </p>
                    </div>
                    <div className="activity__icons">
                        <img className="activity__icon" src="" alt="" />
                        <img className="activity__icon" src="" alt="" />
                    </div>
                </div> 
            </div>
        );
    }  
}
export {Activity};