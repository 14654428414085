import React from "react";
import axios from "axios";
import { Activity } from "./Activity";
import "./styles/activities.css";
import imgEvent1 from "./images/event1.png";
import imgEvent2 from "./images/event2.png";
import imgEvent3 from "./images/event3.png";
import imgEvent4 from "./images/event4.png";


class Activities extends React.Component{
    constructor(props){
        super(props);
        this.state={
            flagActivities:false,
            user:{
                idPolitician:this.props.idPolitician
            },
            activities:[]
        }
    }
    getActivities=async()=>{
        // console.log("###antes de validar"+this.state.cardValidation.tokenDescription);
        axios.post('https://politicosperu.pe/php/getActivities.php',JSON.stringify({idPolitician:this.props.idPolitician}))
        .then(response =>{
            //console.log(response.data);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    //console.log("#### valida la sesión");
                    this.setState({
                        flagActivities:true,
                        activities:response.data
                    });
                    // this.getPoliticianDetails(this.getUrlPolitician());
            }else{
                //console.log("#### inValida no hay actividades");
                this.setState({
                    flagActivities:true,
                });
            }
    
        }).catch(error=>{
            //console.log("#### error");

            this.setState({
                flagActivities:true,
            });
        });

    }

getMonth(month){
    //console.log("getMonth"+month);
    switch (month){
        case ("1"):
            return("Ene");
            break;
        case ("2"):
            return("Feb");
            break;
        case ("3"):
            return("Mar");
            break;
        case ("4"):
            return("Abr");
            break;
        case ("5"):
            return("May");
            break;
        case ("6"):
            return("Jun");
            break;
        case ("7"):
            return("Jul");
            break;
        case ("8"):
            return("Ago");
            break;
        case ("9"):
            return("Sep");
            break;
        case ("10"):
            return("Oct");
            break;
        case ("11"):
            return("Nov");
            break;
        case ("12"):
            return("Dic");
            break;
          
    }

}

componentDidUpdate(){
    if(this.props.idPolitician !='' && this.state.flagActivities==false){
        this.getActivities();

    }
}
    render(){
        return(
            <div className="activitiesContainer">
                <div className="activitiesWrapper">
                {this.state.activities.map((activity)=>{
                    var day= activity.date.split("-");
                    var month=day[1];
                    var day=day[2];
                    
                    month=this.getMonth(month);

                    return(
                        <Activity 
                        flagDelete={false}
                        image={activity.image} 
                        title={activity.title} 
                        text={activity.description} 
                        date={day} 
                        month={month}/>
                    );
                })}
                {/* <Activity image={imgEvent2} title="Conversatorio" text="Realidad de la vida partidaria en el Perú, historia de los partidos políticos" date="10" month="Oct"/>
                <Activity image={imgEvent3} title="Congreso" text="Congreso anual sobre el desarrollo económico de los países sudamericanos" date="7" month="Nov"/>
                <Activity image={imgEvent4} title="Reunión" text="Se convoca a todos los seguidores a unirse a la videollamada a desarrollarse este fin de semana." date="12" month="Dic"/> */}
                </div>
            </div>
           );
    }
}
export {Activities};