import NewsItem from '../components/News';
import Footer from '../components/Footer';
import "./css/news.css";
import React from 'react';
import axios from 'axios';

class News extends React.Component{
    constructor(props){
        super(props);   
        this.state={data:[]};
    }
    getNews=async()=>{
        const res = await axios.get('https://politicosperu.pe/php/listNews.php');
        //console.log(res.data);
        this.setState({
            data:res.data
        });
    }
    getNewsBunch=async()=>{

        axios.post('https://politicosperu.pe/php/getNewsBunch.php',JSON.stringify(
            {date:this.state.data[this.state.data.length - 1].date,
            idNews:this.state.data[this.state.data.length - 1].idNews,
            idNewsPen:this.state.data[this.state.data.length - 2].idNews,
            }))
        .then(response =>{
            //console.log(response.data);
            //console.log(this.state.data[this.state.data.length - 1].date);
            //console.log(this.state.data[this.state.data.length - 1].idNews);
          //   //console.log("eseta es la respuesta positiva"+response);
          if(response.data[0].status == "success"){
            //console.log("success");
            var newArray=this.state.data;
            for(var i=0;i<response.data[0].news.length;i++){
                newArray.push(response.data[0].news[i]);
            }
            this.setState({
                data:newArray
            });
          }else{
            //console.log("no results");
          }
        }).catch(error=>{
          //   //console.log(error);
          //   //console.log("un pinche error");
        });
    }
    componentDidMount(){
        this.getNews();
    }
    render(){
        return(
            <div className="PageNewsContainer">
                <div className='PageNewsWrapper'> 
                {
                        this.state.data.map(function(element){
                            return(
                                <NewsItem 
                                idNews={element.idNews}
                                category={element.name}
                                title={element.title}
                                date={element.date}
                                image={element.image}
                                /> 
                            )
                            }
                        )
            }
                </div>

            <button onClick={this.getNewsBunch} className='PageNews__button'>Más</button>
            <Footer/>

            </div>
        );
    }

}
export default News;

