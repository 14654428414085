import React from "react";
import News from './News';
import './styles/slider.css';
import axios from "axios";
import {Link} from 'react-router-dom';

class Slider extends React.Component{

    constructor(props){
        super(props);   
        this.state={
            error:null,
            counter:0,
            counterNews:9,
            data:[]
        };
    }

    // state={
    //     error:null,
    //     counter:0,
    // }

    getNews=async()=>{
        const res = await axios.get('https://politicosperu.pe/php/listNewsSlider.php');
        // console.log(res.data);

        this.setState({
            data:res.data
        });
    }

    componentDidMount(){
        this.getNews();
    }
    rightClick=()=>{
        if(this.state.counter !=2 ){
            let section= document.getElementsByClassName("group");
            let barSection = document.getElementsByClassName("slider__section");
            let actualBarSection=document.getElementsByClassName("slider__section--active");
            barSection.item(this.state.counter + 1).classList.add("slider__section--active");
            actualBarSection.item(0).classList.remove("slider__section--active");

            let variable= -100 * (this.state.counter + 1);
            section.item(0).style.transform="translate("+variable+"vw)";
            section.item(1).style.transform="translate("+variable+"vw)";
            section.item(2).style.transform="translate("+variable+"vw)";
            // console.log("click right"+variable);

            this.setState(
                {
                    counter:this.state.counter + 1
                }
            );
        }


    }

    leftClick=()=>{
        if(this.state.counter!=0){

            let section= document.getElementsByClassName("group");
            let barSection = document.getElementsByClassName("slider__section");
            let actualBarSection=document.getElementsByClassName("slider__section--active");
            actualBarSection.item(0).classList.remove("slider__section--active");
            barSection.item(this.state.counter - 1).classList.add("slider__section--active");

            let variable= -100 * (this.state.counter - 1);
            section.item(0).style.transform="translate("+variable+"vw)";
            section.item(1).style.transform="translate("+variable+"vw)";
            section.item(2).style.transform="translate("+variable+"vw)";
            // console.log("click left"+variable);

            this.setState(
                {
                    counter:this.state.counter - 1,
                }
            );
        }

    }

    render(){
        return(
            <div className="slider__container">
                <div className="slider__wrapper">
                    <div className="group" key="3">
                    {
                    this.state.data.map(function(element,index){
                        if(index>=0 && index<3){
                            // console.log(index);
                                return( 
                                <News
                                key={element.idNews}
                                idNews={element.idNews} 
                                category={element.name}
                                title={element.title}
                                date={element.date}
                                image={element.image}
                                /> 
                                )
                        }
                    }
                     )
                    }
                    </div>
                    <div className="group" key="2">
                    {
                    this.state.data.map(function(element,index){
                        if(index>2 && index<6){
                            // console.log(index);
                                return(
                                <News
                                key={element.idNews}
                                idNews={element.idNews} 
                                category={element.name}
                                title={element.title}
                                date={element.date}
                                image={element.image}
                                /> 
                                )
                        }
                    }
                     )
                    }
                    </div>
                    <div className="group" key="1">
                    {
                    this.state.data.map(function(element,index){
                        if(index>5 && index<9){
                            // console.log(index);
                                return(
                                <News 
                                key={element.idNews}
                                idNews={element.idNews}
                                category={element.name}
                                title={element.title}
                                date={element.date}
                                image={element.image}
                                /> 
                                )
                        }
                    }
                     )
                    }
                    </div>
                </div>
                <div className="slider__controller">
                    <div className="slider__button slider__button--right" onClick={this.leftClick}>&lt; </div>
                    <div className="slider__section slider__section--active"></div>
                    <div className="slider__section"></div>
                    <div className="slider__section"></div>
                    <div className="slider__button" onClick={this.rightClick}>&gt;</div>
                </div>
            </div>
    
        );
    };

};
export default Slider;