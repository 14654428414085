import React from "react";
import './styles/residence.css';
import axios from "axios";

class Residence extends React.Component{
    constructor(props){
        super(props);
        this.state={
            regionSearch:'',
            provinceSearch:'',
            districtSearch:'',
            auxRegionSearch:'',
            auxProvinceSearch:'',
            auxDistrictSearch:'',
            regions:[],
            provinces:[],
            districts:[],
            regionFlag: false,
            provinceFlag:false,
            districtFlag:false,
            regionOnFocusFlag:false,
            provinceOnFocusFlag:false,
            districtOnFocusFlag: false,
            flagErrorUpdate:false,
            regionId:'',
            provinceId:'',
            districtId:''

        }
    }
        getRegions=async()=>{
        // const res = await axios.get('https://politicosperu.pe/php/listRegions.php');
        await axios.post('https://politicosperu.pe/php/getBasicResidence.php', JSON.stringify(
            {
            token:this.props.token,
            idPolitician: this.props.idPolitician
            }
            )).then(response =>{
                //console.log("#### respuesta server basicResidence");
                //console.log(response);
                var arrayResponse= response.data;
                //console.log(arrayResponse);
                //console.log(arrayResponse[0].regions);
        
                if(arrayResponse[1].status == "success"){  
                        this.setState({
                            regions:arrayResponse[0].regions,
                            provinces:arrayResponse[3].provinces,
                            districts:arrayResponse[3].districts,
                            regionId:arrayResponse[2].regionId,
                            provinceId:arrayResponse[2].provinceId,
                            districtId:arrayResponse[2].districtId,
                            regionSearch:arrayResponse[2].region,
                            provinceSearch:arrayResponse[2].province,
                            districtSearch:arrayResponse[2].district,
                            auxRegionSearch:arrayResponse[2].region,
                            auxProvinceSearch:arrayResponse[2].province,
                            auxDistrictSearch:arrayResponse[2].district,
                            regionFlag:true,
                            provinceFlag:true,
                            districtFlag:true
                        });
                }
        
            }).catch(error=>{
                this.setState({
                    flagErrorStart:true
                });
            });



    }
        getProvinces=async(query)=>{
        const res = await axios.get('https://politicosperu.pe/php/listProvinces.php?region='+query);
        // console.log("provinces: "+res.data);

        this.setState({
            provinces:res.data
        });
    }
    getDistricts=async(query)=>{
        const res = await axios.get('https://politicosperu.pe/php/listDistricts.php?province='+query);
        // console.log("districts: "+res.data);

        this.setState({
            districts:res.data
        });
    }

    handleOnChange=(e)=>{
        this.handleVisibleRecomendation(e);

        this.setState({
            [e.target.name+'Search']: e.target.value,
            [e.target.name+'Flag']:false

        });

    }



    handleSelectSuggestion=(e)=>{
        // console.log("handle suggestion"+e.target.getAttribute('data-suggestion'));
        //console.log("handle selection");
        if(e.target.getAttribute('data-suggestion') =="" || e.target.getAttribute('data-suggestion') == undefined){

        }else{
            var group=e.target.parentNode;
            group.style.display="none";
            // console.log("### target id"+e.target.id);
            if(e.target.id =="region"){
                this.getProvinces(e.target.getAttribute('data-idsuggestion'));
                // console.log("region"+e.target.getAttribute('data-suggestion'));
                this.setState({
                    // ...this.setState,
                    regionSearch: e.target.getAttribute('data-suggestion'),
                    auxRegionSearch: e.target.getAttribute('data-suggestion'),
                    regionFlag:true,
                    provinceFlag:false,
                    provinceSearch:'',
                    auxProvinceSearch:'',
                    districtFlag:false,
                    districtSearch:'',
                    auxDistrictSearch:'',
                    regionId:e.target.getAttribute('data-idsuggestion')
                });
            }else if(e.target.id =="province"){
                this.getDistricts(e.target.getAttribute('data-idsuggestion'));
                // console.log("province chaja chaja"+e.target.getAttribute('data-suggestion'));
                this.setState({
                    // ...this.setState,
                    provinceSearch: e.target.getAttribute('data-suggestion'),
                    auxProvinceSearch: e.target.getAttribute('data-suggestion'),
                    provinceFlag:true,
                    districtFlag:false,
                    districtSearch:'',
                    auxDistrictSearch:'',
                    provinceId:e.target.getAttribute('data-idsuggestion')
                });
            }else if(e.target.id =="district"){
                // console.log("district chaja chaja"+e.target.getAttribute('data-idsuggestion'));
                // console.log("###### district is: "+this.state.districtId+" on HandleClick");

                this.setState({
                    // ...this.setState,
                    districtSearch: e.target.getAttribute('data-suggestion'),
                    auxDistrictSearch: e.target.getAttribute('data-suggestion'),
                    districtFlag:true,
                    districtId:e.target.getAttribute('data-idsuggestion')
                });
            }


        }
    }

        handleVisibleRecomendation(e){
        var searchWord=e.target.value;
        // console.log("handle searchOnChange"+"handle Visible Recomendation");

        var RecomendationRegions=document.getElementsByClassName('Residence__'+e.target.name+'RecomendationGroup');

        if(searchWord.length <1){
            RecomendationRegions.item(0).style.display="none";
        }else{
            RecomendationRegions.item(0).style.display="block";
        }
    }

    handleOnFocusOut=(e)=>{
        setTimeout(function() {
            var recomendationRegions=document.getElementsByClassName('Residence__'+e.target.name+'RecomendationGroup');
            recomendationRegions.item(0).style.display="none";
        
        }, 200);
        setTimeout(()=>{

            if((e.target.getAttribute("data--inputtype") == 'region') && !this.state.regionFlag ){

                this.setState({
                    regionOnFocusFlag: false,
                    regionFlag:true,
                    regionSearch: this.state.auxRegionSearch
                });          

      }else if(e.target.getAttribute("data--inputtype") == 'province' && !this.state.provinceFlag){
          this.setState({
              provinceOnFocusFlag: false,
              provinceFlag:true,
              provinceSearch: this.state.auxProvinceSearch
          });

      }else if(e.target.getAttribute("data--inputtype") == 'district' && !this.state.districtFlag){
          this.setState({
              districtOnFocusFlag: false,
              districtFlag:true,
              districtSearch:this.state.auxDistrictSearch
          });
      }    
        },200);

        
      
    }

    updateResidence= async()=>{
        // console.log("###antes de validar"+this.props.token);
        if(this.state.regionFlag && this.state.provinceFlag && this.state.districtFlag){
            axios.post('https://politicosperu.pe/php/updateResidence.php',JSON.stringify(
                {
                idRegion:this.state.regionId,
                region:this.state.regionSearch,
                idProvince:this.state.provinceId,
                province:this.state.provinceSearch,
                idDistrict:this.state.districtId,
                district:this.state.districtSearch,
                token:this.props.token,
                idPolitician: this.props.idPolitician
            }
                ))
            .then(response =>{
                //console.log(response);
                var arrayResponse= new Array(response.data[0]);
        
                if(arrayResponse[0].status == "success"){  
                        //console.log("#### cambiando la residencia");
                        // this.hideSuccessPassword();
                        this.setState({
                            flagErrorUpdate:false,
                        });

                }else{
                    // //console.log("#### citizen inValida"+arrayResponse[0].urlUser+"-- token: "+arrayResponse[0].token);
                    //console.log("no se pudo cambiar la residencia");

                    this.setState({
                        flagErrorUpdate:true
                    });
                }
        
            }).catch(error=>{
                this.setState({
                    flagErrorUpdate:true
                });
            });
        }else{
            this.setState({
                flagErrorUpdate:true
            });
        }
    }

    handleOnFocus=(e)=>{
        if(e.target.getAttribute("data--inputtype") == 'region'){
            this.setState({
                regionOnFocusFlag: true,
            });

        }else if(e.target.getAttribute("data--inputtype") == 'province'){
            this.setState({
                provinceOnFocusFlag: true,
            });

        }else if(e.target.getAttribute("data--inputtype") == 'district'){
            this.setState({
                districtOnFocusFlag: true,
            });
        }
    }
    componentDidMount(){
        this.getRegions();
    }
    render(){
        return(
            <div className="ResidenceContainer">
                <div className="ResidenceWrapper">
                    <h2 className="Residence__title">
                        Mi Residencia
                    </h2>
                    <div className="Residence__body">
                        <div className="Residence_inputsWrapper">
                        <div className="Residence__inputWrapper">
                        <input data--inputtype='region' onFocus={this.handleOnFocus} autoComplete="off" onBlur={this.handleOnFocusOut} onChange={this.handleOnChange} placeholder="Departamento" name="region" type="text" className="Residence__input" value={this.state.regionSearch} />
                        <div className="Residence__wrapperRecomendationGroup">
                            <div className="Residence__regionRecomendationGroup">
                            { 
                            
                                this.state.regions.filter((val)=>{
                                    // // console.log(val.name.toLowerCase());
                                    if(this.state.regionSearch ==""){
                                        return val;
                                    }
                                    else if(val.name.toLowerCase().includes(this.state.regionSearch.toLowerCase())){
                                        return val;
                                    }
                                }).map((element)=>{
                                    return(
                                        <div onClick={this.handleSelectSuggestion} data-idsuggestion={element.idRegion} data-suggestion={element.name} id="region" className="Residence__recomendationItem"> {element.name}</div>
                                    )
                                    }
                                )
                            }
                            
                            </div>
                        </div>
                        </div>
                        <div>
                        <input data--inputtype='province' onFocus={this.handleOnFocus} autoComplete="off" onBlur={this.handleOnFocusOut} onChange={this.handleOnChange} placeholder="Provincia" name="province" type="text" className="Residence__input" value={this.state.provinceSearch} />
                        <div className="Residence__wrapperRecomendationGroup">
                            <div className="Residence__provinceRecomendationGroup">
                            {
                                this.state.provinces.filter((val)=>{
                                    // // console.log(val.name.toLowerCase());
                                    if(this.state.provinceSearch ==""){
                                        return val;
                                    }
                                    else if(val.name.toLowerCase().includes(this.state.provinceSearch.toLowerCase())){
                                        return val;
                                    }
                                }).map((element)=>{
                                    return(
                                        <div onClick={this.handleSelectSuggestion} data-idsuggestion={element.idProvince} data-suggestion={element.name} id="province" className="Residence__recomendationItem"> {element.name}</div>
                                    )
                                    }
                                )
                            }
                            </div>
                        </div>
                        </div>
                        <div>
                        <input data--inputtype='district' onFocus={this.handleOnFocus} autoComplete="off" onBlur={this.handleOnFocusOut} onChange={this.handleOnChange} placeholder="Distrito" name="district"  type="text" className="Residence__input" value={this.state.districtSearch} />
                            <div className="Residence__wrapperRecomendationGroup">
                            <div className="Residence__districtRecomendationGroup">
                            {
                                this.state.districts.filter((val)=>{
                                    // // console.log(val.name.toLowerCase());
                                    if(this.state.districtSearch ==""){
                                        return val;
                                    }
                                    else if(val.name.toLowerCase().includes(this.state.districtSearch.toLowerCase())){
                                        return val;
                                    }
                                }).map((element)=>{
                                    return(
                                        <div onClick={this.handleSelectSuggestion} data-idsuggestion={element.idDistrict}  data-suggestion={element.name} id="district"  className="Residence__recomendationItem"> {element.name}</div>
                                    )
                                    }
                                )
                            }
                            </div>
                        </div>
                        </div>
                        </div>


                         <div className="Residence__footer">
                                <button onClick={this.updateResidence} className="Residence__button">Actualizar</button>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
export default Residence;