
import './styles/header.css';
import Logo from './images/logo.png'; 
import {Link} from 'react-router-dom';
import React from 'react';
import Options from './Options';
import menu from "./images/menu.png";
import OptionsPhone from './OptionsPhone';

class Header extends React.Component{

    constructor(props){
        super(props);
        this.state={
            flagMenuPhone:false,
            flagMovile:false,
            flagDesktop:false,
            flagTablet:false,
            flagPhone:false
        }
    }

    readCookie=(name)=> {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
    createCookie=(name,value,days)=> {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            var expires = "; expires="+date.toGMTString();
        }
        else var expires = "";
        document.cookie = name+"="+value+expires+"; path=/";
    }
    
    eraseCookie=(name)=>{
        this.createCookie(name,"",-1);
    }


    // manageSesion=()=>{
    //     if(this.readCookie != "" || this.readCookie != null){
    //         console.log("hay cookie");
    //         this.setState(
    //             {
    //                 token: true,
    //                 tokenDescription:this.readCookie('token'),
    //                 domain:"/manage/"+this.readCookie('domain')
    //             }
    //         );
    //     }
    // }


    handleMenuClick=(e)=>{
        if(this.state.flagMovile){
            this.setState({
                ... this.state,
                flagMovile:false,
            });
        }else{
            this.setState({
                ... this.state,
                flagMovile:true
            });
        }

    }

    getDeviceUsed=()=>{
        if(window.innerWidth <= 517 && window.innerWidth >= 320){
            this.setState({
                flagPhone: true,
            })
        }else if(window.innerWidth <= 650 && window.innerWidth >517){
            this.setState({
                flagTablet: true,
            }) 
         }else{
            this.setState({
                flagDesktop: true,
            })
        }
    }

    changeDevice=()=>{
        console.log("called");
        if(window.innerWidth <= 517 && window.innerWidth >= 320){
            this.setState({
                flagPhone:true,
                flagTablet:false,
                flagDesktop:false,
            });
        }else if(window.innerWidth <= 650 && window.innerWidth >517){
            this.setState({
                flagPhone:false,
                flagTablet:true,
                flagDesktop:false,
            });
        }else{
            this.setState({
                flagMovile:false,
                flagPhone:false,
                flagTablet:false,
                flagDesktop:true,
            });
        }
    }

    componentDidMount(){
        // this.manageSesion();
        window.addEventListener('resize', (event)=> this.changeDevice());
        if(!this.state.flagDesktop && !this.state.flagTablet && !this.state.flagPhone){
            this.getDeviceUsed();
        }
    }

    componentDidUpdate(){
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.changeDevice());

    }
    closeMovile=()=>{
        this.setState({
            flagMovile:false,
        });
    }
    render(){
        return(
            <div className="Header">
                <div className="Header__home">
                <Link className="Header__logo" to="./">
                    <div className='Header__imageContainer'>
                    <img className='Header__image' src={Logo} alt="logo políticos Perú" width="60px" height="60px" />
                    </div>
                    <span className="Header__logoText" ><strong>P</strong>olíticos <strong>P</strong>erú</span>
                    </Link>
                </div>
                    {(this.state.flagDesktop || this.state.flagMovile) &&  
                    <div onClick={this.closeMovile} className={!this.state.flagMovile ? 'Header__navbarWrapper' : 'Header__navbarWrapper Header__navbarWrapper--phone'}>

                    <nav onClick={e=>{e.stopPropagation()}} className={!this.state.flagMovile ? "Header__navbar" : "Header__navbar Header__navbar--phone"}>
                    <ul className={!this.state.flagMovile ? "Header__navbarList" : "Header__navbarList Header__navbarList--phone"}>
                        <li className={!this.state.flagMovile ? "Header__navbarItem" : "Header__navbarItem Header__navbarItem--phone"}>
                            <Link onClick={this.closeMovile} className={!this.state.flagMovile ? "Header__navbarItemLink" : "Header__navbarItemLink Header__navbarItemLink--phone"} to="/news">Noticias</Link>
                            </li>
                        <li className={!this.state.flagMovile ? "Header__navbarItem" : "Header__navbarItem Header__navbarItem--phone"}>
                            <Link onClick={this.closeMovile} className={!this.state.flagMovile ? "Header__navbarItemLink" : "Header__navbarItemLink Header__navbarItemLink--phone"} to="/lawsuits">Denuncias</Link>
                            </li>
                        <li className={!this.state.flagMovile ? "Header__navbarItem" : "Header__navbarItem Header__navbarItem--phone"}>
                            <Link onClick={this.closeMovile} className={!this.state.flagMovile ? "Header__navbarItemLink" : "Header__navbarItemLink Header__navbarItemLink--phone"} to="/politicians">Políticos</Link>
                            </li>
                        
                        {(this.props.token && !this.props.flagCitizen) ?  
                        <li className= {!this.state.flagMovile ? "Header__navbarItemProfile" : "Header__navbarItemProfile Header__navbarItemProfile--phone"}>
                            <Link onClick={this.closeMovile} to={"manage/"+this.props.domain} className={!this.state.flagMovile ? "Header__navbarItemProfileDescription" : "Header__navbarItemProfileDescription Header__navbarItemProfileDescription--phone"}>
                                Perfil
                            </Link>  
                            <Options closeMovile={this.closeMovile} flagMenuPhone={this.state.flagMovile} domain={this.props.domain}  closeSesion={this.props.closeSesion} />
                        </li>
                        :(this.props.token !="" && this.props.flagCitizen) && <li className= {!this.state.flagMovile ? "Header__navbarItemProfile" : "Header__navbarItemProfile Header__navbarItemProfile--phone"}>
                        <Link onClick={this.closeMovile} to={"/ciudadano"} className={!this.state.flagMovile ? "Header__navbarItemProfileDescription" : "Header__navbarItemProfileDescription Header__navbarItemProfileDescription--phone"}>
                            Perfil
                        </Link>  
                        <Options closeMovile={this.closeMovile} flagMenuPhone= {this.state.flagMovile} flagCitizen={this.props.flagCitizen} domain="ciudadano"  closeSesion={this.props.closeSesion} />
                    </li>
                        }

                    </ul>
                    </nav>
                     </div>
                    }
                {((this.state.flagPhone || this.state.flagTablet) && !this.state.flagMovile) &&

                    <div onClick={this.handleMenuClick} className='Header__navbarPhoneImageContainer'>
                    <img className='Header__navbarPhoneImage' src={menu} alt="" />
                    </div>
                }
                    

                {/* {this.state.flagMenuPhone &&  <nav className="Header__navbarPhone">

                    <ul onClick={this.handleMenuClick} className="Header__navbarListPhone">
                        <li className="Header__navbarItemPhone">
                            <Link className="Header__navbarItemLink" to="/news">Noticias</Link>
                        </li>
                        <li className="Header__navbarItemPhone">
                            <Link className="Header__navbarItemLink" to="/lawsuits">Denuncias</Link>
                        </li>
                        <li className="Header__navbarItemPhone">
                            <Link className="Header__navbarItemLink" to="/politicians">Políticos</Link>
                        </li>
                        {this.props.token &&  
                        <li className="Header__navbarItemProfilePhone">
                            <Link to={this.props.domain} className="Header__navbarItemProfileDescription">Perfil</Link>
                            <OptionsPhone flagMenuPhone= {this.state.flagMenuPhone} domain={this.props.domain} closeSesion={this.props.closeSesion} />
                            </li>}
                    </ul>
                </nav>} */}

            </div>
        );
    }

}

export default Header;