import './styles/profile.css';
import profileImg from './images/news3.png';
import { Link } from 'react-router-dom';
import React from 'react';


class Profile extends React.Component{
    constructor(props){
    super(props);
    }
    
    render(){
        return(
            <Link  to={this.props.webpage} data-idcandidate={this.props.idCandidate} className="profile__wrapper">
            <div  className="profile">
                <div className="profile__imgContainer">
                    <img className="profile__image" src={this.props.imgProfile} alt="" />
                </div>
                <div className="profile__name"> {this.props.name} {this.props.surnameFather} {this.props.surnameMother}</div>
                <div className="profile__profession">{this.props.actualWork}</div>
                <div className="profile__position"> {this.props.location}</div>
            </div>
            </Link>
        );
    }


}

export default Profile;