import React from 'react';
import NewsItem from '../components/News';
import './css/descriptionNew.css';
import axios from 'axios';
import Footer from '../components/Footer';


class DescriptionNew extends React.Component{

    constructor(props){
        super(props);
        var url=window.location.href;
        var arrayurl=url.split("/");
        var idNews=arrayurl[4];

        this.state={
            data:[],
            actualNews:[],
            totalParagraph:[],
            url:idNews
        }
    }

    getActualNews=async(query)=>{
        const res = await axios.get('https://politicosperu.pe/php/getActualNews.php?idNews='+query);

        var listNews=[];

        axios.post('https://politicosperu.pe/php/listThreeNews.php',{idNews:query})
        .then(response =>{
            //console.log(response);
            var arrayResponse= new Array(response.data);
            //console.log(arrayResponse[0]);
    
            listNews= arrayResponse[0];

            var actualNews='';
            res.data.map(function(element){
                actualNews=element;
            });
    
            var arrayContent= [];
            var content=actualNews.content;
    
            arrayContent=content.split(/\r?\n/);
            for(var i=0; i<arrayContent.length;i++){
                arrayContent[i]={"paragraph":arrayContent[i]};
            }
            actualNews.content=arrayContent;
            // arrayContent.push(JSON.stringify(Object.assign({}, content)));
    
            this.setState({
                actualNews:actualNews,
                totalParagraph:arrayContent,
                data:listNews
            });
        }).catch(error=>{
   
        });

       


    }
    componentDidMount(){
        // var url=window.location.href;
        // var arrayurl=url.split("/");
        // var idNews=arrayurl[4];
        this.getActualNews(this.state.url);
    }
    componentDidUpdate(){
        // var url=window.location.href;
        // var arrayurl=url.split("/");
        // var idNews=arrayurl[4];
        // this.getActualNews(idNews);
        // this.getNews();
    }
    render(){
        return(
            <div className="newContainer">
                <div className="new__header">
                    <img className="new__image" src={this.state.actualNews.image} alt="" />
                    <div className="new__meta">
        
                        <h1 className="new__title">
                            {this.state.actualNews.title}
                        </h1>
                        <div className="new__containerDetails">
        
                        <span className="new__detail">
                        {this.state.actualNews != "" && this.state.actualNews.date.split(" ")[0].split("-")[2]+" - "+this.state.actualNews.date.split(" ")[0].split("-")[1]+" - "+this.state.actualNews.date.split(" ")[0].split("-")[0]}
                        </span>
                        <span className="new__detail">
                        {this.state.actualNews.city}
                        </span>
                        <span className="new__detail">
                        {this.state.actualNews.name}
                        </span>
                        </div>
                    </div>
                </div>
                <div className="new__content">
                    <h2 className="new__contentTitle">
                            {this.state.actualNews.headContent}
                        </h2>   
                        
                        {
                            this.state.totalParagraph.map(
                                function(element){
                                    return(
                                        <p className="new__contentParagraph">
                                            {element.paragraph}
                                        </p>
                                    );
                                }
                            )
                        }
                </div>
                <div className="new__more">
                        { this.state.data != null &&
                        this.state.data.map((element,index)=>{

                            if(element.idNews != this.state.url ){
                                
                                return(
                                    <NewsItem 
                                    key={element.idNews}
                                    idNews={element.idNews}
                                    category={element.name}
                                    title={element.title}
                                    date={element.date}
                                    image={element.image}
                                    /> 
                                )
                            }

                        }
                    )}
                </div>
                <Footer/>
            </div>
        );
    }

}

export default DescriptionNew;