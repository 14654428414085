import './styles/news.css';
import {Link} from 'react-router-dom';

function News(props){
    var date= props.date.split(" ")[0];
    var newdate= date.split("-");

    return(
        <a href={'/news/'+props.idNews} className="news__wrapper"> 
        <div className='news' key={props.idNews}>
            <div className='news__imageContainer'>
                <img  src={props.image} alt=""  className='news__image'/>
            </div>
            <div className='news__description'>
                <h2 className='news__category'>{props.category}</h2>
                <h1 className='news__title'>{props.title}</h1>
                <span className='news__date'>{newdate[2]+" - "+newdate[1]+" - "+newdate[0]}</span>
            </div>
        </div>  
        </a> 
        );
}
export default News;