import React from "react";
import './styles/options.css';
import rowImg from "./images/row.png";
import { Link } from "react-router-dom";

class OptionsPhone extends React.Component{
    constructor(props){
        super(props);
        this.state={
            flagOptions:false,
            flagClick:false,
        }
    }

       
    handleClick=()=>{
        console.log('click para ver opciones');
        if(this.state.flagOptions){
            // console.log('false');
            this.setState({
                flagOptions:false,
                flagClick:true
            });
        }else{
            // console.log('true');
            this.setState({
                flagOptions:true,
                flagClick:true
            });
        }

    }


    
    componentDidMount() {
 
      }
     
    componentWillUnmount(){

    }


    render(){
        return(
            <div className="optionsContainer">
                {this.props.flagMenuPhone 
                ? <div className="optionsWrapper">
                            <div ref={this.box} >
                            <div onClick={this.handleClick} className="options__button">
                                <img src={rowImg} width="13px" height="13px" alt="" />
                            </div>
                            {this.state.flagOptions &&  <div  className="options__listOptions">
                            <Link className="options__item" to={"config/"+this.props.domain} > Configuración </Link>
                            <div onClick={this.props.closeSesion} className="options__item options__footer">Cerrar sesión</div>
                            </div>}
                            </div>
                    </div>
                :
                <div className="optionsWrapper">
                <div onClick={this.handleClick} className="options__button">
                <img src={rowImg} width="13px" height="13px" alt="" />
                </div>

                <div>
                {this.state.flagOptions &&  <div  className="options__listOptions">
                <Link className="options__item" to={"config/"+this.props.domain} > Configuración </Link>
                <div onClick={this.props.closeSesion} className="options__item options__footer">Cerrar sesión</div>
                </div>}
                </div>
                    </div>
                }




            </div>
        );
    }

}

export default OptionsPhone;