
import {Routes,Route,useNavigate} from 'react-router-dom';
import axios from 'axios';
import Home from './pages/Home.js';
import Lawsuits from './pages/Lawsuits.js';
import News from './pages/News';
import Politicians from './pages/Politicians.js';
import BasicLayout from './layouts/Basic.js';
import DescriptionNew from './pages/DescriptionNew.js';
import Politician from './pages/Politician.js';
import UserProfile from './pages/UserProfile.js';
import ConfigProfile from './pages/ConfigProfile.js';
import React from 'react';
import CitizenProfile from './pages/CitizenProfile.js';
import Error404 from './pages/Error404.js';
import Restore from './pages/RestoreAccount.js';
import SignUp from './pages/SignUp.js';
import ConfirmCitizen from './pages/ConfirmCitizen.js';
import ConfirmPolitician from './pages/ConfirmPolitician.js';
import Recover from './pages/recover.js';
import ConfirmChangePassword from './pages/ConfirmChangePassword.js';






class App extends React.Component {
  
  
  constructor(props){
    super(props);
    
    this.state={
      tryOpenSesion:false,
      suspended:'0',
      idPolitician:'',
      idCitizen:'',
      flagTest:false,
      token:false,
      flagLogin:false,
      flagCitizen:false,
      tokenDescription:null,
      domain:'',
      user:{
        username:'',
        password:'',
      }
    }
  }
  
  
  readCookie=(name)=> {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
createCookie=(name,value,days)=> {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
}

eraseCookie=(name)=>{
    this.createCookie(name,"",-1);
}



openOldSesion=()=>{
  if(this.readCookie("token") != null){
      //console.log("hay cookie");
      // console.log("este es"+this.readCookie("token"));
      if(this.readCookie("idPolitician") != null || (this.readCookie("idPolitician") !="" && this.readCookie("idPolitician") != null) ){
        //console.log("here the idPolitician from cookie"+this.readCookie("idPolitician"));


        this.setState(
            {
                token: true,
                tokenDescription:this.readCookie("token"),
                domain:this.readCookie('domain')
            }
        );
      }else{
        //console.log("here the idCitizen from cookie"+this.readCookie("idCitizen"));


        this.setState(
            {
                flagCitizen:true,
                token: true,
                tryOpenSesion:true,
                tokenDescription:this.readCookie("token"),
                idCitizen:this.readCookie("idCitizen"),
                domain:"/ciudadano/"
            }
        );
      }

  }else{
    //console.log("no hay cookie");
    this.setState(
      {
          token: false,
          tryOpenSesion:true,
          tokenDescription:null,
          domain:''
      }
  );
  }
}

closeSesion=()=>{
  // console.log('closing sesion');
  this.eraseCookie('token');
  this.eraseCookie('idCitizen');
  this.eraseCookie('idPolitician');
  this.eraseCookie('token');
  this.eraseCookie('domain');
  const { navigation } = this.props;
  navigation('/');

  this.setState(
      {
          token: false,
          flagLoged:false,
          flagLogin:false,
          flagCitizen:false,
          tokenDescription:'',
          suspended:'0',
          idPolitician:'',
          domain:'',
          idCitizen:'',
      }
  );
}


checkLogin=async()=>{
  //console.log("login");

  if(this.state.token){

    this.setState({
      user:{
        username:'',
        password:''
      }
    });
  }else{
    axios.post('https://politicosperu.pe/php/login.php',JSON.stringify(this.state.user))
    .then(response =>{
        // console.log("base de datos consultada test");
        // console.log("base de datos consultada dos veces");
        // console.log(response);
        var arrayResponse= new Array(response.data[0]);
        // console.log("###### antes del if");

        if(arrayResponse[0].status == "success"){
        //console.log("###### login success");

          // console.log("webpage"+arrayResponse[0].webpage);
          // console.log("token"+arrayResponse[0].token);
          // console.log("idPolician"+arrayResponse[0].idPolician);
            if(arrayResponse[0].idPolitician !=''){
              //console.log("###### politician success");

              this.createCookie('token',arrayResponse[0].token,1);
              this.createCookie('idPolitician',arrayResponse[0].idPolitician,1);
              this.createCookie('domain',arrayResponse[0].webpage,1);
  
  
              const { navigation } = this.props;
  
              navigation('/manage/'+arrayResponse[0].webpage);
              
            //console.log(arrayResponse[0].suspended);
              this.setState(
                {
                    flagLogin:false,
                    token: true,
                    suspended:arrayResponse[0].suspended,
                    tokenDescription:arrayResponse[0].token,
                    idPolitician:arrayResponse[0].idPolitician,
                    domain:arrayResponse[0].webpage,
                    user:{
                      username:'',
                      password:''
                    }
                }
            );
            }else{
              //console.log("###### citizen success");
              // console.log(arrayResponse[0]);

              this.createCookie('token',arrayResponse[0].token,1);
              this.createCookie('idCitizen',arrayResponse[0].idCitizen,1);
  
              const { navigation } = this.props;
  
              navigation('/ciudadano/');
              
            
              this.setState(
                {
                    flagLogin:false,
                    flagCitizen:true,
                    token: true,
                    tokenDescription:arrayResponse[0].token,
                    idCitizen:arrayResponse[0].idCitizen,
                    domain:"/ciudadano/",
                    user:{
                      username:'',
                      password:''
                    }
                }
            );
            }

        }else{
          //console.log("esta dando error ####################");
            this.setState(
                {
                    flagLogin:true,
                    flagLoged:false,
                    token:false,
                    tokenDescription:'',
                    domain:''
                }
            );
        }

    }).catch(error=>{
        // console.log(error);
    });
  }

  
}


handleOnChange=(e)=>{
  this.setState({
      user:{
          ...this.state.user,
          [e.target.name]: e.target.value
      }
  });
}

handleSuspendedFlag=()=>{
  if(this.state.suspended == "0"){
    this.setState({
      suspended:"1"
    });
  }else{
    this.setState({
      suspended:"0"
    });
  }
}
componentDidMount(){
  if(!this.state.tryOpenSesion){
    this.openOldSesion();
  }
}

  render(){
    
    console.log("render App");
    return (
     
      <BasicLayout flagCitizen={this.state.flagCitizen} token={this.state.token} tokenDescription={this.state.tokenDescription} closeSesion={this.closeSesion} domain={this.state.domain}>
      <Routes>
          <Route path="/" element={<Home 
                                    userDataLogin={this.state.user}
                                    updateInputs={this.handleOnChange}
                                    flagLogin={this.state.flagLogin}
                                    checkLogin={this.checkLogin} 
                                    token={this.state.token} 
                                    tokenDescription={this.state.tokenDescription}
                                    suspended={this.state.suspended} />}/>
          <Route path="*" element={<Error404  />}/>
          <Route exact path="/news" element={<News 
                                              token={this.state.token} 
                                              tokenDescription={this.state.tokenDescription}
                                              suspended={this.state.suspended}/>}/>
          <Route exact path="/lawsuits" element={<Lawsuits 
                                              token={this.state.token}
                                              tokenDescription={this.state.tokenDescription}
                                              suspended={this.state.suspended}/>}/>
          <Route exact path="/restore" element={<Restore 
                                              token={this.state.token} 
                                              tokenDescription={this.state.tokenDescription}
                                              suspended={this.state.suspended}/>}/>
          <Route exact path="/:politicianid" element={<Politician 
                                              token={this.state.token} 
                                              tokenDescription={this.state.tokenDescription}
                                              suspended={this.state.suspended}/>}/>
          <Route exact path="/politicians/:politicianid" element={<Politician 
                                              token={this.state.token} 
                                              tokenDescription={this.state.tokenDescription}
                                              suspended={this.state.suspended}/>}/>
          <Route exact path="/politicians" element={<Politicians 
                                              token={this.state.token} 
                                              tokenDescription={this.state.tokenDescription}
                                              suspended={this.state.suspended}/>}/>
          <Route exact path="/news/:newid" element={<DescriptionNew 
                                              token={this.state.token} 
                                              tokenDescription={this.state.tokenDescription}
                                              suspended={this.state.suspended}/>}/>
          <Route exact path="/signup" element={<SignUp 
                                              token={this.state.token} 
                                              tokenDescription={this.state.tokenDescription}
                                              suspended={this.state.suspended}/>}/>

          {this.state.token && <Route exact path="/manage/:politicianid" element={<UserProfile
                                              closeSesion={this.closeSesion}
                                              domain={this.state.domain} 
                                              token={this.state.token} 
                                              tokenDescription={this.state.tokenDescription}
                                              suspended={this.state.suspended} />}/>}
          {this.state.token && <Route exact path="/config/:politicianid" element={<ConfigProfile
                                              flagCitizen={this.state.flagCitizen} 
                                              token={this.state.token} 
                                              closeSesion={this.closeSesion}
                                              domain={this.state.domain} 
                                              tokenDescription={this.state.tokenDescription}
                                              suspended={this.state.suspended}
                                              handleSuspendedFlag={this.handleSuspendedFlag}
                                              />}/>}

          <Route exact path="/ciudadano" element={<CitizenProfile 
                                              flagCitizen={this.state.flagCitizen}
                                              token={this.state.token} 
                                              tokenDescription={this.state.tokenDescription}
                                              suspended={this.state.suspended} />}/>
          <Route exact path="/citcon/:codeConfirmation" element={<ConfirmCitizen 
                                              flagCitizen={this.state.flagCitizen}
                                              token={this.state.token} 
                                              tokenDescription={this.state.tokenDescription}
                                              suspended={this.state.suspended} />}/>
          <Route exact path="/polcon/:codeConfirmation" element={<ConfirmPolitician 
                                              token={this.state.token} 
                                              tokenDescription={this.state.tokenDescription}
                                              suspended={this.state.suspended} />}/>
          <Route exact path="/recover" element={<Recover 
                                              token={this.state.token} 
                                              tokenDescription={this.state.tokenDescription}
                                              suspended={this.state.suspended} />}/>
          <Route exact path="/recover/:codeConfirmation" element={<ConfirmChangePassword 
                                              token={this.state.token} 
                                              tokenDescription={this.state.tokenDescription}
                                              suspended={this.state.suspended} />}/>
        
      </Routes>
      </BasicLayout>
  
     
  );
  }


}

// export default App;
export default function(props) {
  const navigation = useNavigate();

  return <App {...props} navigation={navigation} />;
}