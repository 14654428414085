import Profile from "../components/Profile";
import Footer from '../components/Footer';
import './css/politicians.css'
import axios from "axios";
import React from "react";

class Politicians extends React.Component{
    constructor(props){
        super(props);
        this.state={
            data:[],
            regionSearch:'',
            provinceSearch:'',
            districtSearch:'',
            provinces:[],
            districts:[],
            candidates:[],
            regionFlag: false,
            provinceFlag:false,
            districtFlag:false,
            regionId:'',
            provinceId:'',
            districtId:''
        }
    }
    getRegions=async()=>{
        const res = await axios.get('https://politicosperu.pe/php/listRegions.php');
        // console.log("region: "+res.data);
        this.setState({
            data:res.data
        });
    }
    getProvinces=async(query)=>{
        const res = await axios.get('https://politicosperu.pe/php/listProvinces.php?region='+query);
        // console.log("provinces: "+res.data);

        this.setState({
            provinces:res.data
        });
    }
    getDistricts=async(query)=>{
        const res = await axios.get('https://politicosperu.pe/php/listDistricts.php?province='+query);
        // console.log("districts: "+res.data);

        this.setState({
            districts:res.data
        });
    }
    getCandidates=async()=>{
        const res = await axios.get('https://politicosperu.pe/php/listCandidates.php');
        // console.log(res.data);
        this.setState({
            candidates:res.data
        });
    }
    getSearch=async()=>{
        if(this.state.regionFlag && !this.state.provinceFlag && !this.state.districtFlag){
            const res = await axios.get('https://politicosperu.pe/php/listSearch.php?region='+this.state.regionId);
            // console.log(res.data);
            this.setState({
                candidates:res.data
            });
        }else if(this.state.provinceFlag && !this.state.districtFlag){
            const res = await axios.get('https://politicosperu.pe/php/listSearch.php?province='+this.state.provinceId);
            // console.log(res.data);
            this.setState({
                candidates:res.data
            });
        }else if(this.state.districtFlag){
            // console.log("###### district is:"+this.state.districtId);
            const res = await axios.get('https://politicosperu.pe/php/listSearch.php?district='+this.state.districtId);
            // console.log(res.data);
            this.setState({
                candidates:res.data
            });
        }
    }
    componentDidMount(){
        this.getRegions();
        this.getCandidates();
    }
    handleVisibleRecomendation(e){
        var searchWord=e.target.value;
        // console.log("handle searchOnChange"+"handle Visible Recomendation");

        var RecomendationRegions=document.getElementsByClassName('politicians__'+e.target.name+'RecomendationGroup');

        if(searchWord.length <1){
            RecomendationRegions.item(0).style.display="none";
        }else{
            RecomendationRegions.item(0).style.display="block";
        }
    }
    handleOnFocusOut(e){
        
        setTimeout(function() {
            var recomendationRegions=document.getElementsByClassName('politicians__'+e.target.name+'RecomendationGroup');
            recomendationRegions.item(0).style.display="none";
          }, 200);
    }

    handleSearchOnChange=(e)=>{
        this.handleVisibleRecomendation(e);
        // console.log("handle searchOnChange"+e.target.name);
        // console.log("handle searchOnChange"+e.target.value);
        this.setState(
            {
                [e.target.name+'Search']: e.target.value,
                [e.target.name+'Flag']:false
            }
        );
       
    }

    handleSelectSuggestion=(e)=>{
        // console.log("handle suggestion"+e.target.getAttribute('data-suggestion'));

        if(e.target.getAttribute('data-suggestion') =="" || e.target.getAttribute('data-suggestion') == undefined){

        }else{
            var group=e.target.parentNode;
            group.style.display="none";
            // console.log("### target id"+e.target.id);
            if(e.target.id =="region"){
                this.getProvinces(e.target.getAttribute('data-idsuggestion'));
                // console.log("region"+e.target.getAttribute('data-suggestion'));
                this.setState({
                    // ...this.setState,
                    regionSearch: e.target.getAttribute('data-suggestion'),
                    regionFlag:true,
                    provinceFlag:false,
                    provinceSearch:'',
                    districtFlag:false,
                    districtSearch:'',
                    regionId:e.target.getAttribute('data-idsuggestion')
                });
            }else if(e.target.id =="province"){
                this.getDistricts(e.target.getAttribute('data-idsuggestion'));
                // console.log("province chaja chaja"+e.target.getAttribute('data-suggestion'));
                this.setState({
                    // ...this.setState,
                    provinceSearch: e.target.getAttribute('data-suggestion'),
                    provinceFlag:true,
                    districtFlag:false,
                    districtSearch:'',
                    provinceId:e.target.getAttribute('data-idsuggestion')
                });
            }else if(e.target.id =="district"){
                // console.log("district chaja chaja"+e.target.getAttribute('data-idsuggestion'));
                // console.log("###### district is: "+this.state.districtId+" on HandleClick");

                this.setState({
                    // ...this.setState,
                    districtSearch: e.target.getAttribute('data-suggestion'),
                    districtFlag:true,
                    districtId:e.target.getAttribute('data-idsuggestion')
                });
            }


        }
    }

    render(){
        return(
            <React.StrictMode>
            <div className="politiciansContainer">
                <h1 className="politicians__title">Informate, Encuentra a tu candidato</h1>
                <div className="politicians__ubigeo">
                        <input className="politicians__input" autoComplete="off" onBlur={this.handleOnFocusOut} onChange={this.handleSearchOnChange} value={this.state.regionSearch} type="text"  name="region" id="" placeholder="Región"/>
                        <input className="politicians__input" autoComplete="off" onBlur={this.handleOnFocusOut} onChange={this.handleSearchOnChange} value={this.state.provinceSearch}  type="text"  name="province" id="" placeholder="Provincia"/>
                        <input className="politicians__input" autoComplete="off" onBlur={this.handleOnFocusOut} onChange={this.handleSearchOnChange} value={this.state.districtSearch} type="text"  name="district" id="" placeholder="Distrito"/>
                        <button onClick={this.getSearch} className="politicians__filterButton">Buscar</button>
                        <div className="politicians__wrapperRecomendationGroup">
                            <div className="politicians__regionRecomendationGroup">
                            {
                                this.state.data.filter((val)=>{
                                    // // console.log(val.name.toLowerCase());
                                    if(this.state.regionSearch ==""){
                                        return val;
                                    }
                                    else if(val.name.toLowerCase().includes(this.state.regionSearch.toLowerCase())){
                                        return val;
                                    }
                                }).map((element)=>{
                                    return(
                                        <div onClick={this.handleSelectSuggestion} data-idsuggestion={element.idRegion} data-suggestion={element.name} id="region" className="politicians__recomendationItem"> {element.name}</div>
                                    )
                                    }
                                )
                            }
                            </div>
                        </div>
                        <div className="politicians__wrapperRecomendationGroup">
                            <div className="politicians__provinceRecomendationGroup">
                            {
                                this.state.provinces.filter((val)=>{
                                    // // console.log(val.name.toLowerCase());
                                    if(this.state.provinceSearch ==""){
                                        return val;
                                    }
                                    else if(val.name.toLowerCase().includes(this.state.provinceSearch.toLowerCase())){
                                        return val;
                                    }
                                }).map((element)=>{
                                    return(
                                        <div onClick={this.handleSelectSuggestion} data-idsuggestion={element.idProvince} data-suggestion={element.name} id="province" className="politicians__recomendationItem"> {element.name}</div>
                                    )
                                    }
                                )
                            }
                            </div>
                        </div>
                        <div className="politicians__wrapperRecomendationGroup">
                            <div className="politicians__districtRecomendationGroup">
                            {
                                this.state.districts.filter((val)=>{
                                    // // console.log(val.name.toLowerCase());
                                    if(this.state.districtSearch ==""){
                                        return val;
                                    }
                                    else if(val.name.toLowerCase().includes(this.state.districtSearch.toLowerCase())){
                                        return val;
                                    }
                                }).map((element)=>{
                                    return(
                                        <div onClick={this.handleSelectSuggestion} data-idsuggestion={element.idDistrict}  data-suggestion={element.name} id="district"  className="politicians__recomendationItem"> {element.name}</div>
                                    )
                                    }
                                )
                            }
                            </div>
                        </div>
                </div>
                {/* <div className="politicians__filter">
                    <span className="politicians__filterOption">Alfabetico</span>
                    <span className="politicians__filterOption">Edad</span>
                    <span className="politicians__filterOption">Sexo</span>
                    <span className="politicians__filterOption">Nº denuncias</span>
                    <button onClick={this.getSearch} className="politicians__filterButton">Buscar</button>
                </div> */}
                <div className="politicians__list">
                        {
                       
                    this.state.candidates.map(function(element){
                        if(element.idPolitician== false){
                                return(
                                    <div className='politicians__messageNoPolitician'>
                                    <span> No existen políticos registrados <br/>
                                    <span className="politicians__messageStrong"> Puedes ser el primero!</span> <br/>Escribenos a:</span> 
                                    <span className='politicians__messageNoPoliticianRelevant'> contacto@politicosperu.pe</span>
                                    </div>
                                );
                        }else{
                            return(
                                <Profile
                                idPolitician={element.idPolitician}
                                name={element.name}
                                surnameFather={element.surnameFather}
                                surnameMother={element.surnameMother}
                                actualWork={element.actualWork}
                                location=""
                                webpage={element.webpage}
                                imgProfile={element.imgProfile}
                                />
                            )
                        }

                        }
                    )
                    
                    }
                </div>
            </div>
            <Footer/>
            </React.StrictMode>

        );
    }
   
}
export default Politicians;