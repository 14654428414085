import React from 'react';
import Header from '../components/Header';
// import Footer from '../components/Footer';
import "./Basic.css";

class Layout extends React.Component{

    constructor(props){
        super(props);
    }

    render(){
        return (
            <React.Fragment> 
            <Header flagCitizen={this.props.flagCitizen} domain={this.props.domain} closeSesion={this.props.closeSesion} token={this.props.token} tokenDescription={this.props.tokenDescription}/>
            {this.props.children}
            </React.Fragment> 
        );
    }

}
export default Layout;