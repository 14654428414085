import "./styles/profilePhotoModal.css";
import React from "react";
import axios from "axios";
import FileUploader from "../components/FileUploader";

class ProfileDetailsModal extends React.Component{
    constructor(props){
        super(props);
        this.state={
            regions:[],
            provinces:[],
            districts:[],
            regionShow:false,
            provinceShow:false,
            districtShow:false,
            regionFlag: true,
            provinceFlag:true,
            districtFlag:true,
            regionSearch:this.props.regionDescription,
            provinceSearch:this.props.provinceDescription,
            districtSearch:this.props.districtDescription,
            regionId:'',
            provinceId:'',
            districtId:'',
            data:[],
            idPolitician:this.props.idPolitician,
            name:this.props.name,
            surnameFather:this.props.surnameFather,
            surnameMother:this.props.surnameMother,
            birthDate:this.props.birthDate,
            day:'',
            month:'',
            year:"",
            regionDescription:this.props.regionDescription,
            provinceDescription:this.props.provinceDescription,
            districtDescription:this.props.districtDescription,
            actualWork:this.props.actualWork,
            maxGrade:this.props.maxGrade,
            income:this.props.income,
            politicalParty:this.props.politicalParty,
            loadingFlag:false
        }
    }


    handleOnFocusOut(e){
        
        setTimeout(function() {
            var recomendationRegions=document.getElementsByClassName('profileDetailsModal__'+e.target.name+'RecomendationGroup');
            recomendationRegions.item(0).style.display="none";
          }, 200);
    }

    updateLoadingFlag=()=>{
        this.setState({
            loadingFlag:true

        });
    }
    handleOnChange=(e)=>{
        // console.log(e);
        this.setState({
            [e.target.name]:e.target.value

        });
    }

    componentDidMount(){
        // console.log("this is flagModal"+this.props.flagModal);
    }
    componentWillUnmount(){
        // console.log("did unmount");
    }
    render(){
        if(this.props.flagBanner || this.props.flagProfile){
            return(
                <div onClick={this.props.handleCancelImageModal} className="profilePhotoModalWrapper">
                <div onClick={(e) => e.stopPropagation()} className="profilePhotoModalContainer">
                    <div className="profilePhotoModal__header">
                        {this.props.messageHeader}
                    </div>
                    <div className="profilePhotoModal__body">
                        <div className="profilePhotoModal__item">
                        <FileUploader
                        imageModal= {this.props.imageModal}
                        handleCancelImageModal={this.props.handleCancelImageModal}
                        updateImgProfile={this.props.updateImgProfile}
                        updateImgBanner={this.props.updateImgBanner}
                        idPolitician={this.props.idPolitician} 
                        flagBanner={this.props.flagBanner}
                        flagProfile={this.props.flagProfile}
                        updateLoadingFlag={this.updateLoadingFlag}
                        />
                        </div>
                    </div>
                    {this.state.loadingFlag &&
                    <div className="profilePhotoModal__loading">
                    </div>
                    }

                </div>
                </div>

            );
        }else{
            return null;
        }

    }
}

export default ProfileDetailsModal;