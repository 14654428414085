import React from "react";
import Footer from "../components/Footer";
import "./css/Recover.css";
import axios from "axios";

class Recover extends React.Component{
    constructor(props){
        super();
        this.state={
            email:"",
            flagSuccess:false,
            flagErrorServer: false,
            flagErrorEmail: false,
            flagEmailBlocked:false,
        }
    }
    handleOnChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    hideMessages=()=>{
        window.setTimeout(()=>{
            this.setState({
                flagSuccess:false,
                flagErrorServer: false,
                flagErrorEmail: false,
                flagEmailBlocked:false,
            })
        },4000);
    }
    recoverPassword= async()=>{
        axios.post('https://politicosperu.pe/php/recover.php',JSON.stringify({
            email: this.state.email
        })).then( response =>{
            //console.log(response.data);
            var arrayResponse= new Array(response.data[0]);

            if(arrayResponse[0].status == "success"){
                this.hideMessages();
                this.setState({
                    flagSuccess: true,
                    email: "",
                });
            }else if(arrayResponse[0].status == "error" && arrayResponse[0].attemps == "blocked" ){
                this.hideMessages();
                this.setState({
                    flagEmailBlocked: true,
                    email: "",
                });
            }else if(arrayResponse[0].status == "error"){
                this.hideMessages();
                this.setState({
                    flagErrorServer: true,
                    email: "",
                });
            }
            
        }
        ).catch(error=>{
            this.setState({

            });
        });
    }

    render(){
        return(
            <div className="ContainerRecover">
                <img className="RecoverBackground" src="https://politicosperu.pe/images/bgRecover.png" alt="" />
                <div className="WrapperRecover">
                    <div className="Recover__Whole">
                    <h3 className="Recover__header">Ingresa tu correo electrónico</h3>
                    {this.state.flagSuccess && <div className="ContainerRecover__successMessage"> Valido, Revisa tu correo</div>}
                    {this.state.flagEmailBlocked && <div className="ContainerRecover__errorMessage"> Muchos intentos, vuelva mas tarde</div>}
                    {this.state.flagErrorServer && <div className="ContainerRecover__errorMessage"> Hubo un error, intetelo nuevamente mas tarde</div>}
                    {this.state.flagErrorEmail && <div className="ContainerRecover__errorMessage"> Ingrese un correo valido</div>}
                    <input placeholder="Email" className="Recover__input" onChange={this.handleOnChange} name="email" type="text" value={this.state.email} />
                    <button onClick={this.recoverPassword} className="Recover__button">Confirmar</button>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}
export default Recover;