import React from "react";
import axios from "axios";
import './styles/socialnetworksEdit.css';
import imgFacebook from "./images/facebook1.png";
import imgWhatsapp from "./images/whatsapp.png";
import imgTwitter from "./images/twitter1.png";
import imgInstagram from "./images/instagram1.png";
import imgTiktok from "./images/tiktok1.png";

class SocialnetworksEdit extends React.Component{
    constructor(props){
        super(props);
        this.state={
            flagNetworksModal:false,
            title:'',
            linkFacebook:'',
            linkTwitter:'',
            linkInstagram:'',
            linkTiktok:'',
            index:'',
            socialnetwoks:[]
        }
    }
    handleSocialnetworksModal=(e)=>{
        //console.log(e.target.getAttribute('data-name'));
        this.getSupport();
        this.setState({
            flagNetworksModal:true,
            title:e.target.getAttribute('data-name'),
            index:e.target.getAttribute('data-index'),
        });
    }
    handleCloseSocialnetworksModal=()=>{
        this.setState({
            flagNetworksModal:false
        });
    }
    handleOnChangeNetwork=(e)=>{
        let items=[...this.state.socialnetwoks];
        items[this.state.index]=e.target.value;

        //console.log("onChange socialnetwork"+items[this.state.index]);
        this.setState({
            socialnetwoks:items
        });
    }
    handleUpdateSocialnetwork=()=>{
        var socialnetwork;
        if(this.state.index=="0"){
            socialnetwork="facebook";
            //console.log("entre al facebook"+this.state.socialnetwoks[this.state.index]);
            //console.log("entre al facebook"+this.props.idPolitician);
            //console.log("entre al facebook"+socialnetwork);
            //console.log("entre al facebook"+this.props.token);
        }else if(this.state.index=="1"){
            socialnetwork="twitter";
        }else if(this.state.index=="2"){
            socialnetwork='instagram';
        }else if(this.state.index=="3"){
            socialnetwork='instagram';
        }else{

        }

        axios.post('https://politicosperu.pe/php/updateSocialnetwork.php',JSON.stringify({idPolitician:this.props.idPolitician, socialNetwork:socialnetwork, link:this.state.socialnetwoks[this.state.index], token: this.props.token}))
        .then(response =>{
            //console.log(response.data);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    //console.log("#### valido, se actualizo la red social");
                    this.setState({
                        flagNetworksModal:false,
                        });
                    // this.getPoliticianDetails(this.getUrlPolitician());
            }else{
                //console.log("#### inValida updateSocialnetwork");
                this.setState({
                    flagNetworksModal:false,
                });
            }
    
        }).catch(error=>{
            //console.log("#### error");

            this.setState({
                flagNetworksModal:false,
            });
        });
    }

    getSupport=async()=>{
        // //console.log("###antes de validar"+this.state.cardValidation.tokenDescription);
        axios.post('https://politicosperu.pe/php/getSocialnetworks.php',JSON.stringify({idPolitician:this.props.idPolitician}))
        .then(response =>{
            //console.log(response.data);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    //console.log("#### valido, traemos las redes sociales");
                    this.setState({
                        flagNetworksModal:true,
                        socialnetwoks:[response.data[0].linkFacebook,response.data[0].linkTwitter,response.data[0].linkInstagram,response.data[0].linkTiktok],
                        linkFacebook:response.data[0].linkFacebook,
                        linkTwitter:response.data[0].linkTwitter,
                        linkInstagram:response.data[0].linkInstagram,
                        linkTiktok:response.data[0].linkTiktok,
                        });
                    // this.getPoliticianDetails(this.getUrlPolitician());
            }else{
                //console.log("#### inValida");
                this.setState({
                    flagSupport:false,
                });
            }
    
        }).catch(error=>{
            //console.log("#### error");

            this.setState({
                flagSupport:false,
            });
        });

    }

    render(){
        return(
            <div className="socialnetworksEditContainer">
                <div className="socialnetworksEditWrapper">
                    <div className="socialnetworksEdit__title">
                        Mis redes Sociales
                    </div>
                    <div className="socialnetworksEdit__list">

                        <div data-index="0" data-name="Facebook" onClick={this.handleSocialnetworksModal} className="socialnetworksEdit__item">
                            <img data-index="0" data-name="Facebook" className="socialnetworksEdit__icon" src={imgFacebook} alt="" />
                        </div>
                        <div data-index="1" data-name="Twitter" onClick={this.handleSocialnetworksModal} className="socialnetworksEdit__item">
                            <img data-index="1" data-name="Twitter" className="socialnetworksEdit__icon socialnetworksEdit__icon--twitter" src={imgTwitter} alt="" />
                        </div>
                        <div data-index="2" data-name="Instagram" onClick={this.handleSocialnetworksModal} className="socialnetworksEdit__item">
                            <img data-index="2" data-name="Instagram" className="socialnetworksEdit__icon" src={imgInstagram} alt="" />
                        </div>
                        <div data-index="3" data-name="Tiktok" onClick={this.handleSocialnetworksModal} className="socialnetworksEdit__item">
                            <img data-index="3" data-name="Tiktok" className="socialnetworksEdit__icon" src={imgTiktok} alt="" />
                        </div>
                    </div>
                </div>
                {this.state.flagNetworksModal && 
                <div onClick={this.handleCloseSocialnetworksModal} className="socialnetworksEditModalContainer">
                    <div onClick={(e)=>e.stopPropagation()}  className="socialnetworksEditModalWrapper">
                    <h4 className="socialnetworksEditModal__title">{this.state.title}</h4>
                    <div className="socialnetworksEditModal__content">
                        <input onChange={this.handleOnChangeNetwork} className="socialnetworksEditModal__input" type="text" placeholder={"Link actual de "+this.state.title} value={this.state.socialnetwoks[this.state.index]}/>
                        <button onClick={this.handleUpdateSocialnetwork} className="socialnetworksEditModal__button">Actualizar</button>
                    </div>
                    </div>
                </div>
                }
            </div>
            );
    }
}
export default SocialnetworksEdit;