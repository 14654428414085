import heroImg1 from './images/lawsuit.jpg';
import './styles/heroLawsuits.css';
import LawsuitsForm from './LawsuitsForm';
import Button from './Button';
import React from 'react';


const expression = {
	user: /^[a-zA-Z0-9\_\-]{4,16}$/, // Letras, numeros, guion y guion_bajo
	name: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
	title: /^[a-zA-ZÀ-ÿ\s]{1,150}$/, // Letras y espacios, pueden llevar acentos.
	description: /^.{1,450}$/, // Letras y espacios, pueden llevar acentos.
	password: /^.{4,12}$/, // 4 a 12 digitos.
	correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
	phone: /^\d{9,14}$/, // 7 a 14 numeros.
    dni: /^\d{8}$/,
    link:/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
}

class heroLawsuits extends React.Component{
    state={
       form:{
           name:"",
           phone:"",
           dni:"",
           anonymouse:false,
           title:"",
           description:"",
           namePolitician:"",
           dniPolitician:"",
           link:"",
        // validForm: false,
            recaptcha:false,
            stepOne:false,
            stepTwo:false,
            stepThree:false,
            validParam:false
        }
    }

    handleResetForm=()=>{
        this.setState(
            {
                form:{
                    name:"",
                    phone:"",
                    dni:"",
                    anonymouse:false,
                    title:"",
                    description:"",
                    namePolitician:"",
                    dniPolitician:"",
                    link:"",
                 // validForm: false,
                     recaptcha:false,
                     stepOne:false,
                     stepTwo:false,
                     stepThree:false,
                     validParam:false
                }
            }

        )
    }
    handleLawsuit=e=>{
        const newForm=this.state.form;
        newForm[e.target.name]=e.target.value;
        
        if(e.target.name =="name"){
            if(!expression.name.test(this.state.form.name)){
                e.target.style.color="red";
                newForm.validParam=false;   
            }else{
                e.target.style.color="black";
                newForm.validParam=true;   
            }  
        }
        if(e.target.name =="dni"){
            if(!expression.dni.test(this.state.form.dni)){
                e.target.style.color="red";
                newForm.validParam=false;   
            }else{
                e.target.style.color="black";
                newForm.validParam=true;
            }  
        }
        if(e.target.name =="phone"){
            if(!expression.phone.test(this.state.form.phone)){
                e.target.style.color="red";
                newForm.validParam=false;   
            }else{
                e.target.style.color="black";
                newForm.validParam=true;
            }  
        }
        if(e.target.name =="title"){
            if(!expression.title.test(this.state.form.title)){
                e.target.style.color="red";
                newForm.validParam=false;   
            }else{
                e.target.style.color="black";
                newForm.validParam=true;
            }  
        }
        if(e.target.name =="description"){
            if(!expression.description.test(this.state.form.description)){
                e.target.style.color="red";
                newForm.validParam=false;   
            }else{
                e.target.style.color="black";
                newForm.validParam=true;
            }  
        }
        if(e.target.name =="namePolitician"){
            if(!expression.name.test(this.state.form.namePolitician)){
                e.target.style.color="red";
                newForm.validParam=false;   
            }else{
                e.target.style.color="black";
                newForm.validParam=true;
            }  
        }
        if(e.target.name =="dniPolitician"){
            if(!expression.dni.test(this.state.form.dniPolitician)){
                e.target.style.color="red";
                newForm.validParam=false;   
            }else{
                e.target.style.color="black";
                newForm.validParam=true;
            }  
        }
        if(e.target.name =="link"){
            if(!expression.link.test(this.state.form.link)){
                e.target.style.color="red";
                newForm.validParam=false;   
            }else{
                e.target.style.color="black";
                newForm.validParam=true;
            }  
        }


        //checking the checkbox
        if(e.target.name=="anonymouse"){
            if(e.target.checked){
                newForm[e.target.name]=e.target.checked;             
            }else{
                newForm[e.target.name]=e.target.checked;             
            }
        }

        //checking step one
        if(this.state.form.anonymouse == true){
            if(this.state.form.phone != ""){
                newForm.stepOne=true;
            }else{
                newForm.stepOne=false;
            }
        }else{
            
        if(this.state.form.name !="" && this.state.form.phone !="" && this.state.form.dni !=""){
            let userExpression=expression.user;                          
                newForm.stepOne=true;
                // console.log("true anonymouse");
            }else{
                // console.log("false anonymouse");
                newForm.stepOne=false;
            }
        }

        //checking step two
        if(this.state.form.title != "" && this.state.form.description !="" && (this.state.form.namePolitician != "" || this.state.form.dniPolitician != "")){
            newForm.stepTwo=true; 
        }else{
            newForm.stepTwo=false; 
        }
        //checking step three
        if(this.state.form.link != ""){
            newForm.stepThree=true; 
        }else{
            newForm.stepThree=false; 
        }

        this.setState({
            form: newForm
        }); 	                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            	                                        
    }
    

    render(){
        // console.log(this.state.form);

        return(
            <div className="HeroLawsuits">
            <div className="HeroLawsuits__imageContainer">
                <img class="HeroLawsuits__image" src={heroImg1} alt="" />
                <div className="HeroLawsuits__gradient"></div>
            </div>
            <div className="HeroLawsuits__message">
            <span className="HeroLawsuits__messageRelevant">Denunciar</span> es hacer política
            </div>
            <div className="HeroLawsuits__form">
            <LawsuitsForm 
            validForm={this.handleLawsuit} 
            dataForm={this.state.form}
            handleResetForm={this.handleResetForm}
            />
            </div>
            {/* <div className="HeroLawsuits__demandBtn">
                <Button 
                validForm={this.state.form.validForm}
                />
            </div> */}
        </div>
    );
    }
}

export default heroLawsuits;