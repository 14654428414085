import React from "react";
import "./styles/confirmationActionModal.css"
class ConfirmationActionModal extends React.Component{
    constructor(props){
        super(props);
        this.state={
            inputValue:'',
            value:Math.floor(Math.random()*10000),
            flagError:false
        }
    }

    handleOnChangeInputValue=(e)=>{
        this.setState({
            inputValue:e.target.value
        });
    }
    showError=()=>{
        this.setState({
            flagError:true
        });
    }
    confirmCode=()=>{
        if(this.state.value == this.state.inputValue){
            this.props.handleOnClickMethod();
        }else{
            this.showError();
        }
    }
    render(){
        return(
            <div onClick={this.props.handleCloseModal} className="ConfirmationActionModalContainer">
                <div onClick={(e)=>{e.stopPropagation()}} className="ConfirmationActionModalWrapper">
                    <div className="ConfirmationActionModal__header">
                        <span className="ConfirmationActionModal__title">{this.props.header}</span>
                        <span onClick={this.props.handleCloseModal} className="ConfirmationActionModal_close">x</span>
                    </div>
                    <div className="ConfirmationActionModal__body">
                        <div className="ConfirmationActionModal__bodyContainer">
                            <span>
                        {this.props.message}
                            </span>
                            <span className="ConfirmationActionModal__bodyCode">
                                {this.state.value}
                            </span>
                        </div>
                        <div className="ConfirmationActionModal__bodyInputContainer">
                            <input className="ConfirmationActionModal__bodyInput" placeholder="Ingrese el Codigo" onChange={this.handleOnChangeInputValue} value={this.state.inputValue} type="text" />
                            {this.state.flagError && <span className="ConfirmationActionModal__error">código erroneo </span>}
                        </div>
                    </div>
                    <div className="ConfirmationActionModal__footer">
                    <button onClick={this.confirmCode} className="ConfirmationActionModal__button"> Confirmar</button>
                    </div>
                </div>
            </div>
        );
    }
}
export default ConfirmationActionModal;