import React from "react";
import "./styles/supportList.css";
import axios from "axios";

class SupportList extends React.Component{
    constructor(props){
        super(props);
        this.state={
            followers:[],
            flagSupport:false,
            token: false,
            flagModalAddSupport:false,
            flagModalAddSupportCitizen:false,
            flagModalAddSupportPolitician:false,
            tokenDescription:null,
            flagCitizen:false,
            domain:''
        }

    }
    readCookie=(name)=> {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
    createCookie=(name,value,days)=> {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            var expires = "; expires="+date.toGMTString();
        }
        else var expires = "";
        document.cookie = name+"="+value+expires+"; path=/";
    }
    
    eraseCookie=(name)=>{
        this.createCookie(name,"",-1);
    }
    

    handleSupportPolitician=(e)=>{
        if(this.readCookie("token") != null && this.readCookie("idCitizen") != null){
            //console.log("hay cookie de citizen");
            // //console.log("este es"+this.readCookie("token"));
            //console.log("here the idCitizen from cookie"+this.readCookie("idCitizen"));

            if(e.target.id=="citizenIdButton"){

            }
        }else if(this.readCookie("token") !=null && this.readCookie("idPolitician") !=null){
            //console.log("hay cookie de politician");
            if(e.target.id=="politicianIdButton" && this.readCookie("idPolitician") != this.props.idPolitician){
                this.putSupportPolitician();
            }else{
                //console.log("querias");
            }
        }
        else{
          this.setState(
            {
                token: false,
                flagModalAddSupport:true,
                flagModalAddSupportCitizen:false,
                flagModalAddSupportPolitician:false,
                tokenDescription:null,
                domain:''
            }
        );
        }
    }
    getSupport=async()=>{
        // //console.log("###antes de validar"+this.state.cardValidation.tokenDescription);
        axios.post('https://politicosperu.pe/php/getSupport.php',JSON.stringify({idPolitician:this.props.idPolitician}))
        .then(response =>{
            //console.log(response.data);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    //console.log("#### valida se trae el listado de políticos");
                    this.setState({
                        flagSupport:true,
                        followers:response.data,
                        amountPolitician: response.data.length
                    });
                    // this.getPoliticianDetails(this.getUrlPolitician());
            }else{
                //console.log("#### inValida");
                this.setState({
                    flagSupport:true,
                });
            }
    
        }).catch(error=>{
            //console.log("#### error");

            this.setState({
                flagSupport:true,
            });
        });

    }
    putSupportPolitician=async()=>{
        // //console.log("###antes de validar"+this.state.cardValidation.tokenDescription);
        var politician1=this.readCookie("idPolitician");
        axios.post('https://politicosperu.pe/php/putSupportPolitician.php',JSON.stringify({idPolitician:this.props.idPolitician,idPolitician1:politician1}))
        .then(response =>{
            //console.log("### respuesta del server put"+response.data[0]);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    //console.log("#### se debería agregar el support"+arrayResponse[0].status);
                    this.setState({
                        amountPolitician:this.state.amountPolitician +1
                    });
                    // this.getPoliticianDetails(this.getUrlPolitician());
            }
    
        }).catch(error=>{
            //console.log("#### no puedes dar respaldo a este político"+error);
        });
    }

    handleChangeToLogin=(e)=>{
        var modal = document.getElementsByClassName("support__addModalCreateAccount").item(0);
        var modal2 = document.getElementsByClassName("support__addModalLogin").item(0);
        var footer = document.getElementsByClassName("support__addModalFooter").item(0);

        footer.style.display="none";
        // modal.style.visibility="hidden";
        modal.style.transform="translate(-250px)";
        modal2.style.transform="translate(-250px)";
    }

    handleCheckboxs=(e)=>{
        var checkbox1=document.getElementById("politicCheckbox");
        var checkbox2=document.getElementById("citizenCheckbox");

        if(checkbox1.checked && e.target.name=="politico"){
            checkbox2.checked=false;
        }
        if(checkbox2.checked){
            checkbox1.checked=false;
        }

    }

    handleCheckSupports=()=>{
        this.setState({
            flagModal:true
        });
    }
    handleCloseModal=(e)=>{
        this.setState({
            flagModal:false
        });
    }

    handleCloseAddModal=()=>{
        this.setState({
            flagModalAddSupport:false
        });
    }

    componentDidUpdate(){
        if(this.props.idPolitician !='' && this.state.flagSupport==false){
            this.getSupport();
        }
    }

    render(){
        return(
            <div className="supportListContainer">
            <div className="supportList__items">
                { (() => {
                    if(!this.state.flagSupport || this.state.followers.length <= 5) {
                            return (
                                <React.Fragment>
                                <img className="supportPolitics__item" src={this.props.imageProfile} alt="" />
                                <div className="supportPolitics__default">
                                    <span className="supportPolitics__defaultText">Crees que:</span>
                                    <span className="supportPolitics__defaultName">{this.props.name} {this.props.surnameFather} {this.props.surnameMother}</span>
                                    <div className="supportPolitics__defaultBottom">
                                        <span className="supportPolitics__defaultText">Hace buena política?</span>
                                        <button onClick={this.handleSupportPolitician} className="supportPolitics__defaultButton">Respaldar</button>
                                    </div>
                                </div>
                            </React.Fragment> 
                            )
                        } 
 
                 })() 
                }

            </div>
            {this.state.followers.length >=1 &&
            <button onClick={this.handleCheckSupports} className="supportPoliticsList__button">Más</button>
            }
                            {this.state.flagModal &&
                    <div onClick={this.handleCloseModal} className="support__modalContainer">
                        <div onClick={(e)=>e.stopPropagation()} className="support__modalWrapper">
                            <h1 className="support__modalHeader">Mi respaldo político</h1>
                            <div className="support__modalContent">
                            {this.state.flagSupport && this.state.followers.map(
                                (follower)=>{
                                    return(
                                        <img key={follower.idPolitician} className="supportPolitics__item" src={follower.imgProfile} alt="" />
                                        )
                                }
                            )

                            }
                            </div>
                            <button onClick={this.handleGetMoreFollowers} className="supportPoliticsList__buttonModal">Más</button>
                        </div>
                    </div>
                }
            {this.state.flagModalAddSupport &&
                <div onClick={this.handleCloseAddModal} className="support__addModalContainer">
                    <div onClick={(e)=>e.stopPropagation()} className="support__addModalWrapper">
                        <div className="support__addModalCreateAccount">
                            <h1 className="support__addModalHead">
                                Deseo mejorar mi país
                            </h1>
                            <div className="support__addModalContent">
                                <h2 className="support__addModalWelcome">
                                    Bienvenido!
                                </h2>
                                <p className="support__addModalMessage">
                                    Estas a punto de impulsar un gran cambio <span className="support__addModalMessage--red">Unete!</span>
                                </p>
                                <div className="support__addModalInputContainer">
                                <input className="support__addModalInput" type="text" placeholder="DNI" />
                                <input className="support__addModalInput" type="password" placeholder="Clave"/>
                                <input className="support__addModalInput" type="text" placeholder="Email" />
                                </div>
                                <div className="support__addModalOptions">
                                    <div className="support__addModalOption">
                                        <input onClick={this.handleCheckboxs} id="politicCheckbox" className="support__addModalInputCheckbox" type="checkbox" name="politico"/> <label htmlFor="politico">Político</label>
                                    </div>
                                    <div className="support__addModalOption">
                                        <input onClick={this.handleCheckboxs} id="citizenCheckbox" className="support__addModalInputCheckbox" type="checkbox" name="ciudadano"/> <label htmlFor="ciudadano">Ciudadano</label>
                                    </div>
                                </div>
                            </div>
                            <div className="support__addModalFooter">
                                <button className="support__addModalButton">Crear Cuenta</button>
                                <span onClick={this.handleChangeToLogin} className="support__addModalTextFooter">ya poseo una cuenta</span>
                            </div>
                        </div>
                        <div className="support__addModalLogin">
                        <h1 className="support__addModalHead">
                                Deseo mejorar mi país
                            </h1>
                            <div className="support__addModalContent">
                                <h2 className="support__addModalWelcome">
                                    Es grato tenerte de vuelta!
                                </h2>
                                <p className="support__addModalMessage">
                                    Sigamos construyendo un país mejor <span className="support__addModalMessage--red">Viva el Perú!</span>
                                </p>
                                <div className="support__addModalInputContainer">
                                <input className="support__addModalInput" type="text" placeholder="DNI" />
                                <input className="support__addModalInput" type="password" placeholder="Clave"/>
                                </div>
                            </div>
                            <div className="support__addModalFooter">
                                <button className="support__addModalButton">Iniciar Sesión</button>
                                <span className="support__addModalTextFooter">Olvide mi contraseña</span>
                            </div>
                        </div>
                    </div>
                </div>

                }
        </div>
        );
    }
}
export default SupportList;