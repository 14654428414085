import React from "react";
import "./css/ConfirmCitizen.css";
import { Link } from "react-router-dom";
import axios from "axios";

class ConfirmCitizen extends React.Component{
    constructor(props){
        super(props);
        this.state={
            flagErrorServer:false,
            url:"",
            attemp: false,
        }
    
    }

    confirm=async()=>{
        axios.post('https://politicosperu.pe/php/confirmCitizen.php',
        JSON.stringify({
            url: this.state.url
       }))
       .then(response =>{
           //console.log(response.data);
           var arrayResponse= new Array(response.data[0]);
   
           if(arrayResponse[0].status == "success"){  
                   //console.log("#### revisa tu correo");
                   this.setState({
                       flagErrorServer:false,
                       attemp:true,
                   });
                   // this.getPoliticianDetails(this.getUrlPolitician());
           }else{
               //console.log("#### no se envio el correo");
               this.setState({
                   flagErrorServer:true,
                   attemp:true,
               });
           }
   
       }).catch(error=>{
           //console.log("#### error");


           this.setState({
               flagErrorServer:true,
                attemp:true,
           });
       });

    }


    componentDidMount(){

        var finalUrl= window.location.href.split("/");

        this.setState({
            url: finalUrl[4]
        });
    }

    componentDidUpdate(){
        if(this.state.attemp == false && this.state.url !=""){
            this.confirm();
        }
    }

    render(){
        return(
            <div className="ConfirmCitizenContainer">
                <div className="ConfirmCitizenWrapper">
                    <div className="ConfirmCitizen__header">
                       {(this.state.attemp == true && this.state.flagErrorServer ==false) && 
                       <h3 className="ConfirmCitizen__Title">Tu cuenta ha sido Confirmada</h3> } 
                       {(this.state.attemp == true && this.state.flagErrorServer ==false) && 
                       <div className="ConfirmCitizen__body">
                       <Link to="/" className="ConfirmCitizen__button">Continuar</Link>
                       </div>
                       } 
                    </div>
                    
                </div>
                
            </div>
        );
    }
}
export default ConfirmCitizen;