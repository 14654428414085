import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import './css/citizenProfile.css';
import Footer from "../components/Footer";


class CitizenProfile extends React.Component {

    constructor(props){
        super(props);
        this.state={
            cardValidation:{
                urlUser:this.readCookie("idCitizen"),
                token: this.props.token,
                tokenDescription:this.readCookie("token"),
            },
            phone:'',
            code:'',
            supports:[],
            flagCodeSent:false,
            flagSupport:false,
            flagValid:false,
            flagPhone:false,
            flagVerified:false,
            flagVerifiedCodeError:false,
            attemps:0,
            flagNoMore:false,
            flagValidNumber:false,
        }
    }


    readCookie=(name)=> {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    getCitizenDetails=async(query,phone, verified, attemps)=>{
        axios.post('https://politicosperu.pe/php/getCitizenSupport.php',JSON.stringify({idCitizen:query}))
        .then(response =>{
            //console.log(response.data);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    //console.log("#### valida la sesión");
                    if(phone !="" && verified !="1"){
                        //console.log("este es el telefono"+phone);
                        this.setState({
                            flagValid:true,
                            flagVerified:false,
                            flagPhone:false,
                            flagSupport:true,
                            supports:response.data,
                            attemps: attemps ==3 ? 4 : attemps
                        });
                    }else if(phone !="" && verified=="1"){
                        //console.log("este es el verified"+verified);
                        this.setState({
                            flagValid:true,
                            flagVerified:true,
                            flagPhone:true,
                            flagSupport:true,
                            supports:response.data,
                            attemps: attemps ==3 ? 4 : attemps
                        });
                    }else{
                        this.setState({
                            flagValid:true,
                            flagVerified:false,
                            flagPhone:false,
                            flagSupport:true,
                            supports:response.data,
                            attemps: attemps ==3 ? 4 : attemps
                        });
                    }

                    // this.getPoliticianDetails(this.getUrlPolitician());
            }else{
                //console.log("#### no hay supporters");
                if(phone !="" && verified !="1"){
                    //console.log("este es el telefono"+phone);
                    this.setState({
                        flagValid:true,
                        flagVerified:false,
                        flagPhone:false,
                        flagSupport:false,
                        supports:response.data,
                        attemps: attemps ==3 ? 4 : attemps
                    });
                }else if(phone !="" && verified=="1"){
                    //console.log("este es el verified"+verified);
                    this.setState({
                        flagValid:true,
                        flagVerified:true,
                        flagPhone:true,
                        flagSupport:false,
                        supports:response.data,
                        attemps: attemps ==3 ? 4 : attemps
                    });
                }else{

                    this.setState({
                        flagValid:true,
                        flagSupport:false,
                        attemps: attemps ==3 ? 4 : attemps
                    });
                }
            }
    
        }).catch(error=>{
            //console.log("#### error");

            this.setState({
                flagValid:true,
                flagSupport:false,
            });
        });

    }
    handleSendPhone=()=>{
        if(this.state.flagValidNumber){
            //console.log("###antes de validar"+this.state.cardValidation.tokenDescription);
            axios.post('https://politicosperu.pe/php/updatePhone.php',JSON.stringify({idCitizen:this.state.cardValidation.urlUser, phone: this.state.phone, token:this.state.cardValidation.tokenDescription}))
            .then(response =>{
                //console.log("############# attemps");
                //console.log(response);
                var arrayResponse= new Array(response.data[0]);
        
                if(arrayResponse[0].status == "success"){  
                        //console.log("#### Codigo enviado"+arrayResponse[0].idCitizen);
                        this.setState({
                            ...this.state,
                            flagCodeSent:true,
                            attemps:arrayResponse[0].attemps
                        });
    
                }else{
                    // //console.log("#### citizen inValida"+arrayResponse[0].urlUser+"-- token: "+arrayResponse[0].token);
                    //console.log("no se pudo cambiar el phone");
    
                    this.setState({
                        flagValid:false
                    });
                }
        
            }).catch(error=>{
                this.setState({
                    flagValid:false
                });
            });
        }else{
            alert("Ingrese un número valido");
        }


    }

    validateOpenSesion=async()=>{
        //console.log("###antes de validar"+this.state.cardValidation.tokenDescription);
        axios.post('https://politicosperu.pe/php/validateCitizenSesion.php',JSON.stringify(this.state.cardValidation))
        .then(response =>{
            // //console.log(response);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    //console.log("#### citizen valida la sesión"+arrayResponse[0].idCitizen);
                    //console.log("difference hours : "+arrayResponse[0].hours);
                    //console.log("difference days : "+arrayResponse[0].days);
                    this.getCitizenDetails(arrayResponse[0].idCitizen,arrayResponse[0].phone,arrayResponse[0].verified, arrayResponse[0].attemps);

            }else{
                // //console.log("#### citizen inValida"+arrayResponse[0].urlUser+"-- token: "+arrayResponse[0].token);
                //console.log("#### citizen invalida la sesión");

                this.setState({
                    flagValid:false
                });
            }
    
        }).catch(error=>{
            this.setState({
                flagValid:false
            });
        });

    }

    handleVerifyPhoneCode=async(e)=>{

            axios.post('https://politicosperu.pe/php/verifyPhoneCode.php',JSON.stringify({idCitizen : this.readCookie("idCitizen"), token : this.state.cardValidation.tokenDescription, code :  this.state.code}))
            .then(response =>{
                //console.log(response);
                var arrayResponse= new Array(response.data[0]);
        
                if(arrayResponse[0].status == "success"){  
                      
                    this.setState({
                        flagVerified:true,
                    });
    
                }else{
                    // //console.log("#### citizen inValida"+arrayResponse[0].urlUser+"-- token: "+arrayResponse[0].token);
                    //console.log("#### no se pudo verificar");
                    this.setState({
                        flagVerifiedCodeError:true
                    });
    
                }
        
            }).catch(error=>{
    
            });
        

    }


    handleDeleteSupport=async(e)=>{

        
        var idPolitician= e.currentTarget.getAttribute("data--idpolitician");
        var index=e.currentTarget.getAttribute("data--index");
        //console.log("### id Politician"+idPolitician);
        //console.log("### id index"+index);
        //console.log("### id Citizen"+this.readCookie("idCitizen"));
        
        axios.post('https://politicosperu.pe/php/deleteSupportCitizen.php',JSON.stringify({idCitizen : this.readCookie("idCitizen"), idPolitician : idPolitician, token : this.state.cardValidation.tokenDescription}))
        .then(response =>{
            //console.log(response);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                  
                var newArray = this.state.supports;
                newArray.splice(index, 1);

                this.setState({
                    supports: [...newArray],
                });
            }else{
                // //console.log("#### citizen inValida"+arrayResponse[0].urlUser+"-- token: "+arrayResponse[0].token);
                //console.log("#### no se pudo eliminar");

            }
    
        }).catch(error=>{

        });

    }

    handleTryAgain=()=>{
        if(this.state.attemps ==3){
            this.setState({
                ...this.state,
                flagVerifiedCodeError:false,
                flagPhone:false,
                flagCodeSent:false,
                phone:'',
                code:'',
                attemps:4
            });
        }else{
            this.setState({
                ...this.state,
                flagVerifiedCodeError:false,
                flagPhone:false,
                flagCodeSent:false,
                phone:'',
                code:'',
            });
        }

    }

handlePhone=(e)=>{
    //console.log("calling onchange"+e.target.value);
    var pattern = new RegExp("^\\d{9}$");
        this.setState({
            [e.target.name]: e.target.value,
            flagValidNumber:(e.target.value.match(pattern)== null) ? false : true
           }); 

}
handleCode=(e)=>{

        this.setState({
            [e.target.name]: e.target.value,
           }); 
}

    componentDidMount(){
        //console.log("##### calling the componentDidMount");
        this.validateOpenSesion();
    }
    componentDidUpdate(){
        if(this.state.attemps == 0){

        }
    }

    render(){
        if(!this.state.flagValid){
            return(
                <div className="userProfileFail"> sesión invalida</div>
            );
        }else{
            return(
                <div className="citizenProfileContainer">
                    <div className="citizenProfileWrapper">
                        <div className="citizenProfile__top">
                            <div className="citizenProfile__topLeft">
                                <h1 className="citizenProfile__topTitle">Bienvenido!</h1>
                                <p className="citizenProfile__topMessage">
                                    Gracias por querer mejorar nuestro país <span className="citizenProfile__topMessage--red"> Vales un Perú</span>
                                </p>
                            </div>
                            <div className="citizenProfile__topRight">
                            {(!this.state.flagVerified) ? ((this.state.attemps - 3) <= 0)? <div className="citizenProfile__topbox">
                                    {(!this.state.flagPhone && !this.state.flagCodeSent ) &&
                                    <input onChange={this.handlePhone} value={this.state.phone} name="phone" className="citizenProfile__topInput" type="text"  placeholder="Celular"/>
                                    }
                                    {(!this.state.flagPhone && !this.state.flagCodeSent) && <button onClick={this.handleSendPhone} className="citizenProfile__topButton">Verificar</button>}
                                    
                                    
                                    {(!this.state.flagPhone && this.state.flagCodeSent && !this.state.flagVerifiedCodeError ) &&
                                    <input onChange={this.handleCode} value={this.state.code} name="code" className="citizenProfile__topInput" type="text"  placeholder="Codigo"/>
                                    }
                                    {(this.state.flagVerifiedCodeError && this.state.attemps >0 ) && <span className="citizenProfile__errorAttemps">Codigo Incorrecto <br/> restan {3 - this.state.attemps} Intentos</span>}
                                    {(!this.state.flagPhone && this.state.flagCodeSent && !this.state.flagVerifiedCodeError ) && <button onClick={this.handleVerifyPhoneCode} className="citizenProfile__topButton">Validar</button>}
                                    {(!this.state.flagPhone && this.state.flagCodeSent && this.state.flagVerifiedCodeError ) && <button onClick={this.handleTryAgain} className="citizenProfile__topButton">Reintentar</button>}

                                </div> 
                                :
                                <div className="citizenProfile__topbox">
                       
                                <input  name="phone" className="citizenProfile__topInput" type="text"  placeholder="Celular"/>
                                <button className="citizenProfile__topButton">bloqueado</button>
                                </div>: <div className="citizenProfile__topbox"> <h3 className="citizenProfile__topText">Conoce a los políticos</h3>
                                     <Link to="/politicians"> <button className="citizenProfile__topButton">Buscar</button></Link></div>
                            
                            }


                            </div>
                        </div>
                        {!this.state.flagVerified && 
                            <div className="citizenProfile__middle">
                                <div className="citizenProfile__middleLeft">
                                    La transparencia es nuestro pilar
                                </div>
                                {this.state.attemps - 3 <= 0 ?<div className="citizenProfile__middleRight">
                                    {!this.state.flagPhone && <h2 className="citizenProfile__middleMessage">Tienes 24 Horas para verificar tu cuenta</h2>}
                                    {this.state.flagPhone && <h2 className="citizenProfile__middleMessage">Tu cuenta será verificada a la brevedad</h2>}
                                    {!this.state.flagPhone &&<span className="citizenProfile__middleSubText">Solo Celulares Nacionales <br/> Omitir (+51) </span>}
                                </div> : <div className="citizenProfile__middleRight">
                                    {<h2 className="citizenProfile__middleMessage">Intentalo nuevamente más tarde</h2>}
                                </div>  }
                                
                            </div>
                            }

                        <div className="citizenProfile__bottom">
                            <h2 className="citizenProfile__bottomTitle">Mis respaldos</h2>
                            <div className="citizenProfile__bottomSupports">
                            {this.state.flagSupport && this.state.supports.map(
                                (follower,index)=>{
                                    return(
                                        <div className="citizenProfile__bottomSupportItem">
                                            <Link to={"/"+follower.webpage}>
                                            <img key={follower.idPolitician} className="citizenProfile__bottomSupportImage" src={follower.imgProfile} alt="" />
                                            </Link>
                                            <p data--idpolitician={follower.idPolitician} data--index={index} onClick={this.handleDeleteSupport} className="citizenProfile__bottomSupportDelete">Quitar</p>
                                        </div>

                                        )
                                }
                            )
                            }
                            {(!this.state.flagSupport || this.state.supports.length == 0) &&  <div className="citizenProfile__bottomNoSupports">Aun no respaldas a ningún político</div>

                            }
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            );
        }

    }


}

export default CitizenProfile;