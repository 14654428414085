import "./styles/profileEditLawsuitModal.css";
import React from "react";
import axios from "axios";


class ProfileEditLawsuitModal extends React.Component{
    constructor(props){
        super(props);

        if(this.props.idDisclaimer==null){
            this.state={
                item:this.props.data[this.props.index],
                flagScroll:false,
                disclaimerDescription: "",
                disclaimerLink:"",
                data:{
                    idLawsuit:this.props.data[this.props.index].idLawsuit,
                    idDisclaimer:"",
                    description:"",
                    link:"",
                    date:""
                }
            }
        }else{
            this.state={
                item:this.props.data[this.props.index],
                flagScroll:false,
                disclaimerDescription: this.props.disclaimer[0].description,
                disclaimerLink:this.props.disclaimer[0].link,
                data:{
                    idLawsuit:this.props.data[this.props.index].idLawsuit,
                    idDisclaimer:this.props.idDisclaimer,
                    description:this.props.description,
                    link:this.props.link,
                    date:this.props.date,
                }
            }
        }

    }

    handleOnFocusOut(e){
        
        setTimeout(function() {
            var recomendationRegions=document.getElementsByClassName('profileDetailsModal__'+e.target.name+'RecomendationGroup');
            recomendationRegions.item(0).style.display="none";
          }, 200);
    }
    scroll=(e)=>{
        var element=document.getElementsByClassName("profileLawsuitModalContainerDescription");
        var element2=document.getElementsByClassName("profileLawsuitModalContainerDisclaimer");
        element.item(0).style.transform="translate(-100%)";
        element2.item(0).style.transform="translate(-100%)";

    }
    scrollback=(e)=>{
        var element=document.getElementsByClassName("profileLawsuitModalContainerDescription");
        var element2=document.getElementsByClassName("profileLawsuitModalContainerDisclaimer");
        element.item(0).style.transform="translate(0%)";
        element2.item(0).style.transform="translate(0%)";
    }

    handleDisclaimerDescription=(e)=>{
        this.setState({
            disclaimerDescription:e.target.value,
            data:{
                ...this.state.data,
                description:e.target.value
            }
        });
    }
    handleDisclaimerLink=(e)=>{
        this.setState({
            disclaimerLink:e.target.value,
            data:{
                ...this.state.data,
                link:e.target.value
            }
        });
    }

    sendDisclaimer=async()=>{

        console.log(JSON.stringify(this.state.data));
      
        axios.post('https://politicosperu.pe/php/registerDisclaimer.php',JSON.stringify(this.state.data))
        .then(response =>{
            // console.log(response);
            if(response.data[0].status =="success"){
                if(response.data[0].state == "2"){
                    this.props.changeState(this.state.data.idLawsuit);
                }
                // console.log("### exito");
                this.props.handleCancelEditLawsuitModal();
            }
            // this.setState({
            //     ...this.state,
            //     data:{
            //         ...this.state.data,
            //         name:"",
            //         email:"",
            //         phone:"",
            //     }
            // });
        }).catch(error=>{
            // console.log(error);
        });
    }
    componentDidMount(){
        // console.log("this is flagModal"+this.props.flagModal);
    }
    componentWillUnmount(){
        // console.log("did unmount");
    }
    render(){
        // console.log(this.state.item);
            if(!this.state.flagScroll){

            }else{

            }
            return(
                <div onClick={this.props.handleCancelEditLawsuitModal} className="profileLawsuitModalWrapper">
                <div onClick={(e) => e.stopPropagation()} className="profileLawsuitModalContainer">
                    <div className="profileLawsuitModalContainerDescription">
                    <div className="profileLawsuitModal__header">
                    {this.props.messageHeader}
                    </div>
                    <div className="profileLawsuitModal__body">
                        <div className="profileLawsuitModal__item">
                            <div className="profileLawsuitModal__itemHead">Acusación</div>
                            <div  className="profileLawsuitModal__itemBody"> {this.state.item.title}</div>
                        </div>
                        <div className="profileLawsuitModal__item">
                            <div className="profileLawsuitModal__itemHead">Descripción</div>
                            <div  className="profileLawsuitModal__itemBody profileLawsuitModal__itemBody--description"> {this.state.item.description}</div>
                        </div>
                        <div className="profileLawsuitModal__item">
                            <div className="profileLawsuitModal__itemHead">Link de descarga</div>
                            <div  className="profileLawsuitModal__itemBody"> {this.state.item.link}</div>
                        </div>
                    </div>
                    <div className="profileLawsuitModal_footer">
                            <button onClick={this.scroll} className="profileLawsuitModal__itemButtom" >Desmentir</button>
                    </div>
                    </div>
                    <div className="profileLawsuitModalContainerDisclaimer">
                    <div className="profileLawsuitModal__header">
                    Descargo
                    </div>
                    <div className="profileLawsuitModal__body">
                        <div className="profileLawsuitModal__item">
                            <div className="profileLawsuitModal__itemHead">Descargo</div>
                            <textarea onChange={this.handleDisclaimerDescription}  className="profileLawsuitModal__itemBody profileLawsuitModal__itemBody--description" type="text" value={this.state.disclaimerDescription}/> 
                        </div>
                        <div className="profileLawsuitModal__item">
                            <div className="profileLawsuitModal__itemHead">Link de descarga</div>
                            <input onChange={this.handleDisclaimerLink} type="text"  className="profileLawsuitModal__itemBody" value={this.state.disclaimerLink}/> 
                        </div>
                    </div>
                    <div className="profileLawsuitModal_footer profileLawsuitModal_footer--two ">
                            <button onClick={this.scrollback} className="profileLawsuitModal__itemButtom" >Detalles de la denuncia</button>
                            {(this.state.item.state == "1" || this.state.item.state == "2") && <button onClick={this.sendDisclaimer} className="profileLawsuitModal__itemButtom" >Enviar</button>}
                            {(this.state.item.state == "3" || this.state.item.state == "4") && <button className="profileLawsuitModal__itemButtom profileLawsuitModal__itemButtom--off" >Enviar</button>}
                    </div>
                    </div>                   
                    {this.state.loadingFlag &&
                    <div className="profileLawsuitModal__loading">
                    </div>
                    }
                </div>
                </div>
            );

    }
}

export default ProfileEditLawsuitModal;