import "./styles/lawsuitsList.css";
import axios from "axios";
import React, { Component } from "react";
import ProfileLawsuitModal from "../modals/ProfileLawsuitModal";


class LawsuitsList extends React.Component{

    constructor(props){
        super(props);
        this.state={
            flagLawsuit:false,
            flagModalLawsuit:false,
            index:"",
            idDisclaimer:"",
            description:"",
            link:"",
            date:"",
            data:[]
        };
    }

    getList = async ()=>{
        const res = await axios.get('https://politicosperu.pe/php/listLawsuit.php');
            // console.log(res.data);
            var miarray=res.data;
            miarray=miarray[0];
            if(miarray.status =="success"){
                this.setState({
                    flagLawsuit:true,
                    data:res.data
                });
            }else{
                this.setState({
                    flagLawsuit:true,
                    data:res.data
                });
            }

        } 

    handleShowLawsuit=(e)=>{
        this.getDisclaimer(e.currentTarget.getAttribute("data--index"),e.currentTarget.getAttribute("data--idlawsuit"));
    }

    getDisclaimer=async(index,idLawsuit)=>{
        //console.log("index: "+index+ " idLawsuit"+ idLawsuit);
        const res = await axios.get('https://politicosperu.pe/php/getDisclaimer.php?idLawsuit='+idLawsuit);
    
        var miarray=res.data;
        miarray=miarray[0];
        //console.log("disclaimer"+ JSON.stringify(miarray.status));
        if(miarray.status ==  "success"){
            this.setState(
                {
                    ...this.state,
                    flagLawsuit:true,
                    flagModalLawsuit:true,
                    index:index,
                    idDisclaimer:miarray.idDisclaimer,
                    description:miarray.description,
                    link:miarray.link,
                    date:miarray.date                                                             
                }
            );
        }else{
            this.setState(
                {   ...this.state,
                    flagLawsuit:true,
                    flagModalLawsuit:true,
                    index:index,
                }
            );
        }
    }

    handleCancelShowLawsuitModal=(e)=>{

        this.setState(
            {
                flagModalLawsuit:false,
                index:"",
                idDisclaimer:"",
                description:"",
                link:"",
                date:"",
            }
        );
    }

    componentDidMount(){
            // console.log('component did mount lawsuit list');
            this.getList();
        }

    componentDidUpdate(){
        // console.log('component did update');
        
    }


    render(){
        if(!this.state.flagLawsuit){
            return(
                <div className="LawsuitListContainer">
                <div className="LawsuitListContainer__title">Últimas Denuncias</div>
                <div className="LawsuitListContainer__subTitle">
                    <span className="LawsuitListContainer__subTitlePolitician">Político</span>
                    <span className="LawsuitListContainer__subTitleLawSuit">Acusación</span>
                    <span className="LawsuitListContainer__subTitleState">Estado</span>
                </div>
                <div className="LawsuitListContainer__list">
                </div>
                </div>
            );
        }else{


        
        return(
            <div className="LawsuitListContainer">
                <div className="LawsuitListContainer__title">Últimas Denuncias</div>
                <div className="LawsuitListContainer__subTitle">
                    <span className="LawsuitListContainer__subTitlePolitician">Político</span>
                    <span className="LawsuitListContainer__subTitleLawSuit">Acusación</span>
                    <span className="LawsuitListContainer__subTitleState">Estado</span>
                </div>
                <div className="LawsuitListContainer__list">
                {
                        this.state.data.map((element,index)=>{
                            var actualState="";
                            var color="";
                            var flagclick=false;
                            if(element.state==1){
                               actualState="En Desarrollo";
                               color="LawsuitListContainer__stateInProcess";
                               flagclick=true;
                            }
                            if(element.state==2){
                               actualState="Con Réplica";
                               color="LawsuitListContainer__stateInDebate";
                               flagclick=true;
                            }
                            if(element.state==3){
                               actualState="Falso";
                               color="LawsuitListContainer__stateFalse";
                               flagclick=true;
                            }
                            if(element.state==4){
                               actualState="Confirmado";
                               color="LawsuitListContainer__stateTrue";
                               flagclick=true;
                            }
                            return(
                               <div className="LawsuitListContainer__item" onClick={this.handleShowLawsuit} data--index={index} data--idlawsuit={element.idLawsuit}>
                               <span className="LawsuitListContainer__politician">{element.name}</span>
                               <span className="LawsuitListContainer__lawsuit">{element.title}</span>
                               <span className={color}>{actualState}</span>
                           </div>
                                )
                        })
                         }

                </div>
                {this.state.flagModalLawsuit && <ProfileLawsuitModal
                    messageHeader='Detalles de la Denuncia'
                    handleCancelEditLawsuitModal= {this.handleCancelShowLawsuitModal}  
                    data={this.state.data}
                    index={this.state.index}
                    idDisclaimer={this.state.idDisclaimer}
                    description={this.state.description}
                    link={this.state.link}
                    date={this.state.date}
                    changeState={this.changeState}
                />}
            </div>
        );
    }
    }
}

export default LawsuitsList;