import './styles/footer.css';

function Footer() {
  return (
    <div className="Footer">
      <div className="Footer__options">
        <a href="https://web.facebook.com/PoliticosPeruOficial" target="__blank"  className="Footer__item">Facebook</a>
        <a href="https://www.instagram.com/politicosperu/"  target="__blank" className="Footer__item">Instagram</a>
        <a href="https://twitter.com/PoliticosPeruEs"  target="__blank" className="Footer__item">Twitter</a>
        <a href="https://www.tiktok.com/@politicosperues"  target="__blank" className="Footer__item">TikTok</a>
      </div>
    <div className="Footer__copyRight">
      2022 PolíticosPerú.pe
    </div>

    </div>
  );
}

export default Footer;
