import "./styles/profileLawsuitModal.css";
import React from "react";
import axios from "axios";


class ProfileLawsuitModal extends React.Component{
    constructor(props){
        super(props);

        if(this.props.idDisclaimer==""){
            // console.log("CONSTRUCTOR INDEX"+this.props.index);
            // console.log("construtor"+this.props.data);
            this.state={
                lawsuit:this.props.data[this.props.index],
                data:{
                    idDisclaimer:"",
                    description:"",
                    link:"",
                    date:""
                }
            }
        }else{
            this.state={
                lawsuit:this.props.data[this.props.index],
                data:{
                    idDisclaimer:this.props.idDisclaimer,
                    description:this.props.description,
                    link:this.props.link,
                    date:this.props.date,
                }
            }
        }

    }

    scroll=(e)=>{
        var element=document.getElementsByClassName("profileLawsuitContainerDescription");
        var element2=document.getElementsByClassName("profileLawsuitContainerDisclaimer");
        element.item(0).style.transform="translate(-100%)";
        element2.item(0).style.transform="translate(-100%)";

    }
    scrollback=(e)=>{
        var element=document.getElementsByClassName("profileLawsuitContainerDescription");
        var element2=document.getElementsByClassName("profileLawsuitContainerDisclaimer");
        element.item(0).style.transform="translate(0%)";
        element2.item(0).style.transform="translate(0%)";
    }

    handleDisclaimerDescription=(e)=>{
        this.setState({
            disclaimerDescription:e.target.value,
            data:{
                ...this.state.data,
                description:e.target.value
            }
        });
    }
    handleDisclaimerLink=(e)=>{
        this.setState({
            disclaimerLink:e.target.value,
            data:{
                ...this.state.data,
                link:e.target.value
            }
        });
    }

    componentDidMount(){
        // // console.log("this is flagModal"+this.props.flagModal);
    }
    componentWillUnmount(){
        // // console.log("did unmount");
    }
    render(){
        // // console.log("EL LAWSUIT"+this.state.lawsuit);
            return(
                <div onClick={this.props.handleCancelEditLawsuitModal} className="profileLawsuitModalWrapper">
                <div onClick={(e) => e.stopPropagation()} className="profileLawsuitContainer">
                    <div className="profileLawsuitContainerDescription">
                    <div className="profileLawsuit__header">
                    {this.props.messageHeader}
                    </div>
                    <div className="profileLawsuit__body">
                        <div className="profileLawsuit__item">
                            <div className="profileLawsuit__itemHead">Acusación</div>
                            <div  className="profileLawsuit__itemBody"> {this.state.lawsuit.title}</div>
                        </div>
                        <div className="profileLawsuit__item">
                            <div className="profileLawsuit__itemHead">Descripción</div>
                            <div  className="profileLawsuit__itemBody profileLawsuit__itemBody--description"> {this.state.lawsuit.description}</div>
                        </div>
                        <div className="profileLawsuit__item">
                            <div className="profileLawsuit__itemHead">Link de descarga</div>
                            <div  className="profileLawsuit__itemBody"> {this.state.lawsuit.link}</div>
                        </div>
                    </div>
                    <div className="profileLawsuit_footer">
                            <button onClick={this.scroll} className="profileLawsuit__itemButtom" > Ver Replica</button>
                    </div>
                    </div>
                    <div className="profileLawsuitContainerDisclaimer">
                    <div className="profileLawsuit__header">
                    Replica
                    </div>
                    <div className="profileLawsuit__body">
                        <div className="profileLawsuit__item">
                            <div className="profileLawsuit__itemHead">Resumen</div>
                            <div className="profileLawsuit__itemBody profileLawsuit__itemBody--description">{this.state.data.description}</div> 
                        </div>
                        <div className="profileLawsuit__item">
                            <div className="profileLawsuit__itemHead">Link de descarga</div>
                            <div className="profileLawsuit__itemBody">{this.state.data.link}</div> 
                        </div>
                    </div>
                    <div className="profileLawsuit_footer profileLawsuit_footer--two ">
                            <button onClick={this.scrollback} className="profileLawsuit__itemButtom2" >Detalles de la denuncia</button>
                    </div>
                    </div>                   
                    {this.state.loadingFlag &&
                    <div className="profileLawsuit__loading">
                    </div>
                    }
                </div>
                </div>
            );

    }
}

export default ProfileLawsuitModal;