import React from 'react';
import { Link, useNavigate} from 'react-router-dom';
import './styles/login.css';



class Login extends React.Component{

    constructor(props){
        super(props);
        this.state={
            flagLogin:false,
        }
    }


    componentDidMount(){

    }
    render(){

        return(
            <div  className="login">
                <input onChange={this.props.updateInputs} name="username" type="text" value={this.props.userDataLogin.username} className="login__input" placeholder="DNI"/>
                <input onChange={this.props.updateInputs} name="password" type="password" value={this.props.userDataLogin.password}  id="" className="login__input" placeholder="Contraseña"/>
                <div className="login__wrapper">
                    {this.props.flagLogin && <div className="login__error">datos invalidos</div>}
                    {this.props.token && <div className="login__error">Sesión iniciada</div>}
                    <button  onClick={this.props.checkLogin} className="login__button">Iniciar Sesión</button>
                    <div className="login__options">
                        <Link className="login__option" to="/signup">Registrarse</Link>
                        <span className='login__optionMiddle'>|</span>
                        <Link className="login__option" to="/recover">Recuperar</Link>
                    </div>
                </div>
            </div>
        );
    }

}


export default function(props) {
    const navigation = useNavigate();
  
    return <Login {...props} navigation={navigation} />;
  }