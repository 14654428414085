import React from "react";
import axios from "axios";
import "./styles/fileUploader.css";

class FileUploader extends React.Component {
  constructor() {
    super();
    this.state = {
      loading:false,
      selectedFile: "",
      responseArray: [],
      elementOne:'Archivo no seleccionado'
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  readCookie=(name)=> {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

  handleInputChange(event) {
    // console.log(event.target.files);
    var element= event.target.files[0];
    // console.log(element.name);
    this.setState({
      selectedFile: event.target.files,
      responseArray:[],
      elementOne:element.name
    });
  }

  postImageUrl=async(url)=>{
    var idPolitician=this.readCookie("idPolitician");

    // console.log("Este es el id Profile"+idPolitician+" esta es la url "+url);
    axios.post('https://politicosperu.pe/php/updateImageProfileUrl.php',JSON.stringify({'url':url, 'idPolitician':idPolitician}))
    // console.log("postImageUrl");
    // this.setState({
    //     districts:res.data
    // });
}

postBannerUrl=async(url)=>{
  var idPolitician=this.readCookie("idPolitician");

  // console.log("Post Banner URL Este es el idPolitician en Banner:"+idPolitician+" esta es la url "+url);
  axios.post('https://politicosperu.pe/php/updateImageBannerUrl.php',JSON.stringify({'url':url, 'idPolitician':idPolitician}))
  // this.setState({
  //     districts:res.data
  // });
}

  onSubmit() {
    if (!this.state.selectedFile) {
      alert("Please select a file!");
      return false;
    }
    this.props.updateLoadingFlag();
    
    const data = new FormData();
    const headers = { 
        'idPolitician': this.props.idPolitician
    };

    for (let i = 0; i < this.state.selectedFile.length; i++) {
      data.append("file[]", this.state.selectedFile[i]);
    }

    let url = "";

    if(this.props.flagProfile){
        url = "https://politicosperu.pe/uploadImageProfile.php?code="+this.props.idPolitician;
    }else if(this.props.flagBanner){
        url = "https://politicosperu.pe/uploadBanner.php?code="+this.props.idPolitician;

    }else if(this.props.flagPlan){
        url = "https://politicosperu.pe/uploadPlan.php?code="+this.props.idPolitician;
    }
    else if(this.props.flagCv){
        url = "https://politicosperu.pe/uploadCv.php?code="+this.props.idPolitician;
    }
    else if(this.props.flagTeam){
        url = "https://politicosperu.pe/uploadTeam.php?code="+this.props.idPolitician;
    }
    // console.log("here id"+this.props.idPolitician);
    // console.log("here the consulted php"+url);
    axios
      .post(url, data, {'IDPOLITICIAN':'2'
      })
      .then((res) => {

        var miarray='';
        miarray=JSON.stringify(res.data);
        // console.log("respuesta servidor"+miarray);
        // console.log("url"+url);
        // then print response status
        this.setState({ responseArray: res.data });
        // this.resetFile();
      },error=>{
        alert(error);
      });
  }

  resetFile() {
    // Reset file input control
    document.getElementsByName("file")[0].value = null;
  }

  render() {
    return (
        <div className="fileUploaderContainer">
        <div className="fileUploader__body">
          <label className="fileUploader__label" htmlFor="selectFile"> Seleccionar</label>
          <label className="fileUploader__labelFileDescription" htmlFor="">{this.state.elementOne}</label>
              <input type="file" id="selectFile" className="fileUploader__btnSearch" multiple name="file" onChange={this.handleInputChange}/>
        </div>
        <div className="fileUploader__footer">
              <button type="submit" className="fileUploader__btnUpload" onClick={() => this.onSubmit()}>
                Cargar Archivo
              </button>
        </div>
        {this.state.responseArray.map((res, i) => {

          
            if(res.status =="success" && this.props.imageModal != undefined){
              if(this.props.imageModal == "true"){
                this.postImageUrl(res.url);
                this.props.updateImgProfile(res.url);
                this.props.handleCancelImageModal();
              }else{
                this.postBannerUrl(res.url);
                this.props.updateImgBanner(res.url);
                this.props.handleCancelImageModal();
              }
            }
            else if(res.status =="success" ){
                this.props.handleCancelFileModal();
            }
            return(
              <div></div>
              );
          // return(            
          // <div key={i}>
          //   <div  className={'img-alert alert alert-'+res.status}>
          //     <div>{res.message} : {res.url}</div>
          //   </div>
          // </div>
          //   );
        })}

        </div>

    );
  }
}

export default FileUploader;