import heroImg1 from './images/main.jpg';
import { useNavigate } from 'react-router-dom';
import './styles/hero.css';
import Login from './Login';
import Button from './Button';
import React from 'react';


class Hero extends React.Component{
    constructor(props){
        super(props);
    }

    goDemand=()=>{
        const { navigation } = this.props;
        navigation('/lawsuits');
    }
    render(){
       
        return(
            <div className="Hero">
                <div className="Hero__imageContainer">
                    <img className="Hero__image" src={heroImg1} alt="" />
                    <div className="Hero__gradient"></div>
                </div>
                <div className="Hero__message">
                    Mejorando la Política Para la Gente, <span className='Hero__messageRelevant'>Únete</span>
                </div>
                <div className="Hero__login">
                <Login 
                checkLogin={this.props.checkLogin} 
                flagLogin={this.props.flagLogin}
                updateInputs={this.props.updateInputs}
                userDataLogin={this.props.userDataLogin}
                />
                </div>
                <div className="Hero__demandBtn">
                    <Button text="Denunciar" checkForm={this.goDemand}/>
                </div>
            </div>
        );
    }

}

export default function (props){
    const navigation = useNavigate();
  
    return <Hero {...props} navigation={navigation} />;
}
