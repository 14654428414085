import "./styles/heroProfile.css";
import heroImage from "./images/candidato.jpg";
import React from "react";


class HeroProfile extends React.Component{
    constructor(props){
        super(props);
    }


    render(){
        return(
            <div className="heroProfileContainer">
                <div className="heroProfile__imageContainer">
                    <img className="heroProfile__image" src={this.props.imgBanner} alt="" />
                </div>
                <div className="heroProfile__buttonsContainer">
                        <a href={this.props.linkPlan} target="_blank" className="heroProfile__Button"> Plan de Gobierno</a>
                        <a href={this.props.linkTeam} target="_blank" className="heroProfile__Button"> Equipo Técnico</a>
                </div>
            </div>
        );

    }

}
export default HeroProfile;