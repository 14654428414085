import React from "react";
import './styles/options.css';
import rowImg from "./images/row.png";
import { Link } from "react-router-dom";

class Options extends React.Component{
    constructor(props){
        super(props);
        this.box = React.createRef();
        this.state={
            flagOptions:false,
            flagClick:false,
        }
    }

 
      handleOutsideClick = (event) => {

            if (this.box && !this.box.current.contains(event.target)) {
                //console.log('outside n debería salir'+this.props.flagMenuPhone);
                //console.log(event.target);
                //console.log(this.box);
                
                this.setState({
                    flagOptions:false,
                });
            }


        }


      
    handleClick=()=>{
        //console.log('click para ver opciones');
        if(this.state.flagOptions){
            // console.log('false');
            this.setState({
                flagOptions:false,
                // flagClick:true
            });
        }else{
            // console.log('true');
            this.setState({
                flagOptions:true,
                // flagClick:true
            });
        }

    }


    
    componentDidMount() {
 
        // Adding a click event listener
        document.addEventListener('click', this.handleOutsideClick);
      }
     
    componentWillUnmount(){
        document.removeEventListener('click', this.handleOutsideClick);

    }

    
    render(){
        return(
            <div className="optionsContainer">
                {this.props.flagMenuPhone 
                ? <div className="optionsWrapper">
                            <div ref={this.box} >
                            <div onClick={this.handleClick} className="options__button">
                                <img src={rowImg} width="13px" height="13px" alt="" />
                            </div>
                            {this.state.flagOptions &&  <div  className="options__listOptions">
                            <Link onClick={this.props.closeMovile} className="options__item" to={"config/"+this.props.domain} > Configuración </Link>
                            <div onClick={(e)=>{ this.props.closeMovile(); this.props.closeSesion();}} className="options__item options__footer">Cerrar sesión</div>
                            </div>}
                            </div>
                    </div>
                :
                <div className="optionsWrapper">
                <div onClick={this.handleClick} className="options__button">
                <img src={rowImg} width="13px" height="13px" alt="" />
                </div>

                <div ref={this.box} >
                {this.state.flagOptions &&  <div  className="options__listOptions">
                <Link onClick={this.props.closeMovile} className="options__item" to={"config/"+this.props.domain} > Configuración </Link>
                <div onClick={(e)=>{ this.props.closeMovile(); this.props.closeSesion();}} className="options__item options__footer">Cerrar sesión</div>
                </div>}
                </div>
                    </div>
                }




            </div>
        );
    }

}

export default Options;