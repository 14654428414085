import "./styles/propspects.css";
import React from "react";
import axios from "axios";

class Prospects extends React.Component{
    constructor(props){
        super(props);
        this.state={
            idPolitician:this.props.idPolitician,
            suscribers:[],
            members:[],
            flagMember:true,
            flagSuscriber:false,
            flagExistMember:false,
            flagExistSuscriber:false,
            indexDelete:'',
            indexType:"",
            actualId:""
        }
    }

    getData=async()=>{
        const res = await axios.get('https://politicosperu.pe/php/listSuscribers.php?politician='+this.state.idPolitician);
        const res2 = await axios.get('https://politicosperu.pe/php/listMembers.php?politician='+this.state.idPolitician);
        var arrayResponse= new Array(res.data[0]);
        var arrayResponse2= new Array(res2.data[0]);
        //console.log("#####sucribers"+arrayResponse[0].error);
        if(arrayResponse[0].error=="true" && arrayResponse2[0].error=="true"){
            this.setState({
                suscribers:[],
                flagExistSuscriber:false,
                members:[],
                flagExistMember:false,
            });
        }else if(arrayResponse[0].error=="true" && arrayResponse2[0].error=="false"){
            this.setState({
                suscribers:[],
                flagExistSuscriber:false,
                members:res2.data,
                flagExistMember:true,
                
            });
        }
        else if(arrayResponse[0].error=="false" && arrayResponse2[0].error=="true"){
            this.setState({
                suscribers:res.data,
                flagExistSuscriber:true,
                members:[],
                flagExistMember:false
            });
        }else{
            this.setState({
                suscribers:res.data,
                flagExistSuscriber:true,
                members:res2.data,
                flagExistMember:true
            })
        }
        

    }

    handleClick=(e)=>{
        var showMember=document.getElementById("members");
        var showSuscriber=document.getElementById("suscribers");
        var btnMember=document.getElementById("btnMember");
        var btnSuscriber=document.getElementById("btnSuscriber");


        if(e.target.id == btnMember.id){
            showMember.style.display="block";
            showSuscriber.style.display="none";
            btnSuscriber.classList.remove("prospects__button--active");
            btnMember.classList.add("prospects__button--active");
            this.setState(
                {
                    flagMember:true
                }
            );
        }else{
            showMember.style.display="none";
            showSuscriber.style.display="block";
            btnSuscriber.classList.add("prospects__button--active");
            btnMember.classList.remove("prospects__button--active");
            this.setState(
                {
                    flagMember:false
                }
            );
        }
        

    }
    handleShowDelete=(e)=>{
     
        if(e.target.lastElementChild.lastElementChild.style.display =="flex"){
            e.target.lastElementChild.lastElementChild.style="display:none";
            this.setState({
                indexDelete: '',
                indexType:'',
                actualId:''
            });
        }else{
            var elements= document.getElementsByClassName("propspects__listItemButton");
            var elementsArray=[];
            for(var i=0;i<elements.length;i++){
                elementsArray.push(elements.item(i));
            }
            
            elementsArray.map((element)=>{
                element.style.display="none";
            });
    
                    e.target.lastElementChild.lastElementChild.style="display:flex";
                    this.setState({
                        indexDelete: e.target.lastElementChild.lastElementChild.getAttribute("data--index"),
                        indexType: e.target.lastElementChild.lastElementChild.getAttribute("data--type"),
                        actualId: e.target.lastElementChild.lastElementChild.getAttribute("data--id")
                    });
                
            
    
        }

    
    }
    deleteElement=(e)=>{
        if(this.state.indexType =="0"){
            // var auxArr= this.state.members;
            // console.log("members"+this.state.members);
            // auxArr.splice(this.state.indexDelete,1);
            // console.log("members"+this.state.members);
            // console.log("delete member");
            // this.setState({
            //     members:[...auxArr]
            // });
            this.deleteMember();
        }else{
            this.deleteSuscriber();
        }
        
    }
    deleteMember=async()=>{
        // console.log("###antes de validar"+this.state.cardValidation.tokenDescription);
        axios.post('https://politicosperu.pe/php/deleteMember.php',JSON.stringify({idPolitician:this.props.idPolitician, token:this.props.token, idMember:this.state.actualId}))
        .then(response =>{
            //console.log(response.data);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                var auxArr= this.state.members;
                auxArr.splice(this.state.indexDelete,1);

                    //console.log("#### borrado exitoso");
                    this.setState({
                       actualId:'',
                       members:[...auxArr],
                       indexType:'',
                       actualId:''
                    });
                    // this.getPoliticianDetails(this.getUrlPolitician());
            }else{
                //console.log("#### inValida");
                this.setState({
                    flagActivities:true,
                });
            }
    
        }).catch(error=>{
            //console.log("#### error");

            this.setState({
                flagActivities:true,
            });
        });

    }
    deleteSuscriber=async()=>{
        // console.log("###antes de validar"+this.state.cardValidation.tokenDescription);
        axios.post('https://politicosperu.pe/php/deleteSuscriber.php',JSON.stringify({idPolitician:this.props.idPolitician, token:this.props.token, idSuscriber:this.state.actualId}))
        .then(response =>{
            //console.log(response.data);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                var auxArr= this.state.suscribers;
                auxArr.splice(this.state.indexDelete,1);

                    //console.log("#### borrado exitoso");
                    this.setState({
                       actualId:'',
                       suscribers:[...auxArr],
                       indexType:'',
                       actualId:''
                    });
                    // this.getPoliticianDetails(this.getUrlPolitician());
            }else{
                //console.log("#### inValida");
                this.setState({
                    flagActivities:true,
                });
            }
    
        }).catch(error=>{
            //console.log("#### error");

            this.setState({
                flagActivities:true,
            });
        });

    }

    componentDidMount(){
        this.getData();
    }

    render(){
        return(
            <div className="prospectsContainer">
                <div className="prospectsWrapper">

                <div className="prospects__buttonContainer">
                    <div onClick={this.handleClick} id="btnMember" className="prospects__button prospects__button--active">Nuevos Miembros</div>
                    <div onClick={this.handleClick} id="btnSuscriber" className="prospects__button">Nuevos Suscriptores</div>
                </div>
                <div className="prospects__listContainer">
                    <div className="prospects__list" id="members">
                        
                        {this.state.flagExistMember &&
                         this.state.members.map((element,index)=>{
                             return(
                                <div onClick={this.handleShowDelete} className="prospects__listItem">
                                    <div className="prospects__listItemHeader">Nombre</div>
                                    <div className="prospects__listItemHeader">Email</div>
                                    <div className="prospects__listItemHeader">Contacto</div>
                                    <div className="prospects__listItemDescription">{element.name}</div>
                                    <div className="prospects__listItemDescription">{element.email}</div>
                                    <div className="prospects__listItemDescription">{element.phone}</div>
                                    <div onClick={(e)=>e.stopPropagation()}>
                                    <div onClick={this.deleteElement} data--index={index} data--type="0" data--id={element.idMember} name="btndelete" className="propspects__listItemButton"> X </div>    
                                    </div>
                                 </div>
                                 )
                         })}
                        {this.state.members.length ==0 && <div className="prospects__messageError"> No Existen Registros</div>}
                    
                    </div>
                    <div className="prospects__list" id="suscribers">
                    {this.state.flagExistSuscriber &&
                         this.state.suscribers.map((element,index)=>{
                             return(
                                <div onClick={this.handleShowDelete} className="prospects__listItem">
                                    <div className="prospects__listItemHeader">Nombre</div>
                                    <div className="prospects__listItemHeader">Email</div>
                                    <div className="prospects__listItemHeader">Contacto</div>
                                    <div className="prospects__listItemDescription">{element.name}</div>
                                    <div className="prospects__listItemDescription">{element.email}</div>
                                    <div className="prospects__listItemDescription">{element.phone}</div>
                                    <div onClick={(e)=>e.stopPropagation()}>
                                    <div  onClick={this.deleteElement} data--index={index} data--type="1" data--id={element.idSuscriber} name="btndelete" className="propspects__listItemButton"> X </div>
                                    </div>
                                </div>
                                 
                                 )
                         })}
                        {this.state.suscribers.length ==0 && <div className="prospects__messageError"> No Existen Registros</div>}
                    </div>
                </div>
                </div>
            </div>
        );
    }




}

export default Prospects;