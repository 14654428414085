import React from "react";
import axios from "axios";
import "./styles/myStadistics.css";
import imgSupport1 from "./images/support1.png";
import imgSupport2 from "./images/support2.png";

class MyStadistics extends React.Component{
    constructor(props){
        super(props);
        this.state={
            totalCitizens:0,
            totalPoliticians:0,
            totalMembers:0,
            totalSuscribers:0,
            totalVisits:0,
            monthMembers:0,
            monthSuscribers:0,
            monthVisits:0,
            weekMembers:0,
            weekSuscribers:0,
            weekVisits:0
        }
    }

    getStadistics=async()=>{
        // console.log("###antes de validar"+this.state.cardValidation.tokenDescription);
        axios.post('https://politicosperu.pe/php/getPoliticianStadistics.php',JSON.stringify({idPolitician:this.props.idPolitician}))
        .then(response =>{
            //console.log(response.data);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    //console.log("#### valido, traemos las estadisticas");
                    this.setState({
                            totalCitizens:response.data[0].totalCitizen,
                            totalPoliticians:response.data[1].totalPolitician,
                            totalMembers:response.data[2].totalMembers,
                            totalSuscribers:response.data[3].totalSuscribers,
                            totalVisits:response.data[4].totalVisits,
                            monthMembers:response.data[5].monthMembers,
                            monthSuscribers:response.data[6].monthSuscribers,
                            monthVisits:response.data[7].monthVisits,
                            weekMembers:response.data[8].weekMembers,
                            weekSuscribers:response.data[9].weekSuscribers,
                            weekVisits:response.data[10].weekVisits
                        });
                    // this.getPoliticianDetails(this.getUrlPolitician());
            }else{
                //console.log("#### inValida, no hay estadisticas");
                this.setState({
                });
            }
    
        }).catch(error=>{
            //console.log("#### error, no hay estadisticas");

            this.setState({
            });
        });

    }

    componentDidMount(){
        //console.log("###### Este es el ID del politico "+this.props.idPolitician);
        this.getStadistics();
    }

    render(){
        return(
            <div className="myStadisticsContainer">
                <div className="myStadisticsWrapper">
                    <h2 className="myStadistics__title"> Mis estadisticas</h2>
                    <div className="myStadistics__content">
                        <div className="myStadistics__stadistics">
                            <div className="myStadistics__item">
                                <span className="myStadistics__data">{this.state.totalVisits}</span>
                                <span className="myStadistics__descriptionData">Visitas Totales</span>
                            </div>
                            <div className="myStadistics__item">
                                <span className="myStadistics__data">{this.state.totalSuscribers}</span>
                                <span className="myStadistics__descriptionData">Suscripciones Totales</span>
                            </div>
                            <div className="myStadistics__item">
                                <span className="myStadistics__data">{this.state.totalMembers}</span>
                                <span className="myStadistics__descriptionData">Miembros Totales</span>
                            </div>
                            <div className="myStadistics__item">
                                <span className="myStadistics__data">{this.state.monthVisits}</span>
                                <span className="myStadistics__descriptionData">Visitas Ultimo Mes</span>
                            </div>
                            <div className="myStadistics__item">
                                <span className="myStadistics__data">{this.state.monthSuscribers}</span>
                                <span className="myStadistics__descriptionData">Suscriptores Ultimo Mes</span>
                            </div>
                            <div className="myStadistics__item">
                                <span className="myStadistics__data">{this.state.monthMembers}</span>
                                <span className="myStadistics__descriptionData">Miembros Ultimo Mes</span>
                            </div>
                            <div className="myStadistics__item">
                                <span className="myStadistics__data">{this.state.weekVisits}</span>
                                <span className="myStadistics__descriptionData">Visitas Ultima Semana</span>
                            </div>
                            <div className="myStadistics__item">
                                <span className="myStadistics__data">{this.state.weekSuscribers}</span>
                                <span className="myStadistics__descriptionData">Suscriptores Ultima Semana</span>
                            </div>
                            <div className="myStadistics__item">
                                <span className="myStadistics__data">{this.state.weekMembers}</span>
                                <span className="myStadistics__descriptionData">Miembros Ultima Semana</span>
                            </div>
                        </div>
                        <div className="myStadistics__supporters">
                        <div className="supportStadistics__item">
                            <div id="politicianIdButton" className="supportStadistics__itemTop">
                                <img className="supportStadistics__icon" src={imgSupport1} alt="" />
                                { <span className="supportStadistics__data">{this.state.totalPoliticians}</span>}
                            </div>
                            <div className="supportStadistics__itemBottom">
                                <span className="supportStadistics__Description"> Respaldo Político</span>
                            </div>
                        </div>
                        <div id="citizenIdButton" className="supportStadistics__item">
                            <div  className="supportStadistics__itemTop">
                                <img className="supportStadistics__icon" src={imgSupport2} alt="" />
                                <span className="supportStadistics__data">{this.state.totalCitizens}</span>
                            </div>
                            <div className="supportStadistics__itemBottom">
                                <span className="supportStadistics__Description"> Respaldo Ciudadano</span>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MyStadistics;