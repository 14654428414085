import "./styles/lawsuitsForm.css";
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import Button from "./Button";
import axios from "axios";

const recaptchaRef = React.createRef();



class LawsuitsForm extends React.Component{

    status={
        recaptcha:false,        
    };
    
    // onChange(value) {
    //     if(value){
    // //         console.log("Captcha value:", value);
    //     }else{
    
    //     }
    //   }



      checkForm=e=>{
        const recaptchaValue = recaptchaRef.current.getValue();
          if(!recaptchaValue){
            alert("Por favor, Valida que no eres un robot");
          }else{
            if(!this.props.dataForm.validParam){
                alert("por favor ingresa datos validos");
              }else{
                  if(!(this.props.dataForm.stepOne && this.props.dataForm.stepTwo && this.props.dataForm.stepThree)){
                      alert("por favor rellena los campos faltantes");
                  }else{
                    //   console.log(this.props.dataForm);
                    //   console.log("registrar denuncia");
                      axios.post('https://politicosperu.pe/php/registerLawsuit.php',JSON.stringify(this.props.dataForm))
                      .then(response =>{
                        //   console.log("eseta es la respuesta positiva"+response);
                          alert("Denuncia registrada exitosamente");
                          this.props.handleResetForm();
                      }).catch(error=>{
                        //   console.log(error);
                        //   console.log("un pinche error");
                          this.props.handleResetForm();                    
                      });
                  }
              }
        }
     
        }
       
    handleStepOne=e=>{
        var actualStep= document.getElementsByClassName("LawsuitsForm__sectionWrapper");
        var actualtap= document.getElementsByClassName("LawsuitsForm__button");
        actualStep.item(0).style.display="none";
        actualStep.item(1).style.display="none";
        actualtap.item(1).style.backgroundColor="white";
        actualtap.item(1).style.color="black";
        actualStep.item(2).style.display="block";
        actualtap.item(2).style.backgroundColor="white";
        actualtap.item(2).style.color="black";

        e.target.style.backgroundColor="#EA2027";
        e.target.style.color="white";
    }
    checkStepOne(){

    }
    handleStepTwo=e=>{
        var actualStep= document.getElementsByClassName("LawsuitsForm__sectionWrapper");
        var actualtap= document.getElementsByClassName("LawsuitsForm__button");
        actualStep.item(0).style.display="block";
        actualtap.item(0).style.backgroundColor="white";
        actualtap.item(0).style.color="black";
        actualStep.item(1).style.display="none";
        actualtap.item(2).style.backgroundColor="white";
        actualStep.item(2).style.display="none";
        actualtap.item(2).style.color="black";

        e.target.style.backgroundColor="#EA2027";
        e.target.style.color="white";
        e.target.style.display="flex";
    }
    handleStepThree=e=>{
        var actualStep= document.getElementsByClassName("LawsuitsForm__sectionWrapper");
        var actualtap= document.getElementsByClassName("LawsuitsForm__button");
        actualStep.item(0).style.display="none";
        actualtap.item(0).style.backgroundColor="white";
        actualtap.item(0).style.color="black";
        actualStep.item(1).style.display="block";
        actualtap.item(1).style.backgroundColor="white";
        actualtap.item(1).style.color="black";
        actualStep.item(2).style.display="none";
       
        e.target.style.backgroundColor="#EA2027";
        e.target.style.color="white";
        e.target.style.display="flex";
    }

    render(){

        return(
            <div className="LawsuitsFormContainer">
                <div className="LawsuitsForm__buttons"> 
                    <div onClick={this.handleStepOne} className="LawsuitsForm__button LawsuitsForm__button--active">Paso 1: Identificate</div>
                    <div onClick={this.handleStepTwo} className="LawsuitsForm__button">Paso 2: Cuéntanos</div>
                    <div onClick={this.handleStepThree} className="LawsuitsForm__button">Paso 3: Pruebas</div>
                </div>

                <div className="LawsuitsForm__sectionWrapper">
                <div className="LawsuitsForm__step LawsuitsForm__details">
                    <input onChange={this.props.validForm} value={this.props.dataForm.title} className="LawsuitsForm__input LawsuitsForm__input--title" name="title" type="text" placeholder="Título de la Denuncia" />
                    <textarea onChange={this.props.validForm} value={this.props.dataForm.description} className="LawsuitsForm__input LawsuitsForm__input--area" name="description" type="textArea" placeholder="Descripción" />
                    <input onChange={this.props.validForm} value={this.props.dataForm.namePolitician} className="LawsuitsForm__input LawsuitsForm__input--nameDetails"  name="namePolitician" type="text" placeholder="Nombre del Denunciado" />
                    <input onChange={this.props.validForm} value={this.props.dataForm.dniPolitician} className="LawsuitsForm__input LawsuitsForm__input--dniPolitician" name="dniPolitician" type="text" placeholder="DNI" />
                    <div className="LawsuitsForm__detailsMessage">y/o</div>
                </div>
                </div>
                <div className="LawsuitsForm__sectionWrapper">
                <div className="LawsuitsForm__step LawsuitsForm__proofs">
                    <input onChange={this.props.validForm} value={this.props.dataForm.link} className="LawsuitsForm__input" name="link"  type="url" placeholder="Link del contenido" />
                    <span className="LawsuitsForm__instruction">1. Crea una carpeta en algún reporsitorio (Google Drive)</span>
                    <span className="LawsuitsForm__instruction">2. Almacena las pruebas en la carpeta creada.</span>
                    <span className="LawsuitsForm__instruction">3. Genera un link para compartir tu carpeta (denuncias@politicosperu.pe)</span>
                    <span className="LawsuitsForm__instruction">4. Copia el link y pegalo en el cuatro superior</span>
                    <span className="LawsuitsForm__instruction">5. Verifica los datos ingresados y presiona el boton DENUNCIAR</span>
                </div>
                </div>
                <div className="LawsuitsForm__sectionWrapper">
                <div className="LawsuitsForm__step LawsuitsForm__identification">
                    <input onChange={this.props.validForm} value={this.props.dataForm.dni} className="LawsuitsForm__input LawsuitsForm__input--dni" name="dni" type="text" placeholder="DNI" />
                    <input onChange={this.props.validForm} data-recaptcha={this.props.flag} value={this.props.dataForm.phone} className="LawsuitsForm__input LawsuitsForm__input--phone" name="phone" type="tel" placeholder="Número Telefónico"/>
                    <input onChange={this.props.validForm} name="name" value={this.props.dataForm.name} className="LawsuitsForm__input LawsuitsForm__input--name" type="text" placeholder="Nombre Completo"/>
                    {/* <input className="LawsuitsForm__input" type="text" placeholder="Código de verificación"/> */}
                    <ReCAPTCHA className="LawsuitsForm__input--recaptcha"
                    ref={recaptchaRef}
                    sitekey="6LcLAw8lAAAAAIE1MPJTXsAG1iPmypZWShkbWvuC"
                    // onChange={this.onChange}
                    />
                    {/* <div className="LawsuitsForm__inputAnonymouseContainer">
                        <input onChange={this.props.validForm} className="LawsuitsForm__input--checkbox" value={this.props.dataForm.anonymouse} type="checkbox" name="anonymouse" id="anonymouse"/>
                        <label className="LawsuitsForm__input--label"  htmlFor="anonymous"> Denuncia Anonima</label>
                    </div> */}
                    {/* <div className="LawsuitsForm__identificationMessage">Haga click en el recuadro para realizar una denuncia anonima</div> */}

                </div>
                </div>


                <div className="LawsuitsForm__demandBtn LawsuitForm__demandBtn--form">
                    <Button 
                    text="Denunciar"
                    checkForm={this.checkForm}
                    />
                </div>
            </div>
        );

    }
    

}
export default LawsuitsForm;