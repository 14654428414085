import React from "react";
import "./css/signin.css"


class Restore extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className="RestoreContainer">
                <div className="RestoreWrapper">
                <div className="Restore__header">
                    Registrarse
                </div>
                <div className="Restore__body">
                    Lo sentimos, por el momento solo puedes acceder por invitación
                </div>
                </div>
                <div className="RestoreBackground" >
                    <div className="RestoreBackground__filter">

                    </div>
                </div>
                
            </div>
        );
    }
}

export default Restore;