import React from "react";
import './styles/stadisticsSupporters.css';
import imgSupport1 from "./images/support1.png";
import imgSupport2 from "./images/support2.png";
import axios from "axios";


class StadisticsSupporters extends React.Component{
    constructor(props){
        super(props);
        this.state={
            amountCitizen:0,
            amountPolitician:0,
            token: false,
            flagSupport:false,
            flagModalAddSupport:false,
            flagModalAddSupportCitizen:false,
            flagModalAddSupportPolitician:false,
            tokenDescription:null,
            domain:''
        }
    }

    readCookie=(name)=> {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
    createCookie=(name,value,days)=> {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            var expires = "; expires="+date.toGMTString();
        }
        else var expires = "";
        document.cookie = name+"="+value+expires+"; path=/";
    }
    
    eraseCookie=(name)=>{
        this.createCookie(name,"",-1);
    }
    getStadistics=async()=>{
        // console.log("###antes de validar"+this.state.cardValidation.tokenDescription);
        //console.log("el id del político es"+this.props.idPolitician);
        axios.post('https://politicosperu.pe/php/getPoliticianStadistics.php',JSON.stringify({idPolitician:this.props.idPolitician}))
        .then(response =>{
            //console.log(response.data);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    //console.log("#### valido, traemos las estadisticas");
                    this.setState({
                            amountCitizen:response.data[0].totalCitizen,
                            amountPolitician:response.data[1].totalPolitician,
                            totalMembers:response.data[2].totalMembers,
                            totalSuscribers:response.data[3].totalSuscribers,
                            totalVisits:response.data[4].totalVisits,
                            monthMembers:response.data[5].monthMembers,
                            monthSuscribers:response.data[6].monthSuscribers,
                            monthVisits:response.data[7].monthVisits,
                            weekMembers:response.data[8].weekMembers,
                            weekSuscribers:response.data[9].weekSuscribers,
                            weekVisits:response.data[10].weekVisits,
                            flagSupport:true
                        });
                    // this.getPoliticianDetails(this.getUrlPolitician());
            }else{
                //console.log("#### inValida, no hay estadisticas");
                this.setState({
                });
            }
    
        }).catch(error=>{
            //console.log("#### error, no hay estadisticas");

            this.setState({
            });
        });

    }
    putSupportCitizen=async(idCitizen)=>{
        // //console.log("###antes de validar"+this.state.cardValidation.tokenDescription);
        var politician1=this.readCookie("idPolitician");
        axios.post('https://politicosperu.pe/php/putSupportCitizen.php',JSON.stringify({idPolitician:this.props.idPolitician,idCitizen:idCitizen}))
        .then(response =>{
            //console.log("### respuesta del server put"+response.data[0]);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    //console.log("#### se debería agregar el support"+arrayResponse[0].status);
                    this.setState({
                        amountCitizen: parseInt(this.state.amountCitizen) + 1
                    });
                    // this.getPoliticianDetails(this.getUrlPolitician());
            }
    
        }).catch(error=>{
            //console.log("#### no puedes dar respaldo a este político"+error);
        });
    }
    putSupportPolitician=async()=>{
        // //console.log("###antes de validar"+this.state.cardValidation.tokenDescription);
        var politician1=this.readCookie("idPolitician");
        axios.post('https://politicosperu.pe/php/putSupportPolitician.php',JSON.stringify({idPolitician:this.props.idPolitician,idPolitician1:politician1}))
        .then(response =>{
            //console.log("### respuesta del server put"+response.data[0]);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    //console.log("#### se debería agregar el support"+arrayResponse[0].status);
                    this.setState({
                        amountPolitician:this.state.amountPolitician +1
                    });
                    // this.getPoliticianDetails(this.getUrlPolitician());
            }
    
        }).catch(error=>{
            //console.log("#### no puedes dar respaldo a este político"+error);
        });
    }

    handleSupportPolitician=(e)=>{
        if(this.readCookie("token") != null && this.readCookie("idCitizen") != null){
            //console.log("hay cookie de citizen");
            // //console.log("este es"+this.readCookie("token"));
            //console.log("here the idCitizen from cookie"+this.readCookie("idCitizen"));
            //console.log(e.currentTarget.getAttribute("data--button"));
            if(e.currentTarget.getAttribute("data--button")=="citizenIdButton"){
                this.putSupportCitizen(this.readCookie("idCitizen"));

            }else{
                alert("Como ciudadano, solo puedes dar soporte ciudadano");
            }
        }else if(this.readCookie("token") !=null && this.readCookie("idPolitician") !=null){
            //console.log("hay cookie de politician");
            if(e.currentTarget.getAttribute("data--button")=="politicianIdButton" && this.readCookie("idPolitician") != this.props.idPolitician){
                this.putSupportPolitician();
            }else{
                alert("Como político, solo puedes dar soporte político");
            }
        }
        else{
          this.setState(
            {
                token: false,
                flagModalAddSupport:true,
                tokenDescription:null,
                domain:''
            }
        );
        }
    }

    handleChangeToLogin=(e)=>{
        var modal = document.getElementsByClassName("support__addModalCreateAccount").item(0);
        var modal2 = document.getElementsByClassName("support__addModalLogin").item(0);
        var footer = document.getElementsByClassName("support__addModalFooter").item(0);

        footer.style.display="none";
        // modal.style.visibility="hidden";
        modal.style.transform="translate(-250px)";
        modal2.style.transform="translate(-250px)";
    }

    handleCheckboxs=(e)=>{
        var checkbox1=document.getElementById("politicCheckbox");
        var checkbox2=document.getElementById("citizenCheckbox");

        if(checkbox1.checked && e.target.name=="politico"){
            checkbox2.checked=false;
        }
        if(checkbox2.checked){
            checkbox1.checked=false;
        }

    }

    handleCloseAddModal=()=>{
        this.setState({
            flagModalAddSupport:false
        });
    }

componentDidUpdate(){

    if(this.props.idPolitician !='' && this.state.flagSupport==false){
        this.getStadistics();
    }

}


    componentDidMount(){
    }
    render(){
        return(
            <div className="stadisticsSupportersContainer">
            <div className="supportStadistics__item">
                <div data--button="politicianIdButton" onClick={this.handleSupportPolitician} className="supportStadistics__itemTop">
                    <img className="supportStadistics__icon" src={imgSupport1} alt="" />
                    {this.state.flagSupport && <span className="supportStadistics__data">{this.state.amountPolitician}</span>}
                    {!this.state.flagSupport && <span className="supportStadistics__data">0</span>}
                </div>
                <div className="supportStadistics__itemBottom">
                    <span className="supportStadistics__Description"> Respaldo Político</span>
                </div>
            </div>
            <div  className="supportStadistics__item">
                <div data--button="citizenIdButton" onClick={this.handleSupportPolitician} className="supportStadistics__itemTop">
                    <img className="supportStadistics__icon" src={imgSupport2} alt="" />
                    <span className="supportStadistics__data">{this.state.amountCitizen}</span>
                </div>
                <div className="supportStadistics__itemBottom">
                    <span className="supportStadistics__Description"> Respaldo Ciudadano</span>
                </div>
            </div>
            {this.state.flagModalAddSupport &&
                <div onClick={this.handleCloseAddModal} className="support__addModalContainer">
                    <div onClick={(e)=>e.stopPropagation()} className="support__addModalWrapper">
                        <div className="support__addModalCreateAccount">
                            <h1 className="support__addModalHead">
                                Deseo mejorar mi país
                            </h1>
                            <div className="support__addModalContent">
                                <h2 className="support__addModalWelcome">
                                    Bienvenido!
                                </h2>
                                <p className="support__addModalMessage">
                                    Estas a punto de impulsar un gran cambio <span className="support__addModalMessage--red">Unete!</span>
                                </p>
                                <div className="support__addModalInputContainer">
                                <input className="support__addModalInput" type="text" placeholder="DNI" />
                                <input className="support__addModalInput" type="password" placeholder="Clave"/>
                                <input className="support__addModalInput" type="text" placeholder="Email" />
                                </div>
                                <div className="support__addModalOptions">
                                    <div className="support__addModalOption">
                                        <input onClick={this.handleCheckboxs} id="politicCheckbox" className="support__addModalInputCheckbox" type="checkbox" name="politico"/> <label htmlFor="politico">Político</label>
                                    </div>
                                    <div className="support__addModalOption">
                                        <input onClick={this.handleCheckboxs} id="citizenCheckbox" className="support__addModalInputCheckbox" type="checkbox" name="ciudadano"/> <label htmlFor="ciudadano">Ciudadano</label>
                                    </div>
                                </div>
                            </div>
                            <div className="support__addModalFooter">
                                <button className="support__addModalButton">Crear Cuenta</button>
                                <span onClick={this.handleChangeToLogin} className="support__addModalTextFooter">ya poseo una cuenta</span>
                            </div>
                        </div>
                        <div className="support__addModalLogin">
                        <h1 className="support__addModalHead">
                                Deseo mejorar mi país
                            </h1>
                            <div className="support__addModalContent">
                                <h2 className="support__addModalWelcome">
                                    Es grato tenerte de vuelta!
                                </h2>
                                <p className="support__addModalMessage">
                                    Sigamos construyendo un país mejor <span className="support__addModalMessage--red">Viva el Perú!</span>
                                </p>
                                <div className="support__addModalInputContainer">
                                <input className="support__addModalInput" type="text" placeholder="DNI" />
                                <input className="support__addModalInput" type="password" placeholder="Clave"/>
                                </div>
                            </div>
                            <div className="support__addModalFooter">
                                <button className="support__addModalButton">Iniciar Sesión</button>
                                <span className="support__addModalTextFooter">Olvide mi contraseña</span>
                            </div>
                        </div>
                    </div>
                </div>

                }
        </div>
        );
    }
}

export default StadisticsSupporters;