import ProfileDetails from "../components/ProfileDetails";
import HeroProfile from "../components/HeroProfile";
import FormProfile from "../components/FormProfile";
import "./css/politician.css";
import LawsuitsListPolitician from "../components/LawsuitsListPolitician";
import React from "react";
import axios from "axios";
import { Activities } from "../components/Activities";
import imgFacebook  from "../components/images/facebook1.png";
import imgTwitter  from "../components/images/twitter1.png";
import imgInstagram  from "../components/images/instagram1.png";
import imgTiktok  from "../components/images/tiktok1.png";
import StadisticsSupporters from "../components/StadisticsSupporters";
import SupportList from "../components/SuppportList";
import Footer from "../components/Footer";

class Politician extends React.Component{
    constructor(props){
        super(props);
        this.state={
            flagLawsuit:false,
            data:[],
            name:'',
            trueLawsuits:0,
            falseLawsuits:0,
            inProcess:0,
            inDebate:0,
            idPolitician:''
        }
    }

    getUrlPolitician(){
        var url=window.location.href;
        var arrayurl=url.split("/");
        if(arrayurl.length==4){
            var urlUser=arrayurl[3];
           return urlUser;
        }
        if(arrayurl.length==5){
            var urlUser=arrayurl[4];
            return urlUser;
        }
    }

    getAge(date){
        var currentdate = new Date();
        var datePolitician = new Date(date);
        var datetime = '';

        datetime=this.dateDiffInDays(datePolitician,currentdate);
        // console.log("años"+(datetime)/365);
        return Math.trunc(datetime/365);
        
    }

    

    // a and b are javascript Date objects
    dateDiffInDays(a, b) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    
      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }
    
    // // test it
    // const a = new Date("2017-01-01"),
    //     b = new Date("2017-07-25"),
    //     difference = dateDiffInDays(a, b);

//TODO: aqui hay que arreglar ese get listLawsuit para el candidato en especifico
    getListLawsuit = async ()=>{
            const res = await axios.get('https://politicosperu.pe/php/getLawsuitPolitician.php?urlPolitician='+this.getUrlPolitician());
            //console.log("getting lawsuitlist from politician"+res.data);
            //console.log(res.data);
            var trueLawsuits=0;
            var falseLawsuits=0;
            var inProcess=0;
            var inDebate=0;
            if(res.data[0].state == "success"){
                //console.log("##########hay denuncias");
                res.data[0].data.map(function(element){
                    if(element.state == 1){
                        inProcess++;
                    }else if(element.state == 2){
                        inDebate++;
                    }else if(element.state == 3){
                        falseLawsuits++;
                    }else if(element.state == 4){
                        trueLawsuits++;
                    }
                });
        
                // //console.log("in Process"+inProcess);
                this.setState({
                    flagLawsuit:true,
                    data:res.data[0].data,
                    trueLawsuits:trueLawsuits,
                    falseLawsuits:falseLawsuits,
                    inProcess:inProcess,
                    inDebate:inDebate,
                });
            }else{
                this.setState({
                    flagLawsuit:false,
                });
            }


         } 


    getPoliticianDetails=async(query)=>{

        // //console.log("get politicianDetails"+query);
        const res = await axios.get('https://politicosperu.pe/php/getPoliticianDetails.php?idUrl='+query);
        //console.log(res.data);
        var idPolitician='';
        var name='';
        var surnameFather='';
        var surnameMother='';
        var actualWork='';
        var maxGrade='';
        var linkCv='';
        var linkPlan='';
        var linkTeam='';
        var income='';
        var politicalParty='';
        var imgProfile='';
        var imgBanner='';
        var regionDescription='';
        var provinceDescription='';
        var districtDescription='';
        var age='';
        var linkfacebook='';
        var linktwitter='';
        var linkinstagram='';
        var linktiktok='';
        res.data.map(function(element){
            idPolitician=element.idPolitician;
            name=element.name;
            surnameFather= element.surnameFather;
            surnameMother= element.surnameMother;
            actualWork= element.actualWork;
            maxGrade= element.maxGrade;
            linkCv= element.linkCv;
            linkPlan= element.linkPlan;
            linkTeam= element.linkTeam;
            income= element.income;
            politicalParty= element.politicalParty;
            imgProfile= element.imgProfile;
            imgBanner= element.imgBanner;
            regionDescription= element.regionDescription;
            provinceDescription= element.provinceDescription;
            districtDescription= element.districtDescription;
            age= element.birthDate;
            linkfacebook= element.link_facebook;
            linktwitter= element.link_twitter;
            linkinstagram= element.link_instagram;
            linktiktok= element.link_tiktok;
        });

        // //console.log("date:"+age);
        age= this.getAge(age);
        this.setState({
            idPolitician:idPolitician,
            name:name,
            surnameFather: surnameFather,
            surnameMother: surnameMother,
            actualWork: actualWork,
            maxGrade: maxGrade,
            linkCv: linkCv,
            linkPlan: linkPlan,
            linkTeam: linkTeam,
            income: income,
            politicalParty: politicalParty,
            imgProfile: imgProfile,
            imgBanner: imgBanner,
            regionDescription: regionDescription,
            provinceDescription: provinceDescription,
            districtDescription: districtDescription,
            age:age,
            linkfacebook: linkfacebook,
            linktwitter: linktwitter,
            linkinstagram: linkinstagram,
            linktiktok: linktiktok,
        });

    }


    componentDidMount(){
        this.getPoliticianDetails(this.getUrlPolitician());
        // //console.log('component did mount politician '+this.getUrlPolitician());
    }


    render(){
            // console.log("########## ID POLITICIAN ##############"+this.state.idPolitician);
        return(
            <div className="containerPolitician">
                <div className="containerSocialNetworks">
                    {this.state.linkfacebook != '' && <a target="blank" href={this.state.linkfacebook}>
                         <img className="profilePolitician__socialNetwork" src={imgFacebook} alt="" />
                        </a>}
                    {this.state.linktwitter !='' && <a href={this.state.linktwitter}>
                         <img className="profilePolitician__socialNetwork profilePolitician__socialNetwork--twitter" src={imgTwitter} alt="" />
                        </a>}
                    {this.state.linkinstagram != '' && <a href={this.state.linkinstagram}>
                         <img className="profilePolitician__socialNetwork" src={imgInstagram} alt="" />
                        </a>}
                    {this.state.linktiktok !='' && <a href={this.state.linktiktok}>
                         <img className="profilePolitician__socialNetwork" src={imgTiktok} alt="" />
                        </a>}
                </div>
                <div className="politicianWrapper">
                <div className="politicianGridContainer">

                    <HeroProfile
                    linkPlan={this.state.linkPlan}
                    linkTeam={this.state.linkTeam}
                    imgBanner={this.state.imgBanner}
                    />
                    <ProfileDetails
                    name={this.state.name}
                    surnameFather={this.state.surnameFather}
                    surnameMother={this.state.surnameMother}
                    actualWork={this.state.actualWork}
                    maxGrade={this.state.maxGrade}
                    linkCv={this.state.linkCv}
                    income={this.state.income}
                    politicalParty={this.state.politicalParty}
                    imgProfile={this.state.imgProfile}
                    imgBanner={this.state.imgBanner}
                    regionDescription={this.state.regionDescription}
                    provinceDescription={this.state.provinceDescription}
                    districtDescription={this.state.districtDescription}
                    trueLawsuits={this.state.trueLawsuits}
                    falseLawsuits={this.state.falseLawsuits}
                    inProcess={this.state.inProcess}
                    inDebate={this.state.inDebate}
                    age={this.state.age}
                    />
                    <StadisticsSupporters idPolitician={this.state.idPolitician}/>
                    <SupportList 
                    idPolitician={this.state.idPolitician} 
                    name={this.state.name} 
                    surnameFather={this.state.surnameFather}
                    surnameMother={this.state.surnameMother}
                    imageProfile={this.state.imgProfile}
                    />
                </div>
                <Activities idPolitician={this.state.idPolitician}/>
                <FormProfile
                idPolitician={this.state.idPolitician}
                />
                <LawsuitsListPolitician
                handleListLawsuit={this.getListLawsuit}
                data={this.state.data}
                idPolitician={this.state.idPolitician}
                flagLawsuit={this.state.flagLawsuit}
                />
            </div>
            
                <Footer/>
            
            </div>

        );
    }

}
export default Politician;