import "./styles/lawsuitsListPolitician.css";
import React, { Component } from "react";
import axios from "axios";
import ProfileLawsuitModal from "../modals/ProfileLawsuitModal"; 


class LawsuitsListPolitician extends React.Component{

    constructor(props){
        super(props);
        this.state={
            flagModalLawsuit:false,
            index:"",
            idDisclaimer:"",
            description:"",
            link:"",
            date:""
        };
                    }

    getDisclaimer=async(index,idLawsuit)=>{

            const res = await axios.get('https://politicosperu.pe/php/getDisclaimer.php?idLawsuit='+idLawsuit);
        
            var miarray=res.data;
            miarray=miarray[0];
            // console.log("disclaimer"+ JSON.stringify(miarray.status));
            if(miarray.status ==  "success"){
                this.setState(
                    {
                        flagModalLawsuit:true,
                        index:index,
                        idDisclaimer:miarray.idDisclaimer,
                        description:miarray.description,
                        link:miarray.link,
                        date:miarray.date
                    }
                );
            }else{
                this.setState(
                    {
                        flagModalLawsuit:true,
                        index:index,
                        // idDisclaimer:"",
                        // description:"",
                        // link:"",
                        // date:""
                    }
                );
            }
        
        
        }

    handleShowLawsuit=(e)=>{
        // console.log('pixeles'+window.scrollY);
        this.getDisclaimer(e.currentTarget.getAttribute("data--index"),e.currentTarget.getAttribute("data--idlawsuit"));

    }

    handleCancelShowLawsuitModal=(e)=>{

        this.setState(
            {
                flagModalLawsuit:false,
                index:null
            }
        );
    }
    componentDidMount(){
        // console.log('component did mount lawsuit list');
        this.props.handleListLawsuit();
        }

componentDidUpdate(){
    // console.log('component did update');
    
}
    render(){
        return(
            <div className="LawsuitListPoliticianContainer">
                <div className="LawsuitListPoliticianWrapper">

                <div className="LawsuitListPolitician__title">Últimas Denuncias</div>
                <div className="LawsuitListPolitician__subTitle">
                    <span className="LawsuitListPolitician__subTitlePolitician">Demandante</span>
                    <span className="LawsuitListPolitician__subTitleLawSuit">Acusación</span>
                    <span className="LawsuitListPolitician__subTitleState">Estado</span>
                </div>
                <div className="LawsuitListPolitician__list">

                {
                    this.props.flagLawsuit ?
                    this.props.data.map((element,index)=>{
                        var actualState="";
                        var color="";
                        var flagclick=false;

                        if(element.state==1){
                        actualState="En Desarrollo";
                        color="LawsuitListPolitician__stateInProcess";
                        flagclick=true;
                        }
                        if(element.state==2){
                        actualState="Con Réplica";
                        color="LawsuitListPolitician__stateInDebate";
                        flagclick=true;
                        }
                        if(element.state==3){
                        actualState="Falso";
                        color="LawsuitListPolitician__stateFalse";
                        flagclick=true;
                        }
                        if(element.state==4){
                        actualState="Confirmado";
                        color="LawsuitListPolitician__stateTrue";
                        flagclick=true;
                        }
                        return(
                        <div onClick={this.handleShowLawsuit} data--index={index} data--idlawsuit={element.idLawsuit} className="LawsuitListPolitician__item">
                        <span className="LawsuitListPolitician__politician">{element.name}</span>
                        <span className="LawsuitListPolitician__lawsuit">{element.title}</span>
                        <span  className={"LawsuitListPolitician__state "+color }>{actualState}</span>
                        </div>

                            )
                    })
                : <div className="LawsuitListPolitician__noItem">
                    <div className="LawsuitListPolician__noLawsuits"> No se han reportado denuncias</div>
                </div>
                }
                </div>

                </div>

                {this.state.flagModalLawsuit && <ProfileLawsuitModal
                    messageHeader='Detalles de la Denuncia'
                    handleCancelEditLawsuitModal= {this.handleCancelShowLawsuitModal}  
                    data={this.props.data}
                    index={this.state.index}
                    idDisclaimer={this.state.idDisclaimer}
                    description={this.state.description}
                    link={this.state.link}
                    date={this.state.date}
                    changeState={this.changeState}
                />}

            </div>
        );
    }
}

export default LawsuitsListPolitician;