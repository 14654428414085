import "./styles/profileDetailsEdit.css";
import politicianImage from "./images/ajedrez2.jpg";
import Icon1 from "./images/gavel.png";
import Icon2 from "./images/sync.png";
import Icon3 from "./images/fakeNews.png";
import editImage from "./images/edit.png";
import react from "react";
import axios from "axios";

class ProfileDetailsEdit extends react.Component{

    constructor(props){
        super(props);
        this.state={
            name:''
        }
    }

    getUrlPolitician(){
        var url=window.location.href;
        // console.log(url);
        var arrayurl=url.split("/");
        if(arrayurl.length==4){
            var urlUser=arrayurl[3];
            // console.log(urlUser);
           return urlUser;
        }
        if(arrayurl.length==5){
            var urlUser=arrayurl[4];
            return urlUser;
        }
    }

    getPoliticianDetails=async(query)=>{

        const res = await axios.get('https://politicosperu.pe/php/getPoliticianDetails.php?idUrl='+query);
        var namePolitician='';
        res.data.map(function(element){
            namePolitician=element.name;
        });
        // console.log(namePolitician);
        this.setState({
            name:namePolitician
        });

    }

    

    handleModal=()=>{
        // console.log("modal");

        
    }
    componentDidMount(){
        this.getPoliticianDetails(this.getUrlPolitician());
    }
    render(){
        return(
            <div className="containerProfileDetailsEdit">
                <div onClick={this.props.handleChangeImage}  className="profileDetailsEdit__imageContainer">
                    <img  className="profileDetailsEdit__image" id="profileImg" src={this.props.imgProfile} alt="" />
                </div>
                <div onClick={this.props.handleOpenModal} className="ProfileDetailsEditWrapper">
                <div className="profileDetailsEdit__data">
                    <div className="profileDetailsEdit__dataItem"> 
                        <h3 className="profileDetailsEdit__dataName">{this.props.name} {this.props.surnameFather} {this.props.surnameMother}</h3>
                    </div>
                    <div className="profileDetailsEdit__dataItem">
                        <span className="profileDetailsEdit__dataHead">Edad:</span><span className="profileDetailsEdit__dataContent">{!(isNaN(this.props.age)) && <span>{this.props.age}</span>  }</span>    
                    </div>    
                    <div className="profileDetailsEdit__dataItem">
                        <span className="profileDetailsEdit__dataHead">Lugar de Nacimiento:</span><span className="profileDetailsEdit__dataContent">{!(this.props.regionDescription == null) && <span>{this.props.regionDescription}, {this.props.provinceDescription}, {this.props.districtDescription}</span> }</span>    
                    </div>    
                    <div className="profileDetailsEdit__dataItem">
                        <span className="profileDetailsEdit__dataHead" title="Actividad Laboral Principal">ALP:</span><span className="profileDetailsEdit__dataContent">{this.props.actualWork}</span>    
                    </div>    
                    <div className="profileDetailsEdit__dataItem">
                        <span className="profileDetailsEdit__dataHead" title="Máximo grado académico" >MGA:</span><span className="profileDetailsEdit__dataContent">{this.props.maxGrade}</span>    
                    </div>    
                    <div className="profileDetailsEdit__dataItem">
                        <span className="profileDetailsEdit__dataHead" title="Ingresos Anuales">IA:</span><span className="profileDetailsEdit__dataContent">
                            {!(this.props.income == "" || this.props.income == null) && <span>S/. {this.props.income}</span> }

                            </span>    
                    </div>    
                    <div className="profileDetailsEdit__dataItem">
                        <span className="profileDetailsEdit__dataHead" title="Partido Político">PP:</span><span className="profileDetailsEdit__dataContent">{this.props.politicalParty}</span>    
                    </div>    
                </div>
                <div className="profileDetailsEdit__dataLawsuits">
                    <div className="profileDetailsEdit__dataLawSuitsTitle">
                        Denuncias
    
                    </div>
                    <div className="profileDetailsEdit__dataLawSuitsIcons">
                        <div className="profileDetailsEdit__dataLawSuitsIcon">
                            <div  title="Confirmadas"  className="profileDetailsEdit__dataLawSuitsIconImageContainer">   
                            <img className="profileDetailsEdit__dataLawSuitsIconImage" src={Icon1} alt="" />
                            </div>
                            <span className="profileDetailsEdit__dataLawSuitsIconData profileDetailsEdit__dataLawSuitsIconData--trueLawsuits">{this.props.trueLawsuits}</span>
                        </div>
                        <div className="profileDetailsEdit__dataLawSuitsIcon">
                            <div title="En Desarrollo" className="profileDetailsEdit__dataLawSuitsIconImageContainer">
                            <img className="profileDetailsEdit__dataLawSuitsIconImage" src={Icon2} alt="" />
                            </div>
                            <span className="profileDetailsEdit__dataLawSuitsIconData profileDetailsEdit__dataLawSuitsIconData--InProcess">{this.props.inProcess}</span>
                        </div>
                        <div className="profileDetailsEdit__dataLawSuitsIcon">
                            <div  title="Desmentidas"  className="profileDetailsEdit__dataLawSuitsIconImageContainer">
                            <img className="profileDetailsEdit__dataLawSuitsIconImage" src={Icon3} alt="" />
                            </div>
                            <span className="profileDetailsEdit__dataLawSuitsIconData profileDetailsEdit__dataLawSuitsIconData--inDebate">{this.props.inDebate}</span>
                        </div>
                        <div className="profileDetailsEdit__dataLawSuitsIcon">
                            <div  title="Falsas"  className="profileDetailsEdit__dataLawSuitsIconImageContainer">
                            <img className="profileDetailsEdit__dataLawSuitsIconImage" src={Icon1} alt="" />
                            </div>
                            <span className="profileDetailsEdit__dataLawSuitsIconData profileDetailsEdit__dataLawSuitsIconData--falseLawsuits">{this.props.falseLawsuits}</span>
                        </div>
                    </div>
                </div>                    
                </div> 

                <div  target="_blank" className="profileDetailsEdit__button">
                    <span  onClick={this.props.handleChangeFile} id="cv"   className="profileDetailsEdit__buttonText">Hoja de Vida</span>
                </div>
            </div>
            );
    }
    
}
export default ProfileDetailsEdit;