import React from "react";
import axios from "axios";
import "./css/signUp.css";
import background from "./images/backgroundSignUp.jpg";
import GeneralModal from "../modals/GeneralModal";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import InvitationModal from "../modals/InvitationModal";
import ImgEye from "./images/watchPassword2.png";

class SignUp extends React.Component{
    constructor(props){
        super(props);
        this.state={
            dni:"",
            password:"",
            email:"",
            flagShowPassword:false,
            flagPolitician:false,
            flagCitizen:false,
            dniFlag:false,
            passwordFlag:false,
            emailFlag:false,
            errorCheckBox:true,
            flagError:false,
            flagErrorServer:false,
            flagSuccessModal:false,
            errorDni:false,
            errorEmail:false,
            errorPassord:false,
            modalInvitation:false,
            code:"",
                }
    }

    handleCheckboxs=(e)=>{
        var checkbox1=document.getElementById("politicianCheckbox");
        var checkbox2=document.getElementById("citizenCheckbox");

        if(e.target.name == "politician"){
            if(checkbox2.checked){
                checkbox1.checked=true;
                checkbox2.checked=false;
                this.setState({
                    flagPolitician:true,
                    flagCitizen:false,
                });
            }else{
                checkbox1.checked=true;
                this.setState({
                    flagPolitician:true,
                });
            }
            
        }else{
            if(checkbox2.checked){
                checkbox1.checked=false;
                checkbox2.checked=true;
                this.setState({
                    flagPolitician:false,
                    flagCitizen:true,
                });
            }else{
                checkbox2.checked=true;
                this.setState({
                    flagCitizen:true,
                });
            }
        }
        
    }

    handleOnChange=(e)=>{
        var valueFlag=false;
        if(e.target.name == "dni"){
            if(/^([0-9]){8}$/.test(e.target.value)){
                valueFlag= true;
            }else{
                valueFlag= false;
            }
        }else if(e.target.name == "password"){

            if(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(e.target.value)){
                valueFlag= true;
            }else{
                valueFlag= false;
            }
        }else{
            if(/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/ .test(e.target.value)){
                valueFlag= true;
            }else{
                valueFlag= false;
            }
        }

        this.setState({
            [e.target.name]:e.target.value,
            [e.target.name+"Flag"]: valueFlag
        });
    }
    hideErrorMessage=()=>{
        window.setTimeout(()=>{this.setState({flagError:false})},4500);
    
    }
    handleUpdateCode=(e)=>{
        this.setState({
            code: e.target.value
        });
    }

    handleShowPassword=(e)=>{
        var element= e.target;
        var style= window.getComputedStyle(element);

        console.log(style.getPropertyValue("opacity"));
        if(!this.state.flagShowPassword){
            element.previousSibling.setAttribute("type", "text");
            element.style.opacity= "1";
            this.setState({
                flagShowPassword:true,
            });
        }else{
            element.previousSibling.setAttribute("type", "password");
            element.style.opacity= "0.5";
            this.setState({
                flagShowPassword:false,
            });
        }
    }
    handleVerifyCode= async()=>{

                var type="0";
        if(this.state.flagPolitician){
            type='1';
        }else{
            type="0";
        }

        axios.post('https://politicosperu.pe/php/signUpPolitician.php', JSON.stringify({
            code: this.state.code,
            dni:this.state.dni,
            email:this.state.email,
            password:this.state.password,
            type: type,
        })).then(response =>{
                           console.log(response.data);
                           var arrayResponse= new Array(response.data[0]);
                   
                           if(arrayResponse[0].status == "success"){  
                                   //console.log("#### Se creo la cuenta");
                                   this.setState({
                                       flagErrorServer:false,
                                       flagSuccessModal:true,
                                       modalInvitation:false,
                                       code:''
                                   });
                                   // this.getPoliticianDetails(this.getUrlPolitician());
                           }else{
                               console.log("#### no se pudo crear la cuenta");
                               this.setState({
                                   flagErrorServer:true,
                                   flagErrorModal:true,
                               });
                           }
                   
        }).catch(error=>{
                           //console.log("#### error");
               
                           this.setState({
                               flagErrorServer:true,
                               flagErrorModal:true,
                           });
        });
    }
    handleSignIn= async()=>{
        var type="0";
        if(this.state.flagPolitician){
            type='1';
        }else{
            type="0";
        }

        if(!this.state.flagCitizen && !this.state.flagPolitician){
            this.hideErrorMessage();
            this.setState({
                errorCheckBox:false,
                flagError:true,
            });
        }else{


                if(this.state.dniFlag && this.state.emailFlag && this.state.passwordFlag){

                    if(this.state.flagPolitician){
                        this.setState({
                            modalInvitation: true,
                        });
                    }else{
                        axios.post('https://politicosperu.pe/php/signUp.php',
                        JSON.stringify({
                           dni:this.state.dni,
                           email:this.state.email,
                           password:this.state.password,
                           type:type,
                       }))
                       .then(response =>{
                           //console.log(response.data);
                           var arrayResponse= new Array(response.data[0]);
                   
                           if(arrayResponse[0].status == "success"){  
                                   //console.log("#### revisa tu correo");
                                   this.setState({
                                       flagErrorServer:false,
                                       flagSuccessModal:true,
                                   });
                                   // this.getPoliticianDetails(this.getUrlPolitician());
                           }else{
                               //console.log("#### no se envio el correo");
                               this.setState({
                                   flagErrorServer:true,
                                   flagErrorModal:true,
                               });
                           }
                   
                       }).catch(error=>{
                           //console.log("#### error");
               
                           this.setState({
                               flagErrorServer:true,
                               flagErrorModal:true,
                           });
                       });
                    }

                }else{
                    this.hideErrorMessage();
                    this.setState({
                        flagError:true,
                        errorCheckBox:true,
                    });
                }
            

        }

    }

    closeErrorServerModal=()=>{
        this.setState({
            flagErrorServer:false,
        });
    }
    closeSuccessModal=()=>{
        this.setState({
            flagSuccessModal:false,
        });
    }
    goHome=()=>{
        const { navigation } = this.props;
  
        navigation('/');
    }
    closeInvitationModal=()=>{
        this.setState({
            modalInvitation: false,
            code:''
        });
    }


    render(){
        return(
            <div className="SignUpContainer">
                {this.state.flagError &&
                <div className="SignUpErrorWrapper">
                <span className="SignUpErrorWrapper__message">
                {!this.state.dniFlag && "- Ingrese un DNI valido"}
                </span>
                <span className="SignUpErrorWrapper__message">
                {!this.state.emailFlag && "- El email es invalido"}
                </span>
                <span className="SignUpErrorWrapper__message">
                {!this.state.passwordFlag && "- La clave necesita 6-16 caracteres, simbolos, números y letras"}
                </span>
                <span className="SignUpErrorWrapper__message">
                {!this.state.errorCheckBox && "- Seleccione el tipo de cuenta"}
                </span>
                </div>
                }
                {this.state.modalInvitation &&
                <InvitationModal
                header="Código de Invitación"
                message="Si no tienes un código, escribenos a contacto@politicosperu.pe"
                buttonText="Confirmar"
                handleCloseModal={this.closeInvitationModal}
                handleUpdateCode= {this.handleUpdateCode}
                handleOnClickMethod={this.handleVerifyCode}
                />
                }
                {this.state.flagErrorServer && 
                <GeneralModal
                    header="Ups, Ocurrio un error"
                    message="Al parecer ocurrio un error con nuestro servidor, intentalo nuevamente mas tarde o escribenos a contacto@politicosperu.pe"
                    buttonText="Continuar"
                    handleCloseModal={this.closeErrorServerModal}
                    handleOnClickMethod={this.closeErrorServerModal}
                />
                }
                {this.state.flagSuccessModal && 
                <GeneralModal
                    header="Creación Existosa"
                    message="Revisa tu correo para confirmar tu cuenta"
                    buttonText="Continuar"
                    handleCloseModal={this.closeSuccessModal}
                    handleOnClickMethod={this.goHome}
                />
                }
                <img className="SignUpBackground" src={background} alt="" />
                <div className="SignUpWrapper">
                    <div className="SignUpSubWrapper">

                    <div onClick={(e)=>e.stopPropagation()} className="SignUp__addModalWrapper">
                        <div className="SignUp__addModalCreateAccount">
                            <h1 className="SignUp__addModalHead">
                                Políticos Perú
                            </h1>
                            <div className="SignUp__addModalContent">
                                <h2 className="SignUp__addModalWelcome">
                                    Bienvenido!
                                </h2>
                                <p className="SignUp__addModalMessage">
                                    Estas a punto de impulsar un gran cambio <span className="SignUp__addModalMessage--red">Unete!</span>
                                </p>
                                <div className="SignUp__addModalInputContainer">
                                <input onChange={this.handleOnChange} className="SignUp__addModalInput" value={this.state.dni} name="dni" type="text" placeholder="DNI" />
                                <div className="SignUp__addModalInputWrapper">
                                <input onChange={this.handleOnChange} className="SignUp__addModalInput" value={this.state.password} name="password" type="password" placeholder="Contraseña"/>
                                <img onClick={this.handleShowPassword} src={ImgEye} className="SignUp__addModalInputImage" alt="" />
                                </div>
                                <input onChange={this.handleOnChange} className="SignUp__addModalInput" value={this.state.email} name="email" type="text" placeholder="Email" />
                                </div>
                                <div className="SignUp__addModalOptions">
                                    <div className="SignUp__addModalOption">
                                        <input onClick={this.handleCheckboxs} id="politicianCheckbox" className="SignUp__addModalInputCheckbox" type="checkbox" name="politician"/> <label htmlFor="politician">Político</label>
                                    </div>
                                    <div className="SignUp__addModalOption"> 
                                        <input onClick={this.handleCheckboxs} id="citizenCheckbox" className="SignUp__addModalInputCheckbox" type="checkbox" name="citizen"/> <label htmlFor="citizen">Ciudadano</label>
                                    </div>
                                </div>
                            </div>
                            <div className="SignUp__addModalFooter">
                                <button onClick={this.handleSignIn} className="SignUp__addModalButton">Crear Cuenta</button>
                            </div>
                        </div>
                    </div>
                    </div>
                
                <Footer/>
                </div>
            </div>
        )
    }
}
export default function(props){
    const navigation = useNavigate();
    return <SignUp {...props} navigation={navigation} />
} 
    