import React from "react";
import imgCheck from "./images/check.png";
import axios from "axios";
import "./styles/imagesCarrousel.css";

class ImagesCarrousel extends React.Component{
    constructor(props){
        super(props);
        this.state={
            images:[],
            pages:[],
            flagImages:false,
            page:1,
            pixels:0
        }
    }

    getImages=async()=>{
        // console.log("###antes de validar"+this.state.cardValidation.tokenDescription);
        axios.post('https://politicosperu.pe/php/getImagesCarrousel.php',JSON.stringify({page:this.state.page}))
        .then(response =>{
            //console.log(response.data);
            var arrayResponse= new Array(response.data[0]);

            if(arrayResponse[0].status == "success"){  

                if(this.state.page==1){
                    this.setState({
                        flagImages:true,
                        images:[...this.state.images,response.data],
                        pages:[...this.state.pages,this.state.page],
                        page:this.state.page +1,
                        pixels:0

                    });
                }else{
                    var actualPage= document.getElementById("carrouselMain");
                    var space=this.state.pixels - 290;
                    actualPage.style.transform="translateX("+space+"px)";
                    this.setState({
                        flagImages:true,
                        images:[...this.state.images,response.data],
                        pages:[...this.state.pages,this.state.page],
                        page:this.state.page +1,
                        pixels:this.state.pixels - 290
                    });
                }
                //console.log("#### valida la devolución de imagenes");

                    // this.getPoliticianDetails(this.getUrlPolitician());
            }else{
                //console.log("#### inValida");
                this.setState({
                    flagActivities:true,
                });
            }
    
        }).catch(error=>{
            //console.log("#### error");

            this.setState({
                flagActivities:true,
            });
        });

    }

    handleRightClick=()=>{
       if(this.state.pages.includes(this.state.page)){
        var actualPage= document.getElementById("carrouselMain");
        var space= this.state.pixels - 290;
        actualPage.style.transform="translateX("+space+"px)";
        this.setState({
            pixels:this.state.pixels - 290,
            page:this.state.page +1,
        });
       }else{
        this.getImages();
       } 
    }
    handleLeftClick=()=>{
        if(!(this.state.page ==2)){
            var actualPage= document.getElementById("carrouselMain");
            var space=this.state.pixels + 290;

            actualPage.style.transform="translateX("+space+"px)";
            this.setState({
                pixels:this.state.pixels + 290,
                page:this.state.page - 1
            });
        }

        
    }

    handleSelectImage=(e)=>{

        if(!e.target.nextSibling.classList.contains("activitiesEditModal__itemIcon--show")){
            var images=document.getElementsByClassName("activitiesEditModal__itemIcon");
            //console.log(images.length);
            for(var i=0;i<images.length;i++){
                images.item(i).classList.remove("activitiesEditModal__itemIcon--show");
            }
            e.target.nextSibling.classList.add("activitiesEditModal__itemIcon--show");

            this.props.handleImageFlag(e.target.getAttribute("src"), true);
        }else{
            e.target.nextSibling.classList.remove("activitiesEditModal__itemIcon--show");
            this.props.handleImageFlag("", false);
        }
    
    }


    componentDidMount(){
        this.getImages();
    }
    render(){
        return(
            <div className="carrouselContainer">
            {/* <h4 className="activitiesEditModal__itemTitle">Imagen {this.state.flags.flagImageWrong && <span>*</span>}</h4> */}
            
            <div className="carrouselHeader">
                <h4 className="carrousel__title">Imagen</h4>
                <div className="carrousel__buttonContainer">
                    <button onClick={this.handleLeftClick} className="carrousel__button">&lt;</button>
                    <button onClick={this.handleRightClick} className="carrousel__button">&gt;</button>
                </div>
            </div>
            <div className="carrousel__listContainer">
                <div id="carrouselMain" className="carrousel__listWrapper">
                {this.state.flagImages &&
                    this.state.images.map((element,index)=>{
                        return(
                            <div id={"carrousel"+(index+1)} className="carrousel__list">
                            {element.map((image)=>{
                                return(
                                    <div className="activitiesEditModal__itemImageContainer">
                                    <img onClick={this.handleSelectImage} name="image1" className="activitiesEditModal__itemImage" src={image.url} alt="" />
                                        <img className="activitiesEditModal__itemIcon" src={imgCheck} alt="" />
                                    </div>
                                );
                            })}
                            </div>
                        )
                    })
                 }
                </div>
            </div>
            <span onClick={this.handleUpdateActivity} className="carrousel__title--bottom">Cargar Imagen</span>
        </div>
        );
    }
}
export default ImagesCarrousel;