import "./styles/profileDetails.css";
import politicianImage from "./images/ajedrez2.jpg";
import Icon1 from "./images/gavel.png";
import Icon2 from "./images/sync.png";
import Icon3 from "./images/fakeNews.png";
import react from "react";
import axios from "axios";

class ProfileDetails extends react.Component{

    constructor(props){
        super(props);
        this.state={
            name:''
        }
    }

    getUrlPolitician(){
        var url=window.location.href;
        var arrayurl=url.split("/");
        if(arrayurl.length==4){
            var urlUser=arrayurl[3];
           return urlUser;
        }
        if(arrayurl.length==5){
            var urlUser=arrayurl[4];
            return urlUser;
        }
    }

    getPoliticianDetails=async(query)=>{

        const res = await axios.get('https://politicosperu.pe/php/getPoliticianDetailsGuest.php?idUrl='+query);
        var namePolitician='';
        res.data.map(function(element){
            namePolitician=element.name;
        });
        // console.log(namePolitician);
        this.setState({
            name:namePolitician
        });

    }
    componentDidMount(){
        this.getPoliticianDetails(this.getUrlPolitician());
    }
    render(){
        return(
            <div className="containerProfileDetails">
                <div className="profileDetails__imageContainer">
                    <img  className="profileDetails__image" src={this.props.imgProfile} alt="" />
                </div>
                <div className="profileDetails__data">
                    <div className="profileDetails__dataItem"> 
                        <h3 className="profileDetails__dataName">{this.props.name} {this.props.surnameFather} {this.props.surnameMother}</h3>
                    </div>
                    <div className="profileDetails__dataItem">
                        <span className="profileDetails__dataHead">Edad:</span><span className="profileDetails__dataContent">{!(isNaN(this.props.age)) && <span>{this.props.age}</span>  }</span>    
                    </div>    
                    <div className="profileDetails__dataItem">
                        <span className="profileDetails__dataHead">Lugar de Nacimiento:</span><span className="profileDetails__dataContent">{!(this.props.regionDescription == null) && <span>{this.props.regionDescription}, {this.props.provinceDescription}, {this.props.districtDescription}</span> }</span>    
                    </div>    
                    <div className="profileDetails__dataItem">
                        <span className="profileDetails__dataHead" title="Actividad Laboral Principal">ALP:</span><span className="profileDetails__dataContent">{this.props.actualWork}</span>    
                    </div>    
                    <div className="profileDetails__dataItem">
                        <span className="profileDetails__dataHead" title="Máximo Grado Académico" >MGA:</span><span className="profileDetails__dataContent">{this.props.maxGrade}</span>    
                    </div>    
                    <div className="profileDetails__dataItem">
                        <span className="profileDetails__dataHead" title="Ingresos Anuales">IA:</span>
                         <span className="profileDetails__dataContent">{(!(this.props.income =="0")) && <span>S/. {this.props.income}</span>}</span>    
                    </div>    
                    <div className="profileDetails__dataItem">
                        <span className="profileDetails__dataHead" title="Partido Político">PP:</span><span className="profileDetails__dataContent">{this.props.politicalParty}</span>    
                    </div>    
                </div>
                <div className="profileDetails__dataLawsuits">
                    <div className="profileDetails__dataLawSuitsTitle">
                        Denuncias
    
                    </div>
                    <div className="profileDetails__dataLawSuitsIcons">
                        <div className="profileDetails__dataLawSuitsIcon">
                            <div  title="Confirmadas"  className="profileDetails__dataLawSuitsIconImageContainer">   
                            <img className="profileDetails__dataLawSuitsIconImage" src={Icon1} alt="" />
                            </div>
                            <span className="profileDetails__dataLawSuitsIconData profileDetails__dataLawSuitsIconData--trueLawsuits">{this.props.trueLawsuits}</span>
                        </div>
                        <div className="profileDetails__dataLawSuitsIcon">
                            <div title="En Desarrollo" className="profileDetails__dataLawSuitsIconImageContainer">
                            <img className="profileDetails__dataLawSuitsIconImage" src={Icon2} alt="" />
                            </div>
                            <span className="profileDetails__dataLawSuitsIconData profileDetails__dataLawSuitsIconData--InProcess">{this.props.inProcess}</span>
                        </div>
                        <div className="profileDetails__dataLawSuitsIcon">
                            <div  title="Con Réplica"  className="profileDetails__dataLawSuitsIconImageContainer">
                            <img className="profileDetails__dataLawSuitsIconImage" src={Icon3} alt="" />
                            </div>
                            <span className="profileDetails__dataLawSuitsIconData profileDetails__dataLawSuitsIconData--inDebate">{this.props.inDebate}</span>
                        </div>
                        <div className="profileDetails__dataLawSuitsIcon">
                            <div  title="Falsas"  className="profileDetails__dataLawSuitsIconImageContainer">
                            <img className="profileDetails__dataLawSuitsIconImage" src={Icon1} alt="" />
                            </div>
                            <span className="profileDetails__dataLawSuitsIconData profileDetails__dataLawSuitsIconData--falseLawsuits">{this.props.falseLawsuits}</span>
                        </div>
                    </div>
                </div>
                <a href={this.props.linkCv} target="_blank" className="profileDetails__button">
                    <span className="profileDetails__buttonText">Hoja de Vida</span>
                </a>
            </div>
            );
    }
    
}
export default ProfileDetails;