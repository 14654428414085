import React from "react";
import "./styles/formProfile.css";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();

class FormProfile extends React.Component{
    constructor(props){
        super(props);
        this.state={
            option:true,
            flag:false,
            data:{
                name:"",
                email:"",
                phone:"",
                type:1,
                id:this.props.idPolitician
            }
        };
    }


    getUrlPolitician(){
        var url=window.location.href;
        var arrayurl=url.split("/");
        if(arrayurl.length==4){
            var urlUser=arrayurl[3];
           return urlUser;
        }
        if(arrayurl.length==5){
            var urlUser=arrayurl[4];
            return urlUser;
        }
    }


    
    submitHandler=(e)=>{
        // console.log(JSON.stringify(this.state.data));
      
        axios.post('https://politicosperu.pe/php/register.php',JSON.stringify(this.state.data))
        .then(response =>{
            // console.log(response);
            this.setState({
                ...this.state,
                data:{
                    ...this.state.data,
                    name:"",
                    email:"",
                    phone:"",
                }
            });
        }).catch(error=>{
            // console.log(error);
        });
    }

    joinusTab=(e)=>{
       if(!this.state.option){
           var JoinButton= document.getElementsByClassName("profileForm__Button--join");
           var SuscribeButton= document.getElementsByClassName("profileForm__Button--suscribe");
           var offTab =document.getElementsByClassName("profileForm__button");
           
           JoinButton.item(0).style.display="block";
           SuscribeButton.item(0).style.display="none";

           e.target.style.backgroundColor="#EA2027";
           offTab.item(1).style.backgroundColor="rgba(233,32,39,0.5)";
           
           this.setState(
            {
                option:true,
                data:{
                    ...this.state.data,
                    type:1
                }
            }
        );
        }

    }
    suscribeTab=(e)=>{
       if(this.state.option){
           var SuscribeButton= document.getElementsByClassName("profileForm__Button--suscribe");
           var JoinButton= document.getElementsByClassName("profileForm__Button--join");
           var offTab =document.getElementsByClassName("profileForm__button");
           

           e.target.style.backgroundColor="#EA2027";
           offTab.item(0).style.backgroundColor="rgba(233,32,39,0.5)";

           JoinButton.item(0).style.display="none";
           SuscribeButton.item(0).style.display="block";
            this.setState(
                {
                    option:false,
                    data:{
                        ...this.state.data,
                        type:0
                    }
                }
            );
        }

    }


handleRegister=e=>{
    // console.log("join");
    const recaptchaValue = recaptchaRef.current.getValue();
    if(recaptchaValue){
        // console.log("true captcha");
        this.submitHandler();

    }else{
        // console.log("false captcha");
    }

}


handleChange=e=>{
    // console.log("{"+e.target.name +":"+e.target.value+"}");
    this.setState({
        data: {
            ...this.state.data,
            [e.target.name]:e.target.value
        }
    })
}

componentDidMount(){
    // console.log("3#########"+this.props.idPolitician);


}

componentDidUpdate(pP, pS, sS){
    // console.log("componentDidUpdate el valor de this.props.idPolician es"+this.props.idPolitician);
    // console.log(this.props.idPolitician);
    if((!this.state.flag && this.props.idPolitician != "")){
        // console.log("inside componentDidUpdate");

        this.setState({
            flag:true,
            data:{
                ...this.state.data,
                id:this.props.idPolitician,
            }

        }
        );
    }
}

    render(){
        return(
            <div className="profileFormContainer">
            <div className="profileFormWrapper">

                <div className="profileForm__buttonsContainer">
                    <button onClick={this.joinusTab} className="profileForm__button">Se parte de mi equipo</button>
                    <button onClick={this.suscribeTab} className="profileForm__button"> Suscribete a mis boletines</button>
                </div>
                <div className="profileForm__content">
                    <input onChange={this.handleChange} className="profileForm__input profileForm__input--name" name="name" value={this.state.data.name} type="text" placeholder="Nombre Completo" />
                    <input onChange={this.handleChange} className="profileForm__input profileForm__input--email" name="email" value={this.state.data.email} type="email" placeholder="Correo Electrónico"/>
                    <input onChange={this.handleChange} className="profileForm__input profileForm__input--phone" name="phone" value={this.state.data.phone} type="tel" placeholder="Número de Contacto"/>
                    {/* <input onChange={this.handleChange} className="profileForm__input profileForm__input--code" name="code" type="text" placeholder="Código de Confirmación"/> */}
                    <div className="profileForm__captcha">
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LcLAw8lAAAAAIE1MPJTXsAG1iPmypZWShkbWvuC"
                        // onChange={this.onChange}
                        />
                    </div>
                    <button onClick={this.handleRegister} className="profileForm__Button profileForm__Button--join">Unirme</button>
                    <button onClick={this.handleRegister} className="profileForm__Button profileForm__Button--suscribe">Suscribirme</button>
                </div>
            </div>
        </div>
            );
    }

}
export default FormProfile;