import React from "react";
import "./styles/GeneralModal.css"
class GeneralModal extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }
    render(){
        return(
            <div onClick={this.props.handleCloseModal} className="GeneralModalContainer">
                <div onClick={(e)=>{e.stopPropagation()}} className="GeneralModalWrapper">
                    <div className="GeneralModal__header">
                        <span className="GeneralModal__title">{this.props.header}</span>
                        <span onClick={this.props.handleCloseModal} className="GeneralModal_close">x</span>
                    </div>
                    <div className="GeneralModal__body">
                        {this.props.message}
                    </div>
                    <div className="GeneralModal__footer">
                    <button className="GeneralModal__button" onClick={this.props.handleOnClickMethod}> {this.props.buttonText}</button>
                    </div>
                </div>
            </div>
        );
    }
}
export default GeneralModal;