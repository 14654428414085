import React from "react";
import ProfileDetailsEdit from "../components/ProfileDetailsEdit";
import HeroProfileEdit from "../components/HeroProfileEdit";
import FormProfile from "../components/FormProfile";
import axios from "axios";
import './css/userProfile.css';
import ProfileDetailsModal from "../modals/ProfileDetailsModal";
import ProfilePhotoModal from "../modals/ProfilePhotoModal";
import ProfileFileModal from "../modals/ProfileFileModal";
import LawsuitsListPoliticianEdit from "../components/LawsuitsListPoliticianEdit";
import ProfileEditLawsuitModal from "../modals/ProfileEditLawsuitModal";
import Prospects from "../components/Prospects";
import { Activities } from "../components/Activities";
import Residence from '../components/Residence';
import SocialnetworksEdit from "../components/SocialnetworksEdit";
import MyStadistics from "../components/MyStadistics";
import {ActivitiesEdit} from "../components/ActivitiesEdit";
import Footer from "../components/Footer";

class UserProfile extends React.Component {

    constructor(props){
        super(props);
        this.state={
            cardValidation:{
                urlUser:this.getUrlPolitician(),
                token: false,
                tokenDescription:this.readCookie("token"),
            },
            flagValid:false,
            flagModal:false,
            flagImage:false,
            flagBanner:false,
            flagProfile:false,
            flagCv:false,
            flagPlan:false,
            flagTeam:false,
            flagEditLawsuit:false,
            data:[],
            name:'',
            imgProfile:'',
            imgBanner:'',
            trueLawsuits:0,
            falseLawsuits:0,
            inProcess:0,
            inDebate:0,
            flagErrors:true
        }
    }

    getAge(date){
        var currentdate = new Date();
        var datePolitician = new Date(date);
        var datetime = '';

        datetime=this.dateDiffInDays(datePolitician,currentdate);
        // console.log("años"+(datetime)/365);
        return Math.trunc(datetime/365);
        
    }

    updateImgProfile=(imgProfile)=>{
        this.setState(
            {
                imgProfile:imgProfile
            }
        );
    }
    updateImgBanner=(imgBanner)=>{
        this.setState(
            {
                imgBanner:imgBanner
            }
        );
    }

    getListLawsuit = async ()=>{
        const res = await axios.get('https://politicosperu.pe/php/getLawsuitPolitician.php?urlPolitician='+this.getUrlPolitician());
        //console.log("getting lawsuitlist from politician"+res.data);
        var trueLawsuits=0;
        var falseLawsuits=0;
        var inProcess=0;
        var inDebate=0;
        var flagErrors=true;

        if(res.data[0].state == "success"){
            res.data[0].data.map(function(element){
                if(element.state == 1){
                    inProcess++;
                }else if(element.state == 2){
                    inDebate++;
                }else if(element.state == 3){
                    falseLawsuits++;
                }else if(element.state == 4){
                    trueLawsuits++;
                }
                if(element.error =="true"){
                    flagErrors=false
                }
            });
    
    
    
            // //console.log("in Process"+inProcess);
            this.setState({
                data:res.data[0].data,
                trueLawsuits:trueLawsuits,
                falseLawsuits:falseLawsuits,
                inProcess:inProcess,
                inDebate:inDebate,
                flagErrors:flagErrors,
                flagEditLawsuit:true
            });
    
        }else{
            this.setState({
                flagEditLawsuit:false,
            });
        }



     } 


    // a and b are javascript Date objects
    dateDiffInDays(a, b) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    
      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    getUrlPolitician(){
        var url=window.location.href;
        var arrayurl=url.split("/");
        if(arrayurl.length==4){
            var urlUser=arrayurl[3];
            // console.log(urlUser);
           return urlUser;
        }
        if(arrayurl.length==5){
            var urlUser=arrayurl[4];
            return urlUser;
        }
    }

    readCookie=(name)=> {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    getPoliticianDetails=async(query)=>{

        const res = await axios.get('https://politicosperu.pe/php/getPoliticianDetails.php?idUrl='+query);
        // console.log("get politicianDetails"+res.data);
        var idPolitician='';
        var name='';
        var surnameFather='';
        var surnameMother='';
        var actualWork='';
        var maxGrade='';
        var linkCv='';
        var linkPlan='';
        var linkTeam='';
        var income='';
        var politicalParty='';
        var imgProfile='';
        var imgBanner='';
        var regionDescription='';
        var provinceDescription='';
        var districtDescription='';
        var regionIdRegion='';
        var provinceIdProvince='';
        var districtIdDistrict='';
        var birthDate="";
        var age='';
        res.data.map(function(element){
            idPolitician=element.idPolitician;
            name=element.name;
            surnameFather= element.surnameFather;
            surnameMother= element.surnameMother;
            actualWork= element.actualWork;
            maxGrade= element.maxGrade;
            linkCv= element.linkCv;
            linkPlan= element.linkPlan;
            linkTeam= element.linkTeam;
            income= element.income;
            politicalParty= element.politicalParty;
            imgProfile= element.imgProfile;
            imgBanner= element.imgBanner;
            regionDescription= element.regionDescription;
            provinceDescription= element.provinceDescription;
            districtDescription= element.districtDescription;
            regionIdRegion= element.region_idRegion;
            provinceIdProvince= element.province_idProvince;
            districtIdDistrict= element.district_idDistrict;
            birthDate= element.birthDate;
            age= element.birthDate;
        });

        age= this.getAge(age);
        this.setState({
            flagValid:true,
            idPolitician:idPolitician,
            name:name,
            surnameFather: surnameFather,
            surnameMother: surnameMother,
            actualWork: actualWork,
            maxGrade: maxGrade,
            linkCv: linkCv,
            linkPlan: linkPlan,
            linkTeam: linkTeam,
            income: income,
            politicalParty: politicalParty,
            imgProfile: imgProfile,
            imgBanner: imgBanner,
            regionDescription: regionDescription,
            provinceDescription: provinceDescription,
            districtDescription: districtDescription,
            regionIdRegion: regionIdRegion,
            provinceIdProvince: provinceIdProvince,
            districtIdDistrict: districtIdDistrict,
            birthDate: birthDate,
            age:age
        });

    }


    
    handleOpenModal=()=>{
        this.setState(
            {
                flagModal:true
            }
        );
    }
    handleChangeImage=(e)=>{
        // console.log("here changing the image");
        // console.log(e.target);
        if(e.target.id=="profileImg"){
            // console.log("here I am it's true");
            this.setState(
                {
                    flagProfile:true,
                }
            );
        }else{
            // console.log("here I am it's false");

            this.setState(
                {
                    flagBanner:true,
                }
            );
        }
    }
    handleChangeFile=(e)=>{
        // console.log("changing file");
        // console.log(e.target);
        if(e.target.id=="cv"){
            this.setState(
                {
                    flagCv:true,
                }
            );
        }else if(e.target.id=="plan"){

            this.setState(
                {
                    flagPlan:true,
                }
            );
        }else if(e.target.id =="team"){
            this.setState(
                {
                    flagTeam:true,
                }
            );
        }
    }

    handleWtf=(e)=>{
        // console.log("wtf");
    }
    handleCancelModal=(e)=>{
        // e.stopPropagation();
        this.setState(
            {
                flagModal:false
            }
        );
    }
    handleCancelImageModal=(e)=>{
        // e.stopPropagation();
        this.setState(
            {
                flagImage:false,
                flagProfile:false,
                flagBanner:false,
            }
        );
    }
    handleCancelFileModal=(e)=>{
        // e.stopPropagation();
        this.setState(
            {
                flagCv:false,
                flagPlan:false,
                flagTeam:false,
            }
        );
    }

    updateDataProfile=(name,surnameFather, surnameMother,actualWork,income,politicalParty,maxGrade,birthDate,regionDescription, provinceDescription, districtDescription, regionIdRegion, provinceIdProvince, districtIdDistrict)=>{
        
        var age= this.getAge(birthDate);

        this.setState(
            {
                name:name,
                surnameFather:surnameFather,
                surnameMother:surnameMother,
                actualWork:actualWork,
                income:income,
                politicalParty:politicalParty,
                maxGrade:maxGrade,
                birthDate:birthDate,
                age:age,
                regionDescription: regionDescription,
                provinceDescription: provinceDescription,
                districtDescription: districtDescription,
                regionIdRegion: regionIdRegion,
                provinceIdProvince: provinceIdProvince,
                districtIdDistrict: districtIdDistrict,

            }
        );
    }
    handleConfirmModal(){

    }


    validateOpenSesion=async()=>{
        // console.log("###antes de validar"+this.state.cardValidation.tokenDescription);
        axios.post('https://politicosperu.pe/php/validateSesion.php',JSON.stringify(this.state.cardValidation))
        .then(response =>{
            // console.log(response);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    // console.log("#### valida la sesión");
                    this.getPoliticianDetails(this.getUrlPolitician());
            }else{
                // console.log("#### inValida"+arrayResponse[0].urlUser+"-- token: "+arrayResponse[0].token);
                this.setState({
                    flagValid:false
                });
            }
    
        }).catch(error=>{
            this.setState({
                flagValid:false
            });
        });

    }

    componentDidMount(){
        this.validateOpenSesion();
        
    }

    render(){
        if(!this.state.flagValid){
            return(
                <div className="userProfileFail"> sesión invalida</div>
            );
        }else{
            return(
                <div className="userProfileContainer">
                    <div className="userProfileWrapper">

                    
    
                    {this.state.flagModal &&   <ProfileDetailsModal
                        messageHeader='Editar Datos Del Perfil'
                        handleCancelModal={this.handleCancelModal}
                        handleConfirmModal={this.handleConfirmModal}
                        handleChangeImage={this.handleChangeImage}
                        updateDataProfile={this.updateDataProfile}
                        flagModal={this.state.flagModal}
                        idPolitician={this.state.idPolitician}
                        name={this.state.name}
                        surnameFather={this.state.surnameFather}
                        surnameMother={this.state.surnameMother}
                        actualWork={this.state.actualWork}
                        maxGrade={this.state.maxGrade}
                        linkCv={this.state.linkCv}
                        income={this.state.income}
                        politicalParty={this.state.politicalParty}
                        imgProfile={this.state.imgProfile}
                        imgBanner={this.state.imgBanner}
                        regionDescription={this.state.regionDescription}
                        provinceDescription={this.state.provinceDescription}
                        districtDescription={this.state.districtDescription}
                        trueLawsuits={this.state.trueLawsuits}
                        falseLawsuits={this.state.falseLawsuits}
                        inProcess={this.state.inProcess}
                        inDebate={this.state.inDebate}
                        birthDate={this.state.birthDate}
                        regionIdRegion={this.state.regionIdRegion}
                        provinceIdProvince={this.state.provinceIdProvince}
                        districtIdDistrict={this.state.districtIdDistrict}
                    /> }
                    {this.state.flagProfile && <ProfilePhotoModal
                    imageModal= "true" 
                    updateImgProfile={this.updateImgProfile}
                    messageHeader='Cambiar Imagen Del Perfil'
                    flagProfile={this.state.flagProfile}
                    flagBanner={this.state.flagBanner}
                    idPolitician={this.state.idPolitician}
                    handleCancelImageModal={this.handleCancelImageModal}
                    />}
                    {this.state.flagBanner && <ProfilePhotoModal
                    imageModal="false" 
                    updateImgBanner={this.updateImgBanner}
                    messageHeader='Cambiar Imagen Del Banner'
                    flagBanner={this.state.flagBanner}
                    flagProfile={this.state.flagProfile}
                    idPolitician={this.state.idPolitician}
                    handleCancelImageModal={this.handleCancelImageModal}
                    />}
                    {this.state.flagCv && <ProfileFileModal 
                    messageHeader='Cambiar Curriculum Vitae'
                    flagCv={this.state.flagCv}
                    flagPlan={this.state.flagPlan}
                    flagTeam={this.state.flagTeam}
                    idPolitician={this.state.idPolitician}
                    handleCancelFileModal={this.handleCancelFileModal}
                    />}
                    {this.state.flagPlan && <ProfileFileModal
                    messageHeader='Cambiar Plan de Gobierno' 
                    flagPlan={this.state.flagPlan}
                    flagTeam={this.state.flagTeam}
                    idPolitician={this.state.idPolitician}
                    handleCancelFileModal={this.handleCancelFileModal}
                    />}
                    {this.state.flagTeam && <ProfileFileModal
                    messageHeader='Cambiar Equipo Técnico'  
                    flagTeam={this.state.flagTeam}
                    flagPlan={this.state.flagPlan}
                    idPolitician={this.state.idPolitician}
                    handleCancelFileModal={this.handleCancelFileModal}
                    />}
                <div className="containerUserProfileHead">
                    <ProfileDetailsEdit
                        name={this.state.name}
                        surnameFather={this.state.surnameFather}
                        surnameMother={this.state.surnameMother}
                        actualWork={this.state.actualWork}
                        maxGrade={this.state.maxGrade}
                        linkCv={this.state.linkCv}
                        income={this.state.income}
                        politicalParty={this.state.politicalParty}
                        imgProfile={this.state.imgProfile}
                        imgBanner={this.state.imgBanner}
                        regionDescription={this.state.regionDescription}
                        provinceDescription={this.state.provinceDescription}
                        districtDescription={this.state.districtDescription}
                        trueLawsuits={this.state.trueLawsuits}
                        falseLawsuits={this.state.falseLawsuits}
                        inProcess={this.state.inProcess}
                        inDebate={this.state.inDebate}
                        age={this.state.age}
                        handleOpenModal={this.handleOpenModal}
                        handleChangeImage={this.handleChangeImage}
                        handleChangeFile={this.handleChangeFile}
                        />

                     <SocialnetworksEdit 
                       idPolitician={this.state.idPolitician}
                       token={this.state.cardValidation.tokenDescription}
                       />
    
                    <HeroProfileEdit
                        linkPlan={this.state.linkPlan}
                        linkTeam={this.state.linkTeam}
                        imgBanner={this.state.imgBanner}
                        handleChangeImage={this.handleChangeImage}
                        handleChangeFile={this.handleChangeFile}
                        handleWtf={this.wtf}
                        />

                    </div>
                    
                    <Residence
                     idPolitician={this.state.idPolitician}
                     token={this.state.cardValidation.tokenDescription}
                     /> 

                    <MyStadistics 
                       idPolitician={this.state.idPolitician}
                       />
                    <ActivitiesEdit 
                       idPolitician={this.state.idPolitician}
                       token={this.state.cardValidation.tokenDescription}
                       />
                    <Prospects
                        idPolitician={this.readCookie("idPolitician")}
                        token={this.readCookie("tokenDescription")}
                    />
                    <LawsuitsListPoliticianEdit
                        handleListLawsuit={this.getListLawsuit}
                        data={this.state.data}
                        flagErrors={this.state.flagErrors}
                        flagEditLawsuit={this.state.flagEditLawsuit}
                        />
                        </div>
                    <Footer/>

                </div>
            );
        }

    }


}

export default UserProfile;