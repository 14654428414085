import React from "react";
import "./css/ConfirmPolitician.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import GeneralModal from "../modals/GeneralModal";

class ConfirmPolitician extends React.Component{
    constructor(props){
        super(props);
        this.state={
            flagErrorServer:false,
            url:"",
            attemp: false,
            validUrl:false,

            attemps:0,
            flagVerified:false,
            flagPhone:false,
            flagCodeSent:false,
            phone:"",
            flagVerifiedCodeError:false,
            code:"",

        }
    
    }

    confirm=async()=>{
        axios.post('https://politicosperu.pe/php/confirmPolitician.php',
        JSON.stringify({
            url: this.state.url
       }))
       .then(response =>{
           //console.log(response.data);
           var arrayResponse= new Array(response.data[0]);
   
           if(arrayResponse[0].status == "success"){  
                   //console.log("#### el link aun es valido");
                   this.setState({
                       flagErrorServer:false,
                       attemp:true,
                       attemps: arrayResponse[0].attemps,
                       validUrl:true,
                   });
                   // this.getPoliticianDetails(this.getUrlPolitician());
           }else{
               //console.log("#### link no valido");
               this.setState({
                   flagErrorServer:true,
                   attemp:true,
               });
           }
   
       }).catch(error=>{
           //console.log("#### error");


           this.setState({
               flagErrorServer:true,
                attemp:true,
           });
       });

    }


    componentDidMount(){

        var finalUrl= window.location.href.split("/");

        this.setState({
            url: finalUrl[4]
        });
    }

    componentDidUpdate(){
        if(this.state.attemp == false && this.state.url !=""){
            this.confirm();
        }
    }

    handlePhone=(e)=>{
        //console.log("calling onchange"+e.target.value);
        var pattern = new RegExp("^\\d{9}$");
            this.setState({
                [e.target.name]: e.target.value,
                flagValidNumber:(e.target.value.match(pattern)== null) ? false : true
               }); 

    }
    handleSendPhone=()=>{
        if(this.state.flagValidNumber){
            //console.log("###antes de validar");
            axios.post('https://politicosperu.pe/php/updatePhonePolitician.php',JSON.stringify({
                phone: this.state.phone, 
                token:this.state.url
            }))
            .then(response =>{
                //console.log("############# attemps");
                //console.log(response);
                var arrayResponse= new Array(response.data[0]);
        
                if(arrayResponse[0].status == "success"){  
                        //console.log("#### Codigo enviado"+arrayResponse[0].idCitizen);
                        this.setState({
                            ...this.state,
                            flagCodeSent:true,
                            attemps:arrayResponse[0].attemps
                        });
    
                }else{
                    // //console.log("#### citizen inValida"+arrayResponse[0].urlUser+"-- token: "+arrayResponse[0].token);
                    //console.log("no se pudo cambiar el phone");
    
                    this.setState({
                        flagErrorCodeSent:false
                    });
                }
        
            }).catch(error=>{
                this.setState({
                    flagErrorCodeSent:false
                });
            });
        }else{
            alert("Ingrese un número valido");
        }


    }
    handleCode=(e)=>{
        this.setState({
            [e.target.name]: e.target.value,
           }); 
    }
    handleVerifyPhoneCode=(e)=>{
        axios.post('https://politicosperu.pe/php/verifyPhoneCode2.php',JSON.stringify({
            token : this.state.url, 
            code :  this.state.code}))
        .then(response =>{
            //console.log(response);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  

                window.setTimeout(()=>{this.goHome()},4500);
                  
                this.setState({
                    flagVerified:true,
                });

            }else{
                // //console.log("#### citizen inValida"+arrayResponse[0].urlUser+"-- token: "+arrayResponse[0].token);
                //console.log("#### no se pudo verificar");
                this.setState({
                    flagVerifiedCodeError:true
                });

            }
    
        }).catch(error=>{

        });
    

    }
    goHome=()=>{
        const { navigation } = this.props;
  
        navigation('/');
    }

    handleTryAgain=()=>{
        if(this.state.attemps ==3){
            this.setState({
                ...this.state,
                flagVerifiedCodeError:false,
                flagPhone:false,
                flagCodeSent:false,
                phone:'',
                code:'',
                attemps:4
            });
        }else{
            this.setState({
                ...this.state,
                flagVerifiedCodeError:false,
                flagPhone:false,
                flagCodeSent:false,
                phone:'',
                code:'',
            });
        }
    }
    
    render(){
        return(
            <div className="ConfirmPoliticianContainer">
                {this.state.flagVerified &&
                <GeneralModal
                    header="Registro Exitoso"
                    message="Felicitaciones, ya puedes iniciar sesión"
                    buttonText="Continuar"
                    handleCloseModal={this.goHome}
                    handleOnClickMethod={this.goHome}
                />
                }
                <div className="ConfirmPoliticianWrapper">
                    {this.state.validUrl && 
                        <div className="citizenProfile__top">
                        <div className="citizenProfile__topLeft">
                            <h1 className="citizenProfile__topTitle">Bienvenido!</h1>
                            <p className="citizenProfile__topMessage">
                                Gracias por querer mejorar nuestro país <span className="citizenProfile__topMessage--red"> Vales un Perú</span>
                            </p>
                        </div>
                        <div className="citizenProfile__topRight">
                        {(!this.state.flagVerified) ? ((this.state.attemps - 3) <= 0)? <div className="citizenProfile__topbox">
                                {(!this.state.flagPhone && !this.state.flagCodeSent ) &&
                                <input onChange={this.handlePhone} value={this.state.phone} name="phone" className="citizenProfile__topInput" type="text"  placeholder="Celular"/>
                                }
                                {(!this.state.flagPhone && !this.state.flagCodeSent) && <button onClick={this.handleSendPhone} className="citizenProfile__topButton">Verificar</button>}
                                
                                
                                {(!this.state.flagPhone && this.state.flagCodeSent && !this.state.flagVerifiedCodeError ) &&
                                <input onChange={this.handleCode} value={this.state.code} name="code" className="citizenProfile__topInput" type="text"  placeholder="Codigo"/>
                                }
                                {(!this.state.flagPhone && this.state.flagCodeSent && !this.state.flagVerifiedCodeError ) && <button onClick={this.handleVerifyPhoneCode} className="citizenProfile__topButton">Validar</button>}
                                {(this.state.flagVerifiedCodeError && this.state.attemps >0 ) && <span className="citizenProfile__errorAttemps">Codigo Incorrecto <br/> restan {3 - this.state.attemps} Intentos</span>}
                                {(!this.state.flagPhone && this.state.flagCodeSent && this.state.flagVerifiedCodeError ) && <button onClick={this.handleTryAgain} className="citizenProfile__topButton">Reintentar</button>}

                            </div> 
                            :
                            <div className="citizenProfile__topbox">
                    
                            <input  name="phone" className="citizenProfile__topInput" type="text"  placeholder="Celular"/>
                            <button className="citizenProfile__topButton">bloqueado</button>
                            </div>: <div className="citizenProfile__topbox"> <h3 className="citizenProfile__topText">Conoce a los políticos</h3>
                                    <Link to="/politicians"> <button className="citizenProfile__topButton">Buscar</button></Link></div>
                        
                        } 
        
                        </div>
                        </div>

                    }
                    {/* <div className="ConfirmPolitician__header">
                       {(this.state.attemp == true && this.state.flagErrorServer ==false) && 
                       <h3 className="ConfirmPolitician__Title">Tu cuenta ha sido Confirmada</h3> } 
                       {(this.state.attemp == true && this.state.flagErrorServer ==false) && 
                       <div className="ConfirmPolitician__body">
                       <Link to="/" className="ConfirmPolitician__button">Continuar</Link>
                       </div>
                       } 
                    </div> */}
                    {(this.state.validUrl && !this.state.flagVerified) && 
                    <div className="citizenProfile__middle">
                        <div className="citizenProfile__middleLeft">
                            La transparencia es nuestro pilar
                        </div>
                        {this.state.attemps - 3 <= 0 ?<div className="citizenProfile__middleRight">
                            {!this.state.flagPhone && <h2 className="citizenProfile__middleMessage">Tienes 24 Horas para verificar tu cuenta</h2>}
                            {this.state.flagPhone && <h2 className="citizenProfile__middleMessage">Tu cuenta será verificada a la brevedad</h2>}
                            {!this.state.flagPhone &&<span className="citizenProfile__middleSubText">Solo Celulares Nacionales <br/> Omitir (+51) </span>}
                        </div> : <div className="citizenProfile__middleRight">
                            {<h2 className="citizenProfile__middleMessage">Intentalo nuevamente más tarde</h2>}
                        </div>  }
                        
                    </div>
                    }   
                    
                </div>
                
            </div>
        );
    }
}
export default function(props){
    const navigation = useNavigate();
    return  <ConfirmPolitician {...props} navigation={navigation}/>

} 