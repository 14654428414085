import React from "react";
import "./css/ConfirmChangePassword.css";
import { useNavigate } from "react-router-dom";
import GeneralModal from "../modals/GeneralModal";
import axios from "axios";

class ConfirmChangePassword extends React.Component{
    constructor(props){
        super(props);
        this.state={
            flagErrorServer:false,
            flagErrorPassword:false,
            flagErrorValidPassword: false,
            password1:"",
            password2:"",
            url:"",
            flagSuccess: false,
            flagSuccessChange: false,
        }
    
    }

    confirm=async()=>{
                 axios.post('https://politicosperu.pe/php/confirmChangePassword.php',
            JSON.stringify({
            url: this.state.url,
            password1: this.state.password1,
            password2: this.state.password2
        }))
       .then(response =>{
           //console.log(response.data);
           var arrayResponse= new Array(response.data[0]);
   
           if(arrayResponse[0].status == "success"){  
                   //console.log("#### valido");
                   this.setState({
                       flagErrorServer:false,
                       flagSuccess:true,
                       password1: '',
                       password2:'',
                   });
                   // this.getPoliticianDetails(this.getUrlPolitician());
           }else{
               //console.log("#### invalido");
               this.setState({
                   flagErrorServer:true,
                   flagSuccess:false,
               });
           }
   
       }).catch(error=>{
           //console.log("#### error");


           this.setState({
               flagErrorServer:true,
               flagSuccess:false,
            });
        });


    }


    hideMessages=()=>{
        window.setTimeout(()=>{
            this.setState({
                flagErrorPassword:false,
                flagErrorValidPassword:false,
                flagErrorServer:false,
            })
        },4000);
    }
    hideSuccess=()=>{
        window.setTimeout(()=>{
            this.setState({
                flagSuccess:false,
            })
        },4000);
    }

    handleChangePassword=()=>{
     if(this.state.password1 == this.state.password2){
        if(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(this.state.password1)){
            axios.post('https://politicosperu.pe/php/recoverChangePassword.php',
            JSON.stringify({
            url: this.state.url,
            password1: this.state.password1,
            password2: this.state.password2
        }))
       .then(response =>{
           //console.log(response.data);
           var arrayResponse= new Array(response.data[0]);
   
           if(arrayResponse[0].status == "success"){  
                   //console.log("#### valido");
                   this.hideMessages();
                   this.setState({
                       flagSuccessChange:true,
                       password1: '',
                       password2: '',
                   });
                   // this.getPoliticianDetails(this.getUrlPolitician());
           }else{

            //console.log("#### invalido");

            this.setState({
                   flagErrorServer:true,
               });
           }
   
       }).catch(error=>{
           //console.log("#### error");

           this.setState({
               flagErrorServer:true,
            });
        });
        }else{
            this.hideMessages();
            this.setState({
                flagErrorValidPassword:true,
             });
        }

    }else{
        this.hideMessages();
        this.setState({
            flagErrorPassword: true,
        });
    }

    }

    handleOnchange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    goHome=()=>{
        const { navigation } = this.props;
  
        navigation('/');
    }
    
    componentDidMount(){

        var finalUrl= window.location.href.split("/");

        this.setState({
            url: finalUrl[4]
        });
    }

    componentDidUpdate(){
        if(this.state.flagSuccess == false && this.state.url !=""){
            this.confirm();
        }
    }

    render(){
        return(
            <div className="ConfirmChangePasswordContainer">
                <img className="ConfirmChangePasswordBackground" src="https://politicosperu.pe/images/bgRecover.png" alt="" />
                {this.state.flagSuccessChange && 
                <GeneralModal
                header="Cambio Exitoso"
                message="El cambio se realizó con éxito"
                buttonText="Continuar"
                handleCloseModal={this.goHome}
                handleOnClickMethod={this.goHome}
                />}
                
                <div className="ConfirmChangePasswordWrapper">
                    <div className="ConfirmChangePassword__header">
                        {this.state.flagErrorPassword && <div className="ConfirmChangePassword__errorMessage">Las claves no coinciden</div>}
                        {this.state.flagErrorValidPassword && <div className="ConfirmChangePassword__errorMessage">Error Clave, Ej: 6&a123</div>}
                       {(this.state.flagSuccess == true && this.state.flagErrorServer ==false) && 
                       <h3 className="ConfirmChangePassword__Title">Ingresa tu nueva clave</h3> } 
                       {(this.state.flagSuccess == true && this.state.flagErrorServer ==false) && 
                       <div className="ConfirmChangePassword__body">
                            <input placeholder="Nueva Clave" className="ConfirmChangePassword__input" onChange={this.handleOnchange} type="password" value={this.state.password1} name="password1" />
                            <input placeholder="Verificar Clave" className="ConfirmChangePassword__input" onChange={this.handleOnchange} type="password" value={this.state.password2} name="password2" />
                            <button onClick={this.handleChangePassword} className="ConfirmChangePassword__button">Confirmar</button>
                       </div>
                       } 
                    </div>

                </div>
                
            </div>
        );
    }
}
export default function(props){
    const navigation = useNavigate();
    return <ConfirmChangePassword {...props} navigation={navigation}/>

} 