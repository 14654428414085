import "./styles/profileDetailsModal.css";
import React from "react";
import axios from "axios";
import FileUploader from "../components/FileUploader";

class ProfileDetailsModal extends React.Component{
    constructor(props){
        super(props);
        var arrayDate= this.getPartsBirthDate(this.props.birthDate);
        var validDate= false;
        if(Date.parse(this.props.birthDate) || this.props.birthDate == ""){
            validDate= true;
        }else{
            validDate= false;
        }

        this.state={
            regions:[],
            provinces:[],
            districts:[],
            regionShow:false,
            provinceShow:false,
            districtShow:false,
            showErrorsFlag:false,
            regionFlag: true,
            provinceFlag:true,
            districtFlag:true,
            flagValidName: false,
            flagValidSurnameFather: false,
            flagValidSurnameMother: false,
            flagValidBirthDay: validDate,
            flagValidActualWork: false,
            flagValidMaxGrade: false,
            flagValidIncome: false,
            flagValidPoliticalParty:false,
            regionSearch:this.props.regionDescription,
            provinceSearch:this.props.provinceDescription,
            districtSearch:this.props.districtDescription,
            regionId:'',
            provinceId:'',
            districtId:'',
            data:[],
            idPolitician:this.props.idPolitician,
            name:this.props.name,
            surnameFather:this.props.surnameFather,
            surnameMother:this.props.surnameMother,
            birthDate:this.props.birthDate,
            month:arrayDate[2],
            day:arrayDate[1],
            year:arrayDate[0],
            regionDescription:this.props.regionDescription,
            provinceDescription:this.props.provinceDescription,
            districtDescription:this.props.districtDescription,
            actualWork:this.props.actualWork,
            maxGrade:this.props.maxGrade,
            income:this.props.income,
            politicalParty:this.props.politicalParty,
        }
    }


    getPartsBirthDate(date){
        var arrayDate=date.split("-");
        return arrayDate;
    }
    getRegions=async()=>{
        const res = await axios.get('https://politicosperu.pe/php/listRegions.php');
        // console.log("region: "+res.data);
        
        
        if(this.props.provinceIdProvince!="" && this.props.regionIdRegion!="" ){
            const provincesResponse = await axios.get('https://politicosperu.pe/php/listProvinces.php?region='+this.props.regionIdRegion);
            const districtsResponse = await axios.get('https://politicosperu.pe/php/listDistricts.php?province='+this.props.provinceIdProvince);

            this.setState({
                districts:districtsResponse.data,
                provinces:provincesResponse.data,
                regions:res.data,
            });
        }else{
            this.setState({
                regions:res.data
            });
        }

    }
    getProvinces=async(query)=>{
        const res = await axios.get('https://politicosperu.pe/php/listProvinces.php?region='+query);
        // console.log("provinces: "+res.data);

        this.setState({
            provinces:res.data
        });
    }
    getDistricts=async(query)=>{
        const res = await axios.get('https://politicosperu.pe/php/listDistricts.php?province='+query);
        // console.log("districts: "+res.data);

        this.setState({
            districts:res.data
        });
    }
    
    showErrorMessages =()=>{
        window.setTimeout(()=>{
            this.setState({
                showErrorsFlag: false,
            });
        }, 1000);
        this.setState({
            showErrorsFlag:true,
        });
    }

    verifyDate=()=>{
        if(Date.parse(this.state.year+"-"+this.state.month+"-"+this.state.day) && this.state.year > 1923 && this.state.month > 0 && this.state.day >0){
            this.setState({
                flagValidBirthDay: true,
            });
        }else{
            this.setState({
                flagValidBirthDay: false,
            });        }
    }

    postData=async()=>{

        if(!this.state.regionFlag || !this.state.provinceFlag || !this.state.districtFlag || !this.state.flagValidBirthDay ){
            this.showErrorMessages();
        }else{

            axios.post('https://politicosperu.pe/php/updateDetails.php',JSON.stringify({
                idPolitician: this.props.idPolitician,
                name: this.state.name,
                surnameFather: this.state.surnameFather,
                surnameMother: this.state.surnameMother,
                birthDate: this.state.birthDate,
                idRegion: this.state.regionId,
                idProvince: this.state.provinceId,
                idDistrict: this.state.districtId,
                actualWork: this.state.actualWork,
                maxGrade: this.state.maxGrade,
                income: this.state.income,
                politicalParty: this.state.politicalParty,
                regionDescription: this.state.regionDescription,
                provinceDescription: this.state.provinceDescription,
                districtDescription: this.state.districtDescription
    
            }));
    
            this.props.updateDataProfile(this.state.name,this.state.surnameFather,this.state.surnameMother,this.state.actualWork,this.state.income,this.state.politicalParty,this.state.maxGrade,this.state.year+"-"+this.state.month+"-"+this.state.day, this.state.regionDescription, this.state.provinceDescription, this.state.districtDescription, this.state.regionId, this.state.provinceId, this.state.districtId);
            
            
            this.props.handleCancelModal();
        }
        // console.log("postData");
        // this.setState({
        //     districts:res.data
        // });
    }
    // checkForm=e=>{
    //     const recaptchaValue = recaptchaRef.current.getValue();
    //       if(!recaptchaValue){
    //         alert("Por favor, Valida que no eres un robot");
    //       }else{
    //         if(!this.props.dataForm.validParam){
    //             alert("por favor ingresa datos validos");
    //           }else{
    //               if(!(this.props.dataForm.stepOne && this.props.dataForm.stepTwo && this.props.dataForm.stepThree)){
    //                   alert("por favor rellena los campos faltantes");
    //               }else{
    // //                   console.log(this.props.dataForm);
    // //                   console.log("registrar denuncia");
    //                   axios.post('https://innovastorga.com/registerLawsuit.php',JSON.stringify(this.props.dataForm))
    //                   .then(response =>{
    // //                       console.log(response);
    //                   }).catch(error=>{
    // //                       console.log(error);
    // //                       console.log("un pinche error");
                    
    //                   });
    //               }
    //           }
    //     }
     
    //     }

    handleOnFocusOut(e){
        
        setTimeout(function() {
            var recomendationRegions=document.getElementsByClassName('profileDetailsModal__'+e.target.name+'RecomendationGroup');
            recomendationRegions.item(0).style.display="none";
          }, 300);

        //   this.setState({
        //     [e.target.name]:""
        //   });


    }
    handleVisibleRecomendation(e){
        var searchWord=e.target.value;

        var RecomendationRegions=document.getElementsByClassName('profileDetailsModal__'+e.target.name+'RecomendationGroup');

        if(searchWord.length <1){
            RecomendationRegions.item(0).style.display="none";
        }else{
            RecomendationRegions.item(0).style.display="block";
        }
    }
    handleSelectSuggestion=(e)=>{
        // console.log("handle suggestion"+e.target.getAttribute('data-suggestion'));

        if(e.target.getAttribute('data-suggestion') =="" || e.target.getAttribute('data-suggestion') == undefined){

        }else{
            var group=e.target.parentNode;
            group.style.display="none";
            // console.log("### target id"+e.target.id);
            if(e.target.id =="region"){
                this.getProvinces(e.target.getAttribute('data-idsuggestion'));
                // console.log("region"+e.target.getAttribute('data-suggestion'));
                this.setState({
                    // ...this.setState,
                    regionSearch: e.target.getAttribute('data-suggestion'),
                    regionFlag:true,
                    regionDescription: e.target.getAttribute('data-suggestion'), 
                    provinceFlag:false,
                    provinceSearch:'',
                    provinceDescription:'',
                    districtFlag:false,
                    districtSearch:'',
                    districtDescription:'',
                    regionId:e.target.getAttribute('data-idsuggestion')
                });
            }else if(e.target.id =="province"){
                this.getDistricts(e.target.getAttribute('data-idsuggestion'));
                // console.log("province chaja chaja"+e.target.getAttribute('data-suggestion'));
                this.setState({
                    // ...this.setState,
                    provinceSearch: e.target.getAttribute('data-suggestion'),
                    provinceDescription: e.target.getAttribute('data-suggestion'),
                    provinceFlag:true,
                    districtFlag:false,
                    districtSearch:'',
                    districtDescription:'',
                    provinceId:e.target.getAttribute('data-idsuggestion')
                });
            }else if(e.target.id =="district"){
                // console.log("district chaja chaja"+e.target.getAttribute('data-idsuggestion'));
                // console.log("###### district is: "+this.state.districtId+" on HandleClick");

                this.setState({
                    // ...this.setState,
                    districtSearch: e.target.getAttribute('data-suggestion'),
                    districtDescription: e.target.getAttribute('data-suggestion'),
                    districtFlag:true,
                    districtId:e.target.getAttribute('data-idsuggestion')
                });
            }


        }
    }

    handleOnChange=(e)=>{
        // console.log(e);
        if(e.target.name=="day"){
            this.setState({
                birthDate: this.state.year+'-'+this.state.month+'-'+e.target.value,
                [e.target.name]:e.target.value
    
            });
        }else if(e.target.name=="month"){
            this.setState({
                birthDate: this.state.year+'-'+e.target.value+'-'+this.state.day,
                [e.target.name]:e.target.value
    
            });

        }else if(e.target.name=="year"){
            this.setState({
                birthDate: e.target.value+'-'+this.state.month+'-'+this.state.day,
                [e.target.name]:e.target.value
    
            });
        }else if(e.target.name == "income"){
            if(!isNaN(e.target.value)){
            this.setState({
                [e.target.name]:e.target.value    
            });
            }
        }
        // else if(e.target.name == "surnameFather" || e.target.name == "surnameMother" ){
        //     if(!(/\s/.test(e.target.value))){
        //         this.setState({
        //             [e.target.name]:e.target.value
        //         });
        //     }

        // }
        
        
        else{
            this.setState({
                [e.target.name]:e.target.value
    
            });
        }


 
    }

    verifyFlags(){
        if(this.state.regionFlag && 
            this.state.provinceFlag && 
            this.state.districtFlag &&
            this.state.flagValidName &&
            this.state.flagValidSurnameFather &&
            this.state.flagValidSurnameMother &&
            this.state.flagValidBirthDay &&
            this.state.flagValidActualWork &&
            this.state.flagValidMaxGrade &&
            this.state.flagValidIncome &&
            this.state.flagValidPoliticalParty){
            return true;
        }else{

            return false;
        }
    }

    handleConfirmChanges=(e)=>{
        if(this.verifyFlags){

        }else{

        }
    }

    handleSearchOnChange=(e)=>{
        this.handleVisibleRecomendation(e);
        // console.log("handle searchOnChange"+e.target.name);
        // console.log("handle searchOnChange"+e.target.value);

        if(e.target.id =="regionFirstChange"){
            this.setState(
                {
                    [e.target.name+'Search']: e.target.value,
                    [e.target.name+'Flag']:false,
                    [e.target.name+'Show']:true,
                    provinceFlag:false,
                    provinceSearch:'',
                    districtFlag:false,
                    districtSearch:'',
                }
            );

        }else if(e.target.id =="provinceFirstChange"){
            this.setState(
                {
                    [e.target.name+'Search']: e.target.value,
                    [e.target.name+'Flag']:false,
                    [e.target.name+'Show']:true,
                    districtFlag:false,
                    districtSearch:'',
                }
            );

        }else if(e.target.id =="districtFirstChange"){
            this.setState(
                {
                    [e.target.name+'Search']: e.target.value,
                    [e.target.name+'Flag']:false,
                    [e.target.name+'Show']:true,
                    districtFlag:false,
                }
            );

        }else{
            this.setState(
                {
                    [e.target.name+'Search']: e.target.value,
                    [e.target.name+'Flag']:false,
                    [e.target.name+'Show']:true,
                }
            );
        }

       
    }    

    // handleDay= ()=>{
    //     if(this.state.day == "" || this.verifyDate()){
    //         this.setState({
    //             day: '01'
    //         });
    //     }

    // }
    // handleMonth= ()=>{
    //     if(this.state.month == ""){
    //         this.setState({
    //             month: '01'
    //         });
    //     }

    // }
    // handleYear= ()=>{
    //     if(this.state.year == ""){
    //         this.setState({
    //             year: '2000'
    //         });
    //     }

    // }

    componentDidMount(){
        // console.log("this is flagModal"+this.props.flagModal);
        // console.log("this is props district"+this.props.districtDescription);
        this.getRegions();

    }
    componentWillUnmount(){
        // console.log("did unmount");
    }
    render(){
        if(this.props.flagModal){
            return(
                <div onClick={this.props.handleCancelModal} className="profileDetailsModalWrapper">
                <div onClick={(e) => e.stopPropagation()} className="profileDetailsModalContainer">
                    <div className="profileDetailsModal__header">
                    {this.props.messageHeader}
                    </div>
                    <div className="profileDetailsModal__body">
                        <div className="profileDetailsModal__item">
                        <label className="profileDetailsModal__label" htmlFor="">Nombres:</label>
                        <input maxLength="150" onChange={this.handleOnChange} className="profileDetailsModal__input" type="text" name="name" id="" value={this.state.name} placeholder="Nombres"/>
                        </div>
                        <div className="profileDetailsModal__item">
                        <label className="profileDetailsModal__label" htmlFor="">Apellido Paterno:</label>
                        <input maxLength="150" onChange={this.handleOnChange} className="profileDetailsModal__input" type="text" name="surnameFather" id="" value={this.state.surnameFather} placeholder="Apellido Paterno"/>
                        </div>
                        <div className="profileDetailsModal__item">
                        <label className="profileDetailsModal__label" htmlFor="">Apellido Materno:</label>
                        <input maxLength="150" onChange={this.handleOnChange} className="profileDetailsModal__input" type="text" name="surnameMother" id="" value={this.state.surnameMother} placeholder="Apellido Materno"/>
                        </div>
                        <div className="profileDetailsModal__item">
                        <label className="profileDetailsModal__label" htmlFor="">Fecha de Nacimiento:{(this.state.showErrorsFlag && !this.state.flagValidBirthDay) && <span className="profileDetailsModal__error">*</span> }</label>
                        {/* <input onChange={this.handleOnChange} className="profileDetailsModal__input" type="text" data-date-format="DD MM YYYY" name="birthDate" id="" value={this.state.birthDate}/> */}
                        <div className="profileDetailsModal__inputBirthdate">
                        <input onBlur={this.verifyDate} maxLength="2" onChange={this.handleOnChange} className="profileDetailsModal__inputDay" type="text" placeholder="DD" name="day" id="" value={this.state.day}/>
                        <span>/</span>
                        <input onBlur={this.verifyDate} maxLength="2" onChange={this.handleOnChange} className="profileDetailsModal__inputMonth" type="text" placeholder="MM" name="month" id="" value={this.state.month}/>
                        <span>/</span>
                        <input onBlur={this.verifyDate} maxLength="4" onChange={this.handleOnChange} className="profileDetailsModal__inputYear" type="text" placeholder="YYYY" name="year" id="" value={this.state.year}/>
                        </div>
                        </div>
                        <div className="profileDetailsModal__item">
                        <label className="profileDetailsModal__label" htmlFor="">Region {(this.state.showErrorsFlag && !this.state.regionFlag) && <span className="profileDetailsModal__error">*</span> } </label>
                        <div className="profileDetailsModal__wrapperRecomendationGroup">
                        <input onChange={this.handleSearchOnChange} autoComplete="off" onBlur={this.handleOnFocusOut}  className="profileDetailsModal__input" type="text" name="region" id="regionFirstChange" value={this.state.regionSearch} placeholder="Región" />
                            <div className="profileDetailsModal__regionRecomendationGroup">
                            {
                                this.state.regionShow &&
                                this.state.regions.filter((val)=>{
                                    // console.log("distrito"+this.state.districtSearch);
                                    if(this.state.regionSearch ==""){
                                        return val;
                                    }
                                    else if(val.name.toLowerCase().includes(this.state.regionSearch.toLowerCase())){
                                        return val;
                                    }
                                }).map((element)=>{
                                    return(
                                        <div onClick={this.handleSelectSuggestion} data-idsuggestion={element.idRegion} data-suggestion={element.name} id="region" className="politicians__recomendationItem"> {element.name}</div>
                                    )
                                    }
                                )

                            }
                            </div>
                        </div>
                        
                        </div>
                        <div className="profileDetailsModal__item">
                        <label className="profileDetailsModal__label" htmlFor="">Provincia {(this.state.showErrorsFlag && !this.state.provinceFlag) && <span className="profileDetailsModal__error">*</span> }</label>
                        <div className="profileDetailsModal__wrapperRecomendationGroup">
                        <input onChange={this.handleSearchOnChange} autoComplete="off"  onBlur={this.handleOnFocusOut} className="profileDetailsModal__input" type="text" name="province" id="provinceFirstChange" value={this.state.provinceSearch} placeholder="Provincia"/>
                        <div className="profileDetailsModal__provinceRecomendationGroup">
                            {
                                this.state.provinceShow &&
                                this.state.provinces.filter((val)=>{
                                    if(this.state.provinceSearch ==""){
                                        return val;
                                    }
                                    else if(val.name.toLowerCase().includes(this.state.provinceSearch.toLowerCase())){
                                        return val;
                                    }
                                }).map((element)=>{
                                    return(
                                        <div onClick={this.handleSelectSuggestion} data-idsuggestion={element.idProvince} data-suggestion={element.name} id="province" className="politicians__recomendationItem"> {element.name}</div>
                                    )
                                    }
                                )

                            }
                            </div>
                        </div>
                        </div>
                        <div className="profileDetailsModal__item">
                        <label className="profileDetailsModal__label" htmlFor="">Distrito {(this.state.showErrorsFlag && !this.state.districtFlag) && <span className="profileDetailsModal__error">*</span> }</label>
                        <div className="profileDetailsModal__wrapperRecomendationGroup">
                        <input onChange={this.handleSearchOnChange} autoComplete="off"  onBlur={this.handleOnFocusOut} className="profileDetailsModal__input" type="text" name="district" id="districtFirstChange" value={this.state.districtSearch} placeholder="Distrito"/>
                        <div className="profileDetailsModal__districtRecomendationGroup">
                            {
                                this.state.districtShow &&
                                this.state.districts.filter((val)=>{
                                    // // console.log(val.name.toLowerCase());
                                    if(this.state.districtSearch ==""){
                                        return val;
                                    }
                                    else if(val.name.toLowerCase().includes(this.state.districtSearch.toLowerCase())){
                                        return val;
                                    }
                                }).map((element)=>{
                                    return(
                                        <div onClick={this.handleSelectSuggestion} data-idsuggestion={element.idDistrict} data-suggestion={element.name} id="district" className="politicians__recomendationItem"> {element.name}</div>
                                    )
                                    }
                                )

                            }
                            </div>
                        </div>
                        </div>
                        <div className="profileDetailsModal__item">
                        <label className="profileDetailsModal__label" htmlFor="">Actividad Laboral Principal:</label>
                        <input maxLength="150" onChange={this.handleOnChange} className="profileDetailsModal__input" type="text" name="actualWork" id="" value={this.state.actualWork} placeholder="Actividad Laboral Principal"/>
                        </div>
                        <div className="profileDetailsModal__item">
                        <label className="profileDetailsModal__label" htmlFor="">Maximo Grado Alcanzado:</label>
                        <input maxLength="150" onChange={this.handleOnChange} className="profileDetailsModal__input" type="text" name="maxGrade" id="" value={this.state.maxGrade} placeholder="Maximo Grado Alcanzado" />
                        </div>
                        <div className="profileDetailsModal__item">
                        <label className="profileDetailsModal__label" htmlFor="">Ingreso Anual:</label>
                        <input maxLength="10" onChange={this.handleOnChange} className="profileDetailsModal__input" type="text" name="income" id="" value={this.state.income} placeholder="Ingresos Anuales"/>
                        </div>
                        <div className="profileDetailsModal__item">
                        <label className="profileDetailsModal__label" htmlFor="">Partido Político:</label>
                        <input maxLength="150" onChange={this.handleOnChange} className="profileDetailsModal__input" type="text" name="politicalParty" id="" value={this.state.politicalParty} placeholder="Partido Político"/>
                        </div>
                        <div className="profileDetailsModal__item">
                        {/* <form enctype="multipart/form-data" action="event.preventDefault(); https://politicosperu.pe/php/uploadImages.php" method="POST">
                            <input type="hidden" name="MAX_FILE_SIZE" value="3000000000000000000" />
                            <input name="upfile" type="file"/>
                            <input type="submit" value="Actualizar Imagen" />
                        </form> */}

                        </div>
                    </div>
                    <div className="profileDetailsModal__footer">
                        <button className="profileDetailsModal__button" onClick={this.postData}>Aceptar</button>
                        <button className="profileDetailsModal__button" onClick={this.props.handleCancelModal}>Cancelar</button>
                    </div>
                </div>
                </div>

            );
        }else{
            return null;
        }

    }
}

export default ProfileDetailsModal;