import React from "react";
import axios from "axios";
import { Activity } from "./Activity";
import "./styles/activitiesEdit.css";
import imgEvent1 from "./images/event1.png";
import imgEvent2 from "./images/event2.png";
import imgEvent3 from "./images/event3.png";
import imgEvent4 from "./images/event4.png";
import imgEvent5 from "./images/event5.jpg";
import imgEvent6 from "./images/event6.jpg";
import imgCheck from "./images/check.png";
import { toHaveDisplayValue } from "@testing-library/jest-dom/dist/matchers";
import ImagesCarrousel from "./ImagesCarrousel";
import ConfirmationModal from "../modals/ConfirmationModal";


class ActivitiesEdit extends React.Component{
    constructor(props){
        super(props);
        var date= new Date();
        this.state={
            flagTablet:this.getDeviceUsed("tablet"),
            flagLaptop:this.getDeviceUsed("laptop"),
            flagPhone:this.getDeviceUsed("phone"),
            flagActivities:false,
            flagGetActivities:false,
            flagCreateActivity:false,
            flagActivityDelete:false,
            flagEditActivity:false,
            flagTitle:false,
            flagDescription:false,
            flagDate:false,
            flagTime:false,
            flagDateWrong:false,
            flagImage:false,
            flagErrorCreateActivity:false,
            flagErrorDeleteActivity:false,
            flags:{
                flagTitleWrong:false,
                flagDescriptionWrong:false,
                flagDateWrong:false,
                flagTimeWrong:false,
                flagImageWrong:false
                },
            activityEditId:"",
            activityDeleteId:'',
            activityDeleteIndex:"",
            index:"",
            title:"",
            description:"",
            date:"",
            day:this.maskDay(),
            month:this.maskMonth(),
            year:date.getFullYear(),
            time:"",
            hours:"00",
            minutes:"00",
            image:"",
            address:"",
            linkFacebook:"",
            linkGoogleMap:"",
            linkVideo:"",
            user:{
                idPolitician:this.props.idPolitician
            },
            activities:[]
        }
    }
    maskDay=()=>{
        var date= new Date();

        var day=parseInt(date.getDate());
        if(day<10){
            day="0"+day;
        }
        return day;
    }
    maskMonth=()=>{
        var date= new Date();

        var month=parseInt(date.getMonth()+1);
        if(month<10){
            month="0"+month;
        }
        return month;
    }
    maskYear=()=>{
        var date= new Date();

        var year=parseInt(date.getFullYear());
        if(year<2023){
            year="2023";
        }
        return year;
    }
    getActivities=async()=>{
        // console.log("###antes de validar"+this.state.cardValidation.tokenDescription);
        axios.post('https://politicosperu.pe/php/getActivitiesEdit.php',JSON.stringify({idPolitician:this.props.idPolitician}))
        .then(response =>{
            //console.log(response.data);
            var arrayResponse= new Array(response.data[0]);
            response.data= response.data.reverse();
            if(arrayResponse[0].status == "success"){  
                    //console.log("#### valida la sesión");
                    this.setState({
                        flagActivities:true,
                        flagGetActivities:true,
                        activities:response.data
                    });
                    // this.getPoliticianDetails(this.getUrlPolitician());
            }else{
                //console.log("#### inValida no hay actividades");
                this.setState({
                    flagActivities:false,
                    flagGetActivities:true,
                });
            }
    
        }).catch(error=>{
            //console.log("#### error");

            this.setState({
                flagGetActivities:true,
                flagActivities:false,
            });
        });

    }
    postActivity=async()=>{
        // console.log("###antes de validar"+this.state.cardValidation.tokenDescription);

        //console.log("#### listo para enviar la información");
        axios.post('https://politicosperu.pe/php/registerActivity.php',JSON.stringify({
            idPolitician:this.props.idPolitician, 
            token:this.props.token, 
            title:this.state.title, 
            description:this.state.description, 
            date:this.state.year+"-"+this.state.month+"-"+this.state.day, 
            time:this.state.time, 
            image:this.state.image, 
            address:this.state.address, 
            linkFacebook:this.state.linkFacebook, 
            linkGoogleMap:this.state.linkGoogleMap, 
            linkVideo:this.state.linkVideo 
        }))
        .then(response =>{
            //console.log(response.data);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  

                    //console.log("#### valida la creación de la actividad");
                    var auxArray=[];
                    var auxArray=[...this.state.activities];
                    //console.log(auxArray);
                    if(auxArray.length >=3){
                        auxArray.shift();
                    }
                    auxArray.push({
                        title:arrayResponse[0].title,
                        description:arrayResponse[0].description,
                        date:arrayResponse[0].date,
                        time:arrayResponse[0].time,
                        image:arrayResponse[0].image,
                        location:arrayResponse[0].location,                                   
                        linkFacebook:arrayResponse[0].linkFacebook,                                   
                        linkGoogleMap:arrayResponse[0].linkGoogleMap,                                   
                        linkVideoCall:arrayResponse[0].linkVideoCall,                                   
                        idActivity:arrayResponse[0].idActivity,
                    }); 

                    this.setState({
                        flagActivities:true,
                        activities:[...auxArray],
                        title:"",
                        description:"", 
                        date:"",
                        day:this.maskDay(),
                        month:this.maskMonth(),
                        year:this.maskYear(), 
                        time:"",
                        hours:'',
                        minutes:'', 
                        image:"", 
                        address:"", 
                        linkFacebook:"", 
                        linkGoogleMap:"", 
                        flags:{
                        flagTitleWrong:false,
                        flagDescriptionWrong:false,
                        flagDateWrong:false,
                        flagTimeWrong:false,
                        flagImageWrong:false
                        },
                        linkVideo:"",
                        flagDateWrong:false,
                        flagErrorCreateActivity:false,
                        flagCreateActivity:false,
                        flagDescription:false,
                        flagDate:false,
                        flagTitle:false,
                        flagImage:false,
                        
                                   });
                    // this.getPoliticianDetails(this.getUrlPolitician());
            }else{
                //console.log("#### inValida la creación de la actividad");
                this.setState({
                    flagErrorCreateActivity:true,
                });
            }
    
        }).catch(error=>{
            //console.log("#### error en la creación de la actividad");

            this.setState({
                flagErrorCreateActivity:true,
            });
        });

    }
    updateActivity=async()=>{
        // console.log("###antes de validar"+this.state.cardValidation.tokenDescription);

        //console.log("#### listo para enviar la información");
        axios.post('https://politicosperu.pe/php/updateActivity.php',JSON.stringify({
            idPolitician:this.props.idPolitician,
            idActivity:this.state.activityEditId, 
            token:this.props.token, 
            title:this.state.title, 
            description:this.state.description, 
            date:this.state.year+"-"+this.state.month+"-"+this.state.day, 
            time:this.state.time, 
            image:this.state.image, 
            address:this.state.address, 
            linkFacebook:this.state.linkFacebook, 
            linkGoogleMap:this.state.linkGoogleMap, 
            linkVideo:this.state.linkVideo 
        }))
        .then(response =>{
            //console.log(response.data);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  

                    //console.log("#### valida la creación de la actividad");
                    var auxArray=[];
                    var auxArray=[...this.state.activities];
                    //console.log("auxArray auxArray auxArray auxArray"+auxArray);
                    auxArray[this.state.index]={
                        title:arrayResponse[0].title,
                        description:arrayResponse[0].description,
                        date:arrayResponse[0].date,
                        time:arrayResponse[0].time,
                        image:arrayResponse[0].image,
                        location:arrayResponse[0].location,                                   
                        linkFacebook:arrayResponse[0].linkFacebook,                                   
                        linkGoogleMap:arrayResponse[0].linkGoogleMap,                                   
                        linkVideoCall:arrayResponse[0].linkVideoCall,
                        idActivity:arrayResponse[0].idActivity                                   
                    }

                    this.setState({
                        activities:[...auxArray],
                        title:"",
                        description:"", 
                        date:"",
                        day:this.maskDay(),
                        month:this.maskMonth(),
                        year:this.maskYear(), 
                        time:"",
                        hours:'',
                        minutes:'', 
                        image:"", 
                        address:"", 
                        linkFacebook:"", 
                        linkGoogleMap:"",
                        activityEditId:"",
                        index:'', 
                        flags:{
                        flagTitleWrong:false,
                        flagDescriptionWrong:false,
                        flagDateWrong:false,
                        flagTimeWrong:false,
                        flagImageWrong:false
                        },
                        linkVideo:"",
                        flagDateWrong:false,
                        flagErrorCreateActivity:false,
                        flagEditActivity:false,
                                   });
                    // this.getPoliticianDetails(this.getUrlPolitician());
            }else{
                //console.log("#### inValida la actualización de la actividad");
                this.setState({
                    flagErrorCreateActivity:true,
                });
            }
    
        }).catch(error=>{
            //console.log("#### error en la actualización de la actividad");

            this.setState({
                flagErrorCreateActivity:true,
            });
        });

    }

    handleEditActivity=(e)=>{
  

        var index=e.currentTarget.getAttribute("data--index");
        var jsonItem=this.state.activities[index];
        //console.log("data index"+index +"" +jsonItem);
        var dateParts= this.state.activities[index].date.split("-");
        var timeParts= this.state.activities[index].time.split(":");

        if(this.props.idPolitician != ""){
            this.setState({
                flagEditActivity:true,
                activityEditId:this.state.activities[index].idActivity,
                index: index,
                title:this.state.activities[index].title,
                flagTitle:true,
                description:this.state.activities[index].description,
                flagDescription:true,
                year:dateParts[0],
                month:dateParts[1],
                day:dateParts[2],
                flagDate:true,
                hours:timeParts[0],
                minutes:timeParts[1],
                flagTime:true,
                time:timeParts[0]+":"+timeParts[0],
                image:this.state.activities[index].image,
                flagImage:true,
                address:this.state.activities[index].location,
                linkFacebook:this.state.activities[index].linkFacebook,
                linkGoogleMap:this.state.activities[index].linkGoogleMap,
                linkVideo:this.state.activities[index].linkVideoCall,
            });
        }
    }

getMonth(month){
    switch (month){
        case ("1"):
            return("Ene");
            break;
        case ("2"):
            return("Feb");
            break;
        case ("3"):
            return("Mar");
            break;
        case ("4"):
            return("Abr");
            break;
        case ("5"):
            return("May");
            break;
        case ("6"):
            return("Jun");
            break;
        case ("7"):
            return("Jul");
            break;
        case ("8"):
            return("Ago");
            break;
        case ("9"):
            return("Sep");
            break;
        case ("10"):
            return("Oct");
            break;
        case ("11"):
            return("Nov");
            break;
        case ("12"):
            return("Dic");
            break;
          
    }

}
addMaskDate=(date)=>{
    //console.log("dentro del addMask con"+date);
    date= Array.from(date);
    var newString=[];
    var flag1=true;
    var flag2=true;
    for(var i=0;i<date.length;i++){
        if(i==2 && flag1){
            newString.push("-");
            i--;
            flag1=false;
        }else if(i==4 && flag2){
            newString.push("-");
            i--;
            flag2=false;
        }        
        else{
            newString.push(date[i]);
        }
    }
   newString=newString.join("");
   return newString;
}

cleanStringDate=(date,numbers)=>{
    //console.log("entre al clean String con este string"+date);
   date= Array.from(date);
   var newString=[];
   date.map((element,index)=>{
    if(parseInt(element)>=0 && parseInt(element)<=9 ){
        if(index <numbers){
            newString.push(element);
        }
    }
   });
   newString=newString.join("");
   //console.log("todos los numeros solo numeros"+newString);

   return newString;
}

handleCreateActivity=()=>{
    this.setState({
        flagCreateActivity:true
    });
}
handleCloseCreateActivityModal=()=>{
    this.setState({
        flagCreateActivity:false
    });
}
handleCloseEditActivityModal=()=>{
    this.setState({
        flagEditActivity:false,
        activityEditId:'',
        index:'',
        image:"",
        flagImage:false,
        title:"",
        description:"", 
        date:"",
        day:this.maskDay(),
        month:this.maskMonth(),
        year:this.maskYear(), 
        time:"",
        hours:'',
        minutes:'', 
        image:"", 
        address:"", 
        linkFacebook:"", 
        linkGoogleMap:"",
        activityEditId:"",
        flagTime:false,
        flagTitle:false,
        flagDescription:false,
        flags:{
            flagTitleWrong:false,
            flagDescriptionWrong:false,
            flagDateWrong:false,
            flagTimeWrong:false,
            flagImageWrong:false
            },
            linkVideo:"",
            flagDateWrong:false,
            flagErrorCreateActivity:false,
            flagEditActivity:false,
    });
}

handleUpdateActivity=()=>{
    var date= new Date(this.state.year+"-"+this.state.month+"-"+this.state.day);
    var myFlags={};

    //console.log("#### date"+date);
    if(this.dateIsValid(date)){
        myFlags={...myFlags,flagDateWrong:false};
    }else{
        myFlags={...myFlags,flagDateWrong:true};
    }

    if(parseInt(this.state.hours) <=24 && parseInt(this.state.hours) >=0 && parseInt(this.state.minutes) >=0 && parseInt(this.state.minutes) <=59){
        
        //console.log("hora"+this.state.hours+" minutos: "+this.state.minutes);
        if(parseInt(this.state.hours) ==24 && parseInt(this.state.minutes) >0){
            myFlags={...myFlags,flagTimeWrong:true};
        }else if(parseInt(this.state.hours)==24 && parseInt(this.state.minutes==0)){
            myFlags={...myFlags,flagTimeWrong:false};
        }else{
            myFlags={...myFlags,flagTimeWrong:false};
        }
    }else{
        //console.log("error en el tiempo hora"+this.state.hours+" minutos: "+this.state.minutes);
        myFlags={...myFlags,flagTimeWrong:true};
    }
    //console.log("despues de validar la fecha"+JSON.stringify(myFlags));

    if(this.state.flagTitle){
        myFlags={...myFlags,flagTitleWrong:false};

    }else{
        myFlags={...myFlags,flagTitleWrong:true};
        
    }
    //console.log("despues de validar el titulo"+JSON.stringify(myFlags));
    
    if(this.state.flagDescription){
        myFlags={...myFlags,flagDescriptionWrong:false};
    }else{
        myFlags={...myFlags,flagDescriptionWrong:true};
    }
    
    //console.log("despues de validar la descripcion"+JSON.stringify(myFlags));

    if(!this.state.flagImage){
        myFlags={...myFlags,flagImageWrong:true};
    }
    //console.log("este es el valor de title flag"+myFlags.flagTitleWrong);
    if(!myFlags.flagDateWrong && !myFlags.flagTitleWrong && !myFlags.flagDescriptionWrong && !myFlags.flagImageWrong){
        this.updateActivity();
    }else{
        this.setState({
            flagErrorCreateActivity:true,
            flags:{...myFlags}
        });
    }


}


handleCreateActivitySubmit=()=>{
    var date= new Date(this.state.year+"-"+this.state.month+"-"+this.state.day);
    var flagDate=false;
    var myFlags={};


    if(this.dateIsValid(date)){
        myFlags={...myFlags,flagDateWrong:false};
    }else{
        myFlags={...myFlags,flagDateWrong:true};
    }

    if(parseInt(this.state.hours) <=24 && parseInt(this.state.hours) >=0 && parseInt(this.state.minutes) >=0 && parseInt(this.state.minutes) <=59){
        
        //console.log("hora"+this.state.hours+" minutos: "+this.state.minutes);
        if(parseInt(this.state.hours) ==24 && parseInt(this.state.minutes) >0){
            myFlags={...myFlags,flagTimeWrong:true};
        }else if(parseInt(this.state.hours)==24 && parseInt(this.state.minutes==0)){
            myFlags={...myFlags,flagTimeWrong:false};
        }else{
            myFlags={...myFlags,flagTimeWrong:false};
        }
    }else{
        //console.log("error en el tiempo hora"+this.state.hours+" minutos: "+this.state.minutes);
        myFlags={...myFlags,flagTimeWrong:true};
    }
    //console.log("despues de validar la fecha"+JSON.stringify(myFlags));

    if(this.state.flagTitle){
        myFlags={...myFlags,flagTitleWrong:false};

    }else{
        myFlags={...myFlags,flagTitleWrong:true};
        
    }
    //console.log("despues de validar el titulo"+JSON.stringify(myFlags));
    
    if(!this.state.flagDescription){
        myFlags={...myFlags,flagDescriptionWrong:true};
    }
    
    //console.log("despues de validar la descripcion"+JSON.stringify(myFlags));

    if(!this.state.flagImage){
        myFlags={...myFlags,flagImageWrong:true};
    }


    //console.log("este es el valor de title flag"+myFlags.flagTitleWrong);
    if(!myFlags.flagDateWrong && !myFlags.flagTitleWrong && !myFlags.flagDescriptionWrong && !myFlags.flagImageWrong){
        this.postActivity();
    }else{
        this.setState({
            flagErrorCreateActivity:true,
            flags:{...myFlags}
        });
    }


}

dateIsValid=(date)=>{
    return date instanceof Date && !isNaN(date);
  }

  
handleInputActivitiesModalEdit=(e)=>{

    if(e.target.name =="title"){
        if(e.target.value == ""){
            this.setState({
                [e.target.name]:e.target.value,
                flagTitle:false
            });            
        }else{
            this.setState({
                [e.target.name]:e.target.value,
                flagTitle:true
            }); 
        }
    }else if(e.target.name =="description"){

        if(e.target.value == ""){
            this.setState({
                [e.target.name]:e.target.value,
                flagDescription:false
            });            
        }else{
            this.setState({
                [e.target.name]:e.target.value,
                flagDescription:true
            }); 
        }
    }
    else if(e.target.name =="day"){

        if(e.target.value == ""){
            //console.log("entre a la primera opcion");

            this.setState({
                [e.target.name]:e.target.value,
            });            
        }
        else{
            
            this.setState({
                [e.target.name]:this.cleanStringDate(e.target.value,2),
            });
        }
    }
    else if(e.target.name =="month"){

        if(e.target.value == ""){
            //console.log("entre a la primera opcion");

            this.setState({
                [e.target.name]:e.target.value,
            });            
        }
        else{
            
            this.setState({
                [e.target.name]:this.cleanStringDate(e.target.value,2),
            });
        }
    }
    else if(e.target.name =="year"){

        if(e.target.value == ""){
            //console.log("entre a la primera opcion");

            this.setState({
                [e.target.name]:e.target.value,
            });            
        }
        else{
            
            this.setState({
                [e.target.name]:this.cleanStringDate(e.target.value,4),
            });
        }
    }
    else if(e.target.name =="hours"){

        if(e.target.value == ""){
            this.setState({
                [e.target.name]:e.target.value,
            });            
        }
        else{
            this.setState({
                [e.target.name]:this.cleanStringDate(e.target.value,2),
            });
        }
    }
    else if(e.target.name =="minutes"){

        if(e.target.value == ""){
            this.setState({
                [e.target.name]:e.target.value,
            });            
        }
        else{
            this.setState({
                [e.target.name]:this.cleanStringDate(e.target.value,2),
            });
        }
    }
    else{
        this.setState({
            [e.target.name]:e.target.value
        });
    }


} 
handleFixTwoDigits=(e)=>{ 
    //console.log("calling the fixTwo");
    if(parseInt(e.target.value)<=9 ){
        if(parseInt(e.target.value)<=9 && e.target.value.length==2){
            this.setState({
                [e.target.name]:e.target.value
            });
        }else{
            this.setState({
                [e.target.name]:"0"+e.target.value
            });       
         }

    }
}
handleFixFourDigits=(e)=>{ 
    if(parseInt(e.target.value)<=9 ){
        this.setState({
            [e.target.name]:"000"+e.target.value
        });
    }else if(parseInt(e.target.value)>=10 && parseInt(e.target.value)<=99){
        this.setState({
            [e.target.name]:"00"+e.target.value
        });
    }else if(parseInt(e.target.value)>=100 && parseInt(e.target.value)<=999){
        this.setState({
            [e.target.name]:"0"+e.target.value
        });
    }
}

handleImageFlag=(image, flagImage)=>{
    this.setState({
        image:image,
        flagImage: flagImage
    });
}

handleCloseDeleteModal=()=>{
    this.setState({
        flagActivityDelete:false,        
        activityDeleteId:"",
        activityDeleteIndex:"",
    });
}
handleShowDeleteModal=(e)=>{
    //console.log(e);
    this.setState({
        flagActivityDelete:true,
        activityDeleteId:e.currentTarget.getAttribute("data--idactivity"),
        activityDeleteIndex:e.currentTarget.getAttribute("data--index"),
    });
}
handleDeleteActivity=()=>{
    axios.post('https://politicosperu.pe/php/deleteActivity.php',JSON.stringify({
        idPolitician:this.props.idPolitician,
        idActivity:this.state.activityDeleteId, 
        token:this.props.token 
    }))
    .then(response =>{
        //console.log("tengo respuesta del servidor");
        //console.log(response.data);
        //console.log(response.data[0].status);
        //console.log(response.data[1].activities);

        var arrayResponse= new Array(response.data[0]);


        if(response.data[0].status == "success"){  

                //console.log("#### valida la eliminación de la actividad");

                if(response.data[1].activities.length == 0){
                    this.setState({
                        activities:[...response.data[1].activities],
                        flagActivities:false,
                        flagErrorDeleteActivity:false,
                        flagActivityDelete:false,
                        activityDeleteId:'',
                        activityDeleteIndex:'',
                                   });
                }else{
                    this.setState({
                        activities:[...response.data[1].activities],
                        flagErrorDeleteActivity:false,
                        flagActivityDelete:false,
                        activityDeleteId:'',
                        activityDeleteIndex:'',
                                   });
                }
                
                // this.getPoliticianDetails(this.getUrlPolitician());
        }else{
            //console.log("#### inValida la eliminación de la actividad");
            this.setState({
                flagErrorDeleteActivity:true,
                flagActivityDelete:false,
                activityDeleteId:'',
                activityDeleteIndex:'',
            });
        }

    }).catch(error=>{
        //console.log("#### error en la eliminación de la actividad");

        this.setState({
            flagErrorDeleteActivity:true,
            flagActivityDelete:false,
            activityDeleteId:'',
            activityDeleteIndex:'',
        });
    });
 
}
getDeviceUsed=(device)=>{
    if("phone" == device && window.innerWidth <= 628 && window.innerWidth >= 320){
        return true;
    }else if("tablet" == device && window.innerWidth <= 950 && window.innerWidth >628){
        return true;
    }else if("laptop" == device && window.innerWidth > 950){
        return true;
    }else{
        return false;
    }
}
componentDidMount(){
    if(this.props.idPolitician !='' && this.state.flagGetActivities==false){
        this.getActivities();
    }
    window.addEventListener('resize', (event)=>{
        //console.log("height"+window.innerHeight);
        //console.log("width"+window.innerWidth);

        if(window.innerWidth <= 950 && window.innerWidth >628){
            if(!this.state.flagTablet){
                this.setState({
                    flagTablet:true,
                    flagPhone:false,
                    flagLaptop:false,
                });
            }
        }else if(window.innerWidth <= 628 && window.innerWidth >= 320){
            if(!this.state.flagPhone){
                this.setState({
                    flagTablet:false,
                    flagPhone:true,
                    flagLaptop:false,
                });
            }
        }else{
            if(!this.state.flagLaptop){
                this.setState({
                    flagTablet:false,
                    flagPhone:false,
                    flagLaptop:true,
                });
            }
    
        }
    });
}
componentDidUpdate(){

}
    render(){
        return(
            <div className="activitiesEditContainer">
                {this.state.flagCreateActivity && 
                <div onClick={this.handleCloseCreateActivityModal} className="activitiesEditModalContainer">
                    <div onClick={(e)=>e.stopPropagation()} className="activitiesEditModalWrapper">
                        <div className="activitiesEditModal__title">
                                Nueva Actividad
                        </div>
                        <div className="activitiesEditModal__body">
                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Titulo {this.state.flags.flagTitleWrong && <span>*</span>}</h4>
                                <input onChange={this.handleInputActivitiesModalEdit} maxlength="18" value={this.state.title} name="title" className="activitiesEditModal__itemDescription" placeholder="Añade un Titulo" type="text"/>
                            </div>
                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Descripción  {this.state.flags.flagDescriptionWrong && <span>*</span>}</h4>
                                <textarea onChange={this.handleInputActivitiesModalEdit} maxlength="113" value={this.state.description} name="description" className="activitiesEditModal__itemDescriptionArea" placeholder="Añade una Descripción" type="text"/>
                            </div>
                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Fechas {this.state.flags.flagDateWrong && <span>*</span>} </h4>

                                <div className="activitiesEditModal__dateContainer">
                                <input onBlur={this.handleFixTwoDigits} onChange={this.handleInputActivitiesModalEdit} value={this.state.day} name="day" className="activitiesEditModal__itemDescription activitiesEditModal__itemDescription--itemDay" placeholder="00" type="text"/>
                                <span className="activitiesEditModal__itemDescription--itemLine">-</span>
                                <input onBlur={this.handleFixTwoDigits} onChange={this.handleInputActivitiesModalEdit} value={this.state.month} name="month" className="activitiesEditModal__itemDescription activitiesEditModal__itemDescription--itemDay" placeholder="00" type="text"/>
                                <span className="activitiesEditModal__itemDescription--itemLine">-</span>
                                <input onBlur={this.handleFixFourDigits} onChange={this.handleInputActivitiesModalEdit} value={this.state.year} name="year" className="activitiesEditModal__itemDescription activitiesEditModal__itemDescription--itemYear" placeholder="0000" type="text"/>
                                </div>
                            </div>
                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Hora (24Hrs) {this.state.flags.flagTimeWrong && <span>*</span>} </h4>
                                <div className="activitiesEditModal__timeContainer">
                                <input onBlur={this.handleFixTwoDigits} onChange={this.handleInputActivitiesModalEdit} value={this.state.hours} name="hours" className="activitiesEditModal__itemDescription activitiesEditModal__itemDescription--itemDay" placeholder="00" type="text"/>
                                <span className="activitiesEditModal__itemDescription--itemPoints">:</span>
                                <input onBlur={this.handleFixTwoDigits} onChange={this.handleInputActivitiesModalEdit} value={this.state.minutes} name="minutes" className="activitiesEditModal__itemDescription activitiesEditModal__itemDescription--itemDay" placeholder="00" type="text"/>
                                </div>

                            </div>
                            {/* <div className="activitiesEditModal__itemImages">
                                <h4 className="activitiesEditModal__itemTitle">Imagen {this.state.flags.flagImageWrong && <span>*</span>}</h4>
                                <div className="activitiesEditModal__itemImagesList">
                                    <div className="activitiesEditModal__itemImageContainer">
                                        <img onClick={this.handleSelectImage} name="image1" className="activitiesEditModal__itemImage" src={imgEvent1} alt="" />
                                            <img className="activitiesEditModal__itemIcon" src={imgCheck} alt="" />
                                     </div>

                                    <div name="image2" onClick={this.handleSelectImage} className="activitiesEditModal__itemImageContainer">
                                        <img  name="image2" className="activitiesEditModal__itemImage" src={imgEvent2} alt="" />
                                        <img className="activitiesEditModal__itemIcon" src={imgCheck} alt="" />
                                     </div>

                                    <div name="image3" onClick={this.handleSelectImage} className="activitiesEditModal__itemImageContainer">
                                        <img  name="image3" className="activitiesEditModal__itemImage" src={imgEvent3} alt="" />
                                        <img className="activitiesEditModal__itemIcon" src={imgCheck} alt="" />
                                     </div>

                                    <div name="image4" onClick={this.handleSelectImage} className="activitiesEditModal__itemImageContainer">
                                        <img  name="image4" className="activitiesEditModal__itemImage" src={imgEvent4} alt="" />
                                        <img className="activitiesEditModal__itemIcon" src={imgCheck} alt="" />
                                     </div>

                                    <div name="image5" onClick={this.handleSelectImage} className="activitiesEditModal__itemImageContainer">
                                        <img  name="image5" className="activitiesEditModal__itemImage" src={imgEvent5} alt="" />
                                        <img className="activitiesEditModal__itemIcon" src={imgCheck} alt="" />
                                     </div>

                                    <div name="image6" onClick={this.handleSelectImage} className="activitiesEditModal__itemImageContainer">
                                        <img  name="image6" className="activitiesEditModal__itemImage" src={imgEvent6} alt="" />
                                        <img className="activitiesEditModal__itemIcon" src={imgCheck} alt="" />
                                     </div>

                                </div>
                                <span className="activitiesEditModal__itemTitle--bottom">Cargar Imagen</span>
                            </div> */}
                                <ImagesCarrousel handleImageFlag={this.handleImageFlag}/>

                            
                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Ubicación</h4>
                                <input onChange={this.handleInputActivitiesModalEdit} value={this.state.address} name="address" className="activitiesEditModal__itemDescription" placeholder="Añade una Ubicación" type="text"/>
                            </div>
                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Link Facebook</h4>
                                <input onChange={this.handleInputActivitiesModalEdit} value={this.state.linkFacebook} name="linkFacebook" className="activitiesEditModal__itemDescription" placeholder="Añade un Link de Facebook" type="text"/>
                            </div>
                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Link Google Map</h4>
                                <input onChange={this.handleInputActivitiesModalEdit} value={this.state.linkGoogleMap} name="linkGoogleMap" className="activitiesEditModal__itemDescription" placeholder="Añade un Link de Google" type="text"/>
                            </div>
                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Link Video Conferencia</h4>
                                <input onChange={this.handleInputActivitiesModalEdit} value={this.state.linkVideo} name="linkVideo" className="activitiesEditModal__itemDescription" placeholder="Añade una Link de Video Conferencia" type="text"/>
                            </div>
                            <button onClick={this.handleCreateActivitySubmit} className="activitiesEditModal__button"> Crear</button>
                        </div>
                    </div>
                </div>
                    
                }
                {this.state.flagEditActivity && 
                <div onClick={this.handleCloseEditActivityModal} className="activitiesEditModalContainer">
                    <div onClick={(e)=>e.stopPropagation()} className="activitiesEditModalWrapper">
                        <div className="activitiesEditModal__title">
                                Editando Actividad
                        </div>
                        <div className="activitiesEditModal__body">
                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Titulo {this.state.flags.flagTitleWrong && <span>*</span>}</h4>
                                <input onChange={this.handleInputActivitiesModalEdit} maxlength="18" value={this.state.title} name="title" className="activitiesEditModal__itemDescription" placeholder="Añade un Titulo" type="text"/>
                            </div>
                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Descripción  {this.state.flags.flagDescriptionWrong && <span>*</span>}</h4>
                                <textarea onChange={this.handleInputActivitiesModalEdit} maxlength="113" value={this.state.description} name="description" className="activitiesEditModal__itemDescriptionArea" placeholder="Añade una Descripción" type="text"/>
                            </div>
                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Fechas {this.state.flags.flagDateWrong && <span>*</span>} </h4>

                                <div className="activitiesEditModal__dateContainer">
                                <input onBlur={this.handleFixTwoDigits} onChange={this.handleInputActivitiesModalEdit} value={this.state.day} name="day" className="activitiesEditModal__itemDescription activitiesEditModal__itemDescription--itemDay" placeholder="00" type="text"/>
                                <span className="activitiesEditModal__itemDescription--itemLine">-</span>
                                <input onBlur={this.handleFixTwoDigits} onChange={this.handleInputActivitiesModalEdit} value={this.state.month} name="month" className="activitiesEditModal__itemDescription activitiesEditModal__itemDescription--itemDay" placeholder="00" type="text"/>
                                <span className="activitiesEditModal__itemDescription--itemLine">-</span>
                                <input onBlur={this.handleFixFourDigits} onChange={this.handleInputActivitiesModalEdit} value={this.state.year} name="year" className="activitiesEditModal__itemDescription activitiesEditModal__itemDescription--itemYear" placeholder="0000" type="text"/>
                                </div>
                            </div>
                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Hora (24Hrs) {this.state.flags.flagTimeWrong && <span>*</span>} </h4>
                                <div className="activitiesEditModal__timeContainer">
                                <input onBlur={this.handleFixTwoDigits} onChange={this.handleInputActivitiesModalEdit} value={this.state.hours} name="hours" className="activitiesEditModal__itemDescription activitiesEditModal__itemDescription--itemDay" placeholder="00" type="text"/>
                                <span className="activitiesEditModal__itemDescription--itemPoints">:</span>
                                <input onBlur={this.handleFixTwoDigits} onChange={this.handleInputActivitiesModalEdit} value={this.state.minutes} name="minutes" className="activitiesEditModal__itemDescription activitiesEditModal__itemDescription--itemDay" placeholder="00" type="text"/>
                                </div>

                            </div>

                                <ImagesCarrousel handleImageFlag={this.handleImageFlag}/>

                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Ubicación</h4>
                                <input onChange={this.handleInputActivitiesModalEdit} value={this.state.address} name="address" className="activitiesEditModal__itemDescription" placeholder="Añade una Ubicación" type="text"/>
                            </div>
                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Link Facebook</h4>
                                <input onChange={this.handleInputActivitiesModalEdit} value={this.state.linkFacebook} name="linkFacebook" className="activitiesEditModal__itemDescription" placeholder="Añade un Link de Facebook" type="text"/>
                            </div>
                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Link Google Map</h4>
                                <input onChange={this.handleInputActivitiesModalEdit} value={this.state.linkGoogleMap} name="linkGoogleMap" className="activitiesEditModal__itemDescription" placeholder="Añade un Link de Google" type="text"/>
                            </div>
                            <div className="activitiesEditModal__item">
                                <h4 className="activitiesEditModal__itemTitle">Link Video Conferencia</h4>
                                <input onChange={this.handleInputActivitiesModalEdit} value={this.state.linkVideo} name="linkVideo" className="activitiesEditModal__itemDescription" placeholder="Añade una Link de Video Conferencia" type="text"/>
                            </div>
                            <button onClick={this.handleUpdateActivity} className="activitiesEditModal__button"> Actualizar</button>
                        </div>
                    </div>
                </div>
                    
                }
                <div className="activitiesEditWrapper">
                <div className="activitiesEdit__title">
                    Mis Actividades
                </div>
                <div className="activitiesEdit__list">
                {
                    (()=>{
                        //console.log("########Activities list of if chains");
                        if(this.state.flagActivities){
                            if(this.state.activities.length ==1){
                                if(this.state.flagPhone){
                                    return(
                                        <div className="activitiesEdit__OneActivityContainer">
                                            <div className="activitiesEdit__OneActivity">
                                            {this.state.activities.map((activity,index)=>{
                                            var day= activity.date.split("-");
                                            var month=""+parseInt(day[1]);
                                            var day=day[2];
                                            
                                            month=this.getMonth(month);
                                            return(
                                                <Activity
                                                flagDelete={true}
                                                key={activity.idActivity}
                                                handleShowDeleteModal={this.handleShowDeleteModal}
                                                idPolitician={this.props.idPolitician}
                                                handleEditActivity={this.handleEditActivity} 
                                                index={index}
                                                idActivity={activity.idActivity} 
                                                image={activity.image} 
                                                title={activity.title} 
                                                text={activity.description} 
                                                date={day} 
                                                month={month}/>
                                            )
    
                                            })}
                                            </div>
    
                                        <div className="activitiesEdit__options">
                                        <button className="activitiesEdit__button" > Más</button>
                                        <button onClick={this.handleCreateActivity} className="activitiesEdit__button"> Crear</button>
                                        </div>
    
                                    </div>
                                    );
                                }else{
                                    return(
                                        <div className="activitiesEdit__OneActivityContainer">
                                            <div className="activitiesEdit__exampleMessage">
                                                Las actividades muestran tu compromiso con el Perú
                                            </div> 
                                            <div className="activitiesEdit__OneActivity">
                                            {this.state.activities.map((activity,index)=>{
                                            var day= activity.date.split("-");
                                            var month=""+parseInt(day[1]);
                                            var day=day[2];
                                            
                                            month=this.getMonth(month);
                                            return(
                                                <Activity
                                                flagDelete={true}
                                                key={activity.idActivity}
                                                handleShowDeleteModal={this.handleShowDeleteModal}
                                                idPolitician={this.props.idPolitician}
                                                handleEditActivity={this.handleEditActivity} 
                                                index={index}
                                                idActivity={activity.idActivity} 
                                                image={activity.image} 
                                                title={activity.title} 
                                                text={activity.description} 
                                                date={day} 
                                                month={month}/>
                                            )
    
                                            })}
                                            </div>
    
                                        <div className="activitiesEdit__options">
                                        <button className="activitiesEdit__button" > Más</button>
                                        <button onClick={this.handleCreateActivity} className="activitiesEdit__button"> Crear</button>
                                        </div>
    
                                    </div>
                                    );
                                }


                            

                            }else if(this.state.activities.length ==2){
                                if(this.state.flagTablet){
                                    return(
                                        <div className="activitiesEdit__OneActivityContainer">
                                            <div className="activitiesEdit__twoActivity">
                                            {this.state.activities.map((activity,index)=>{
                                            var day= activity.date.split("-");
                                            var month=""+parseInt(day[1]);
                                            var day=day[2];
                                            
                                            month=this.getMonth(month);
    
                                            return(
                                                <Activity
                                                flagDelete={true}
                                                key={activity.idActivity}
                                                handleShowDeleteModal={this.handleShowDeleteModal}
                                                idPolitician={this.props.idPolitician}
                                                handleEditActivity={this.handleEditActivity} 
                                                index={index}
                                                idActivity={activity.idActivity} 
                                                image={activity.image} 
                                                title={activity.title} 
                                                text={activity.description} 
                                                date={day} 
                                                month={month}/>
                                            )
    
                                            })}
                                            </div>
    
                                        <div className="activitiesEdit__options">
                                        <button className="activitiesEdit__button" > Más</button>
                                        <button onClick={this.handleCreateActivity} className="activitiesEdit__button"> Crear</button>
                                        </div>
    
                                    </div>
                                    );
                                }else if(this.state.flagPhone){
                                    return(
                                        <div className="activitiesEdit__OneActivityContainer">
                                            <div className="activitiesEdit__twoActivity">
                                            {this.state.activities.map((activity,index)=>{
                                            var day= activity.date.split("-");
                                            var month=""+parseInt(day[1]);
                                            var day=day[2];
                                            
                                            month=this.getMonth(month);
                                            if(index == 0){
                                                return;
                                            }else{
                                                return(
                                                    <Activity
                                                    flagDelete={true}
                                                    key={activity.idActivity}
                                                    handleShowDeleteModal={this.handleShowDeleteModal}
                                                    idPolitician={this.props.idPolitician}
                                                    handleEditActivity={this.handleEditActivity} 
                                                    index={index}
                                                    idActivity={activity.idActivity} 
                                                    image={activity.image} 
                                                    title={activity.title} 
                                                    text={activity.description} 
                                                    date={day} 
                                                    month={month}/>
                                                )
                                            }
  
    
                                            })}
                                            </div>
    
                                        <div className="activitiesEdit__options">
                                        <button className="activitiesEdit__button" > Más</button>
                                        <button onClick={this.handleCreateActivity} className="activitiesEdit__button"> Crear</button>
                                        </div>
    
                                    </div>
                                    );
                                }else{
                                    return(
                                        <div className="activitiesEdit__OneActivityContainer">
                                            <div className="activitiesEdit__exampleMessage  activitiesEdit__exampleMessage--two">
                                                Las actividades muestran tu compromiso con el Perú
                                            </div> 
                                            <div className="activitiesEdit__twoActivity">
                                            {this.state.activities.map((activity,index)=>{
                                            var day= activity.date.split("-");
                                            var month=""+parseInt(day[1]);
                                            var day=day[2];
                                            
                                            month=this.getMonth(month);
    
                                            return(
                                                <Activity
                                                flagDelete={true}
                                                key={activity.idActivity}
                                                handleShowDeleteModal={this.handleShowDeleteModal}
                                                idPolitician={this.props.idPolitician}
                                                handleEditActivity={this.handleEditActivity} 
                                                index={index}
                                                idActivity={activity.idActivity} 
                                                image={activity.image} 
                                                title={activity.title} 
                                                text={activity.description} 
                                                date={day} 
                                                month={month}/>
                                            )
    
                                            })}
                                            </div>
    
                                        <div className="activitiesEdit__options">
                                        <button className="activitiesEdit__button" > Más</button>
                                        <button onClick={this.handleCreateActivity} className="activitiesEdit__button"> Crear</button>
                                        </div>
    
                                    </div>
                                    );
                                }

                
                            }else if(this.state.activities.length ==3){
                                if(this.state.flagTablet){
                                    return(
                                        <div className="activitiesEdit__threeActivityContainer">
                                            <div className="activitiesEdit__threeActivity">
                                            {this.state.activities.map((activity,index)=>{
                                            var day= activity.date.split("-");
                                            var month=""+parseInt(day[1]);
                                            var day=day[2];
                                            month=this.getMonth(month);
                                            if(index ==0){
                                                return;
                                            }else{
                                                return(
                                                    <Activity
                                                    flagDelete={true}
                                                    key={activity.idActivity}
                                                    handleShowDeleteModal={this.handleShowDeleteModal}
                                                    idPolitician={this.props.idPolitician}
                                                    handleEditActivity={this.handleEditActivity} 
                                                    index={index}
                                                    idActivity={activity.idActivity} 
                                                    image={activity.image} 
                                                    title={activity.title} 
                                                    text={activity.description} 
                                                    date={day} 
                                                    month={month}/>
                                                )
                                            }    
                                            })}
                                            </div>
    
                                        <div className="activitiesEdit__options">
                                        <button className="activitiesEdit__button" > Más</button>
                                        <button onClick={this.handleCreateActivity} className="activitiesEdit__button"> Crear</button>
                                        </div>
    
                                    </div>
                                    );

                                }else if(this.state.flagPhone){
                                    return(
                                        <div className="activitiesEdit__threeActivityContainer">
                                            <div className="activitiesEdit__threeActivity">
                                            {this.state.activities.map((activity,index)=>{
                                            var day= activity.date.split("-");
                                            var month=""+parseInt(day[1]);
                                            var day=day[2];
                                            month=this.getMonth(month);
                                            if(index ==0 || index==1){
                                                return;
                                            }else{
                                                return(
                                                    <Activity
                                                    flagDelete={true}
                                                    key={activity.idActivity}
                                                    handleShowDeleteModal={this.handleShowDeleteModal}
                                                    idPolitician={this.props.idPolitician}
                                                    handleEditActivity={this.handleEditActivity} 
                                                    index={index}
                                                    idActivity={activity.idActivity} 
                                                    image={activity.image} 
                                                    title={activity.title} 
                                                    text={activity.description} 
                                                    date={day} 
                                                    month={month}/>
                                                )
                                            }

    
                                            })}
                                            </div>
    
                                        <div className="activitiesEdit__options">
                                        <button className="activitiesEdit__button" > Más</button>
                                        <button onClick={this.handleCreateActivity} className="activitiesEdit__button"> Crear</button>
                                        </div>
    
                                    </div>
                                    );
                                }else{
                                    return(
                                        <div className="activitiesEdit__threeActivityContainer">
                                            <div className="activitiesEdit__threeActivity">
                                            {this.state.activities.map((activity,index)=>{
                                            var day= activity.date.split("-");
                                            var month=""+parseInt(day[1]);
                                            var day=day[2];
                                            month=this.getMonth(month);
    
                                            return(
                                                <Activity
                                                flagDelete={true}
                                                key={activity.idActivity}
                                                handleShowDeleteModal={this.handleShowDeleteModal}
                                                idPolitician={this.props.idPolitician}
                                                handleEditActivity={this.handleEditActivity} 
                                                index={index}
                                                idActivity={activity.idActivity} 
                                                image={activity.image} 
                                                title={activity.title} 
                                                text={activity.description} 
                                                date={day} 
                                                month={month}/>
                                            )
    
                                            })}
                                            </div>
    
                                        <div className="activitiesEdit__options">
                                        <button className="activitiesEdit__button" > Más</button>
                                        <button onClick={this.handleCreateActivity} className="activitiesEdit__button"> Crear</button>
                                        </div>
    
                                    </div>
                                    );

                                }

                            }

                        }else if(this.state.activities.length == 0){

                            return(
                                <div className="activitiesEdit__exampleContainer">
                                <div className="activitiesEdit__exampleMessage">
                                    Crea tu primera actividad y muestrale al Perú como te desvives por él
                                </div> 
                                <div onClick={this.handleCreateActivity}>
                                <Activity
                                        flagDelete={false}
                                        idPolitician={this.props.idPolitician}
                                        handleEditActivity={this.handleEditActivity} 
                                        index="1"
                                        idActivity="1" 
                                        image={imgEvent2} 
                                        title="Titulo Evento" 
                                        text="Describe el evento que vas realizar, se lo mas atractivo y conciso." 
                                        date="28" 
                                        month="Jul"/>
                                </div>
                                <div className="activitiesEdit__options">
                                    <button className="activitiesEdit__button" > Más</button>
                                    <button onClick={this.handleCreateActivity} className="activitiesEdit__button"> Crear</button>
                                </div>
                            </div>
                            );                        
                        }
                    })() 
                
                }
                

                </div>

                {/* <Activity image={imgEvent2} title="Conversatorio" text="Realidad de la vida partidaria en el Perú, historia de los partidos políticos" date="10" month="Oct"/>
                <Activity image={imgEvent3} title="Congreso" text="Congreso anual sobre el desarrollo económico de los países sudamericanos" date="7" month="Nov"/>
                <Activity image={imgEvent4} title="Reunión" text="Se convoca a todos los seguidores a unirse a la videollamada a desarrollarse este fin de semana." date="12" month="Dic"/> */}
                </div>
                {this.state.flagActivityDelete && 
                <ConfirmationModal 
                handleCloseModal={this.handleCloseDeleteModal}
                handleOnClickMethod={this.handleDeleteActivity}
                header="Eliminar Actividad"
                message="La actividad será eliminada"
                />}
            </div>
           );
    }
}
export {ActivitiesEdit};