import './styles/candidates.css';
import Profile from './Profile';
import Cities from './Cities';
import {Link} from 'react-router-dom';
import axios from 'axios';
import React from 'react';

class Candidates extends React.Component{
    constructor(props){
        super(props);
        this.state={
            data:[],
            regions:[]
        }
    }
    // getCandidates=async()=>{
    //     const res = await axios.get('https://politicosperu.pe/php/listCandidates.php');
    // //     console.log(res.data);
    //     this.setState({
    //         data:res.data
    //     });
    // }
    getCandidatesHome=async(query)=>{
        // console.log("candidaes home query"+query);
        const res = await axios.get('https://politicosperu.pe/php/listCandidatesHome.php?region='+query);
        // console.log("candidaes home"+res.data);
        this.setState({
            data:res.data
        });
    }
    // getRegions=async()=>{
    //     const res = await axios.get('https://politicosperu.pe/php/listRegions.php');
    // //     console.log(res.data);
    //     this.setState({
    //         regions:res.data
    //     });
    // }
    getListPoliticians=async()=>{
        const candidates = await axios.get('https://politicosperu.pe/php/listCandidates.php');
        const regions = await axios.get('https://politicosperu.pe/php/listRegions.php');

        // console.log(candidates.data);
        this.setState({
            data:candidates.data,
            regions:regions.data

        });
    }
    componentDidMount(){
        // console.log("called didmount");
        this.getListPoliticians();
    }

    handleClick=(e)=>{
        // console.log("handleClick");
        this.getCandidatesHome(e.target.getAttribute('data-idregion'));
    }
    render(){
        return(
            <div className="canditatesWrapper">
            <div className="candidates">
            {
            this.state.data.map(function(element){
                // console.log("idpolitician before if "+element.idPolitician);
                if(!element.idPolitician==false){
                    // console.log("there is data"+element.name);
                    // if(element.level==1){
                    //     element.level='Distrito '+element.districtDescription;
                    // }else if(element.level==2){
                    //     element.level='Provincia '+element.provinceDescription;
                    // }else if(element.level==3){
                    //     element.level='Región '+element.regionDescription;
                    // }else{
                    //     element.level='Nacional';
    
                    // }
                    return(
                        <Profile
                        key={element.idPolitician}
                        idPolitician={element.idPolitician} 
                        name={element.name}
                        surnameFather={element.surnameFather}
                        surnameMother={element.surnameMother}
                        actualWork={element.actualWork}
                        webpage={element.webpage}
                        imgProfile={element.imgProfile}
                        />
                    )
                }else if(element.idPolitician ==false){
                    // console.log("idpolitician is false so: "+element.idPolitician);
                    return(
                        <div className='candidates__messageNoPolitician'>
                            <span> No existen políticos registrados <br/>
                            <span className="candidates__messageStrong"> Puedes ser el primero!</span> <br/>Escribenos a:</span> 
                            <span className='candidates__messageNoPoliticianRelevant'> contacto@politicosperu.pe</span>
                        </div>
                    )
                }

                }
            )}
            </div>
            <Link to="/politicians" className="candidates__button">
                Conoce a los políticos
            </Link>
            <div className="candidates__cities">
                {
                this.state.regions.map((element)=>{
                    return(
                        <Cities
                        key={element.idRegion} 
                        name= {element.name}
                        idRegion={element.idRegion}
                        handleClick={this.handleClick}
                        />
                    )
                    }
                )}
            </div>
            </div>
    
            );
    }

}
export default Candidates;