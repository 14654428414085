import React from "react";
import "./css/error404.css";


class Error404 extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }
    render(){
        return(
            <div className="ErrorContainer">
                <div className="ErrorWrapper">
                    Lo sentimos, no tienes acceso a esta página
                </div>
            </div>
        );
        }
}
export default Error404;