import React from "react";
import { useNavigate } from "react-router-dom";
import "./css/configProfile.css";
import axios from "axios";
import successImage from "./images/success.png";
import errorImage from "./images/error.png";
import ProfilePlanModal from "../modals/ProfilePlanModal";
import patriotaImage from "./images/patriota.png";
import caballeroImage from "./images/Caballero.png";
import oficialImage from "./images/Oficial.png";
import comendadorImage from "./images/Comendador.png";
import granOficialImage from "./images/GranOficial.png";
import granCruzImage from "./images/GranCruz.png";
import granCollarImage from "./images/GranCollar.png";
import ConfirmationModal from "../modals/ConfirmationModal";
import ConfirmationActionModal from "../modals/ConfirmationActionModal";

class ConfigProfile extends React.Component{
     
constructor(props){
    super(props);

    this.state={
        flagPassword:false,
        flagPlan:false,
        flagAddress:false,
        flagEnable:false,
        flagDisable:false,
        flagDelete:false,
        flagValidPassword:false,
        newPassword:'',
        newPassword2:'',
        newDomain:"",
        errorDomain:false,
        errorPassword:false,
        successPassword:false,
        header:"",
        flagPatriota:false,
        flagCaballero:false,
        flagOficial:false,
        flagComendador:false,
        flagGranOficial:false,
        flagGranCruz:false,
        flagGranCollar:false,
        message:''
    }

    }

    handleChangePassword=(e)=>{
        if(!this.state.flagPassword){

        this.setState({
            flagPassword:true
        });
        }else{

        this.setState({
            flagPassword:false
        });
        }
    }
    handleChangePlan=(e)=>{
        if(!this.state.flagPlan){

        this.setState({
            flagPlan:true
        });
        }else{

        this.setState({
            flagPlan:false
        });
        }
    }
    handleChangeAddress=(e)=>{
        if(!this.state.flagAddress){

        this.setState({
            flagAddress:true
        });
        }else{

        this.setState({
            flagAddress:false
        });
        }
    }
    handleCheckPassword=()=>{
        if((this.state.newPassword == this.state.newPassword2) && this.state.newPassword.length >8 && this.state.newPassword !=''){
            return true;
        }else{
            return false;
        }
    }
    handleErrorPassword=()=>{
        this.hideErrorPassword();
        this.setState({
            errorPassword:true
        })
    }
    showErrorPassword=()=>{
        this.setState({
            errorPassword:true
        })
    }
    hideErrorPassword=()=>{
        window.setTimeout(()=>{this.setState({errorPassword:false})},1000);
    }
    hideErrorDomain=()=>{
        window.setTimeout(()=>{this.setState({errorDomain:false})},1000);
    }
    hideSuccessPassword=()=>{
        window.setTimeout(()=>{this.setState({successPassword:false})},1000);
    }

    createNewPassword=()=>{
        if(this.handleCheckPassword()){
            //console.log("clave coincide");
            this.handleUpdatePassword();
        }else{
            this.handleErrorPassword();
            
            //console.log("la clave no coincide");
        }
    }

    updateDomain=()=>{
        if(this.state.newDomain !=""){
            axios.post('https://politicosperu.pe/php/validateDomain.php',JSON.stringify(
                {
                newDomain: this.state.newDomain, 
                token:this.props.tokenDescription}
                ))
            .then(response =>{
                // console.log(response);
                var arrayResponse= new Array(response.data[0]);
        
                if(arrayResponse[0].status == "success"){  
                        this.hideSuccessPassword();
                        this.setState({
                            flagValidDomain:true
                        });
    
                }else{
                    // console.log("#### citizen inValida"+arrayResponse[0].urlUser+"-- token: "+arrayResponse[0].token);
                    //console.log("no se pudo cambiar el dominio");
                    //console.log(arrayResponse[0].value);
                    this.hideErrorDomain();
                    this.setState({
                        flagValidDomain:false,
                        errorDomain:true
                    });
                }
        
            }).catch(error=>{
                this.hideErrorDomain();
                this.setState({
                    flagValidDomain:false,
                    errorDomain:true
                });
            });
        }else{
            this.hideErrorDomain();
            this.setState({
                errorDomain:true,
            });
        }
    }

    handleOnChange=(e)=>{
            this.setState({
                [e.target.name]:e.target.value
            })
    }
    handleUpdatePassword=()=>{
        //console.log("###antes de validar"+this.props.tokenDescription);
        axios.post('https://politicosperu.pe/php/updatePassword.php',JSON.stringify(
            {
            flagCitizen:this.props.flagCitizen,
            password: this.state.newPassword, 
            token:this.props.tokenDescription}
            ))
        .then(response =>{
            // //console.log(response);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    //console.log("#### cambiando el password"+arrayResponse[0].idPolitician);
                    this.hideSuccessPassword();
                    this.setState({
                        flagUpdateResponse:true,
                        newPassword:"",
                        newPassword2:"",
                        successPassword:true,
                    });

            }else{
                // //console.log("#### citizen inValida"+arrayResponse[0].urlUser+"-- token: "+arrayResponse[0].token);
                //console.log("no se pudo cambiar el password");

                this.setState({
                    flagUpdateResponse:false
                });
            }
    
        }).catch(error=>{
            this.setState({
                flagUpdateResponse:false
            });
        });
    }

    handleSelectPlan=(e)=>{
        var plan=e.target.getAttribute('data--plan');
        if(plan == "Patriota"){
            this.setState({
                flagPatriota:true,
                message:"Es un reconocimiento honorífico, reservado para los usuarios que participan enérgicamente en las actividades organizadas por la plataforma. Muestra del ímpetu y el deseo de cambio para bien, que todos esperamos lograr.",
                header:plan
            });
        }else if(plan == "Caballero"){
            this.setState({
                flagCaballero:true,
                message:" Es un reconocimiento honorífico, reservado para los usuarios que adquieren su correo electronico  @politicosperu.pe. Muestra de que el apoyo de todos los niveles es importante para lograr los objetivos planteados.",
                header:plan
            });
        }else if(plan == "Oficial"){
            this.setState({
                flagOficial:true,
                message:"Es un reconocimiento honorífico, reservado para los usuarios que han hecho un aporte de gran valor a la consolidación de la plataforma. Muestra del desprendimiento desinteresado a razon de encaminarnos con fuerza al perú  que todos deseamos y merecemos.",
                header:plan
            });
        }else if(plan == "Comendador"){
            this.setState({
                flagComendador:true,
                message:"Es un reconocimiento honorífico, reservado para los usuarios que han hecho un aporte invaluable a la plataforma. Muestra que el trabajo mancomunado de cada uno de los ciudadanos es importante para lograr un gran perú.",
                header:plan
            });
        }else if(plan == "GranOficial"){
            this.setState({
                flagGranOficial:true,
                message:"Es un reconocimiento honorífico, reservado para los usuarios que han hecho multiples aportes de gran valor a la transparencia de la plataforma. Muestra de la importancia de la transparencia en la ejecución de la vida política.",
                header:plan
            });
        }else if(plan == "GranCruz"){
            this.setState({
                flagGranCruz:true,
                message:"Es un reconocimiento honorífico, reservado para los usuarios que han hecho multiples aportes de gran valor en la consolidación de la plataforma como un entorno para fortalecer la democracia.  Muestra del esfuerzo por difundir la ejecución de una política limpia y de nivel internacional.",
                header:plan
            });
        }else if(plan == "GranCollar"){
            this.setState({
                flagGranCollar:true,
                message:"Es el máximo reconocimiento honorífico, reservado para los usuarios que han hecho multiples aportes invaluables a la plataforma. Muestra de su desprendimiento e ideales para formar el país que todos deseamos y merecemos.",
                header:plan
            });
        }
    }
    handleClosePlanModal=()=>{
        if(this.state.flagPatriota){
            this.setState({
                flagPatriota:false,
                header:"",
                message:''
            });
        }else if(this.state.flagCaballero){
            this.setState({
                flagCaballero:false,
                header:"",
                message:''
            });
        }else if(this.state.flagOficial){
            this.setState({
                flagOficial:false,
                header:"",
                message:''
            });
        }else if(this.state.flagComendador){
            this.setState({
                flagComendador:false,
                header:"",
                message:''
            });
        }else if(this.state.flagGranOficial){
            this.setState({
                flagGranOficial:false,
                header:"",
                message:''
            });
        }else if(this.state.flagGranCruz){
            this.setState({
                flagGranCruz:false,
                header:"",
                message:''
            });
        }else if(this.state.flagGranCollar){
            this.setState({
                flagGranCollar:false,
                header:"",
                message:''
            });
        }
    }

    changeDomain=()=>{

    }
    closeConfirmationModal=()=>{
        this.setState({
            flagValidDomain:false
        });
    }   
    handleOnClickConfirmationDomain=()=>{
        axios.post('https://politicosperu.pe/php/updateDomain.php',JSON.stringify(
            {
            newDomain: this.state.newDomain,
            domain:this.props.domain, 
            token:this.props.tokenDescription}
            ))
        .then(response =>{
            // console.log(response);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    //console.log("#### cambiando el password"+arrayResponse[0].idPolitician);
                    this.eraseCookie("idPolitician");
                    this.eraseCookie("idCitizen");
                    this.eraseCookie("token");
                    this.eraseCookie("domain");
                    this.hideSuccessPassword();
                    const { navigation } = this.props;
                    navigation('/');
                    this.props.closeSesion();
            }else{
                // //console.log("#### citizen inValida"+arrayResponse[0].urlUser+"-- token: "+arrayResponse[0].token);
                //console.log("no se pudo cambiar el dominio");
                this.hideErrorDomain();
                this.setState({
                    errorDomain:true
                });
            }
    
        }).catch(error=>{
            this.hideErrorDomain();
            this.setState({
                errorDomain:true
            });
        });
    }
    readCookie=(name)=> {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
    createCookie=(name,value,days)=> {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            var expires = "; expires="+date.toGMTString();
        }
        else var expires = "";
        document.cookie = name+"="+value+expires+"; path=/";
    }
    
    eraseCookie=(name)=>{
        this.createCookie(name,"",-1);
    }

    showEnableModal=()=>{
        this.setState({
            flagEnable:true
        });
    }
    showDisableModal=()=>{
        this.setState({
            flagDisable:true
        });
    }
    closeEnableModal=()=>{
        this.setState({
            flagEnable:false
        });
    }
    closeDisableModal=()=>{
        this.setState({
            flagDisable:false
        });
    }
    handleEnable=()=>{
        axios.post('https://politicosperu.pe/php/enablePolitician.php',JSON.stringify(
            {
            domain:this.props.domain, 
            token:this.props.tokenDescription}
            ))
        .then(response =>{
            // console.log(response);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    this.closeEnableModal();
                    this.props.handleSuspendedFlag();
            }else{
                // console.log("#### citizen inValida"+arrayResponse[0].urlUser+"-- token: "+arrayResponse[0].token);
                this.closeEnableModal();

            }
    
        }).catch(error=>{
            this.closeEnableModal();

        });
    }
    handleDisable=()=>{
        axios.post('https://politicosperu.pe/php/disablePolitician.php',JSON.stringify(
            {
            domain:this.props.domain, 
            token:this.props.tokenDescription}
            ))
        .then(response =>{
            // console.log(response);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    this.closeDisableModal();
                    this.props.handleSuspendedFlag();
            }else{
                // console.log("#### citizen inValida"+arrayResponse[0].urlUser+"-- token: "+arrayResponse[0].token);
                this.closeDisableModal();

            }
    
        }).catch(error=>{
            this.closeDisableModal();

        });
    }

    closeDeleteModal=()=>{
        this.setState({
            flagDelete:false
        });
    }
    showDeleteModal=()=>{
        this.setState({
            flagDelete:true
        });
    }
    handleDelete=()=>{
        axios.post('https://politicosperu.pe/php/deletePolitician.php',JSON.stringify(
            {
            domain:this.props.domain, 
            token:this.props.tokenDescription,
            flagCitizen: this.props.flagCitizen
        }
            ))
        .then(response =>{
            // console.log(response);
            var arrayResponse= new Array(response.data[0]);
    
            if(arrayResponse[0].status == "success"){  
                    this.closeDeleteModal();
                    const { navigation } = this.props;
                    navigation('/');
                    this.props.closeSesion();
            }else{
                // console.log("#### citizen inValida"+arrayResponse[0].urlUser+"-- token: "+arrayResponse[0].token);
                this.closeDeleteModal();

            }
    
        }).catch(error=>{
            this.closeDeleteModal();

        });
    }
    render(){
        return(
        <div className="configProfileContainer">
            <div className="configProfileWrapper">
                <div onClick={this.handleChangePassword} className="configProfile__itemContainer">Cambiar Clave</div>
                {this.state.flagPassword && 
                    <div className="configProfile__itemDescriptionContainer">
                        <input onChange={this.handleOnChange} className="configProfile__input" value={this.state.newPassword} name="newPassword" type="password" placeholder="Nueva Clave"/>
                        <input onChange={this.handleOnChange} className="configProfile__input" value={this.state.newPassword2} type="password" name="newPassword2" placeholder="Verificar Clave" />
                        <div>
                            <div className="configProfile__buttonContainer">
                        <button onClick={this.createNewPassword} className="configProfile__button">Confirmar</button> 
                        {this.state.errorPassword && <img className="configProfile__error" alt="error" src={errorImage} width="15px" height={"15px"}/>}
                        {this.state.successPassword && <img className="configProfile__success" alt="success" src={successImage} width="15px" height={"15px"} />}
                            </div>
                        </div>
                    </div>}
                {!this.props.flagCitizen &&<div onClick={this.handleChangePlan} className="configProfile__itemContainer">Cambiar mi plan (actual: básico)</div>}
                {this.state.flagPlan && <div className="configProfile__itemDescriptionContainer">
                    <span onClick={this.handleSelectPlan} data--plan="Patriota" className="configProfile__plan">Patriota</span>
                    {this.state.flagPatriota && <ProfilePlanModal header={this.state.header} image={patriotaImage} message={this.state.message} handleClosePlanModal={this.handleClosePlanModal}/>}
                    <span onClick={this.handleSelectPlan} data--plan="Caballero" className="configProfile__plan">Caballero</span>
                    {this.state.flagCaballero && <ProfilePlanModal header={this.state.header} image={caballeroImage} message={this.state.message} handleClosePlanModal={this.handleClosePlanModal}/>}
                    <span onClick={this.handleSelectPlan} data--plan="Oficial" className="configProfile__plan">Oficial</span>
                    {this.state.flagOficial && <ProfilePlanModal header={this.state.header} image={oficialImage} message={this.state.message} handleClosePlanModal={this.handleClosePlanModal}/>}
                    <span onClick={this.handleSelectPlan} data--plan="Comendador" className="configProfile__plan">Comendador</span>
                    {this.state.flagComendador && <ProfilePlanModal header={this.state.header} image={comendadorImage} message={this.state.message} handleClosePlanModal={this.handleClosePlanModal}/>}
                    <span onClick={this.handleSelectPlan} data--plan="GranOficial" className="configProfile__plan">Gran Oficial</span>
                    {this.state.flagGranOficial && <ProfilePlanModal header={this.state.header} image={granOficialImage} message={this.state.message} handleClosePlanModal={this.handleClosePlanModal}/>}
                    <span onClick={this.handleSelectPlan} data--plan="GranCruz" className="configProfile__plan">Gran Cruz</span>
                    {this.state.flagGranCruz && <ProfilePlanModal header={this.state.header} image={granCruzImage} message={this.state.message} handleClosePlanModal={this.handleClosePlanModal}/>}
                    <span onClick={this.handleSelectPlan} data--plan="GranCollar" className="configProfile__plan">Gran Collar</span>
                    {this.state.flagGranCollar && <ProfilePlanModal header={this.state.header} image={granCollarImage} message={this.state.message} handleClosePlanModal={this.handleClosePlanModal}/>}
                </div>}
                {!this.props.flagCitizen && <div onClick={this.handleChangeAddress} className="configProfile__itemContainer">Cambiar mi dirección de perfil (actual: {this.props.domain})</div>}
                    {this.state.flagAddress && 
                    <div className="configProfile__itemDescriptionContainer">
                        <input id="newDomain" onChange={this.handleOnChange} value={this.state.newDomain} name="newDomain" className="configProfile__input" type="text" placeholder="Nueva dirección" />
                        <div>
                        <button onClick={this.updateDomain} className="configProfile__button">Actualizar</button>
                        {this.state.errorDomain && <img className="configProfile__error" alt="error" src={errorImage} width="15px" height={"15px"}/>}
                        {this.state.flagValidDomain && 
                        <ConfirmationModal 
                        handleCloseModal={this.closeConfirmationModal} 
                        message={"Estas por cambiar tu dirección de perfil, confirma para realizar el cambio"}
                        header="Cambio dirección de perfil"
                        handleOnClickMethod={this.handleOnClickConfirmationDomain}
                        />}
                        </div>
                    </div>}

                    {this.props.flagCitizen ?<React.Fragment/> :  this.props.suspended =="1" ?
                                <div onClick={this.showEnableModal} className="configProfile__itemContainer">Darme de alta</div>                                
                                :
                                <div onClick={this.showDisableModal} className="configProfile__itemContainer">Darme una pausa</div>
                            }
                    
                    { this.state.flagEnable && 
                    <ConfirmationModal 
                    handleCloseModal={this.closeEnableModal}
                    message="En hora buena, Tu cuenta será habilitada y apareceras en las busquedas"
                    header="Darme de alta"
                    handleOnClickMethod={this.handleEnable}
                    />}
                    { this.state.flagDisable && 
                    <ConfirmationModal
                    handleCloseModal={this.closeDisableModal}
                    message="Tu cuenta será suspendida, No apareceras en las busqueda hasta que te des de alta nuevamente"
                    header="Darme una pausa"
                    handleOnClickMethod={this.handleDisable}
                    />}

                <div onClick={this.showDeleteModal} className="configProfile__itemContainer">Eliminar mi cuenta</div>
                {this.state.flagDelete && 
                <ConfirmationActionModal
                handleCloseModal={this.closeDeleteModal}
                message="Esta acción sera irreversible, procede con cuidado"
                header="Eliminar mi cuenta"
                handleOnClickMethod={this.handleDelete}
                />}
            </div>
        </div>  
        );
    };
}

export default function(props) {
    const navigation = useNavigate();
  
    return <ConfigProfile {...props} navigation={navigation} />;
  }