import './styles/button.css';

function Button(props){
return(
    <button className='button__hero--demand' onClick={props.checkForm}>
        {props.text}
    </button>
);


}

export default Button;