import React from "react";
import "./styles/InvitationModal.css"
class InvitationModal extends React.Component{
    constructor(props){
        super(props);
        this.state={

        }
    }
    render(){
        return(
            <div onClick={this.props.handleCloseModal} className="InvitationModalContainer">
                <div onClick={(e)=>{e.stopPropagation()}} className="InvitationModalWrapper">
                    <div className="InvitationModal__header">
                        <span className="InvitationModal__title">{this.props.header}</span>
                        <span onClick={this.props.handleCloseModal} className="InvitationModal_close">x</span>
                    </div>
                    <div className="InvitationModal__body">
                        <input placeholder="Código" onChange={this.props.handleUpdateCode} className="InvitationModal__input" type="text" value={this.props.code} />
                        <span className="InvitationModal__Message">
                        {this.props.message}
                        </span>
                    </div>
                    <div className="InvitationModal__footer">
                    <button className="InvitationModal__button" onClick={this.props.handleOnClickMethod}> {this.props.buttonText}</button>
                    </div>
                </div>
            </div>
        );
    }
}
export default InvitationModal;